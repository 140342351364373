export enum ZoomInfoDepartments {
  C_Suite = 'C-Suite',
  Finance = 'Finance',
  Human_Resources = 'Human Resources',
  Sales = 'Sales',
  Operations = 'Operations',
  Information_Technology = 'Information Technology',
  Engineering_Technical = 'Engineering & Technical',
  Marketing = 'Marketing',
  Legal = 'Legal',
  Medical_Health = 'Medical & Health',
  Other = 'Other'
}
