import { EditOutlined } from '@ant-design/icons';
import { Button, Form, Popconfirm, message } from 'antd';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  useDeleteFeatureMutation,
  useEditFeatureMutation,
  useGetCompaniesQuery,
  useGetFeatureQuery,
  useGetMeQuery
} from '../../redux-store';
import { REFRESH_INTERVAL } from '../../redux-store/constants';
import { RolesEnum, type Feature } from '../../types';
import PageWrapper from '../PageWrapper/PageWrapper';
import Spinner from '../Spinner/Spinner';
import FeatureInfo from './components/FeatureInfo';
import EditFeatureForm from './forms/EditFeatureForm';

export interface EditFeatureFormValues extends Pick<Feature, 'name' | 'description' | 'isDefaultEnabled'> {
  companiesId: number[];
}

export default function FeaturePage() {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'features' });
  const [form] = Form.useForm<EditFeatureFormValues>();
  const { currentData: currentUser } = useGetMeQuery(null);
  const hasFeatureAdminRole = !!currentUser?.hasRole(currentUser, RolesEnum.FeatureAdmin);
  const { currentData: companies, isLoading: isCompanyLoading } = useGetCompaniesQuery(
    {},
    {
      pollingInterval: REFRESH_INTERVAL
    }
  );
  const { currentData: feature, isLoading } = useGetFeatureQuery(
    {
      id: Number(featureId)
    },
    {
      skip: !featureId,
      pollingInterval: REFRESH_INTERVAL
    }
  );
  const [updateSingleFeature, { isLoading: isFormLoading }] = useEditFeatureMutation();
  const [deleteSingleFeature] = useDeleteFeatureMutation();

  /**
   * Get company ids from each company
   */
  const featureCompaniesId = useMemo(() => feature?.companies?.map((item) => item.id), [feature?.companies]);

  /**
   * Exit from edit state when click on submit
   * @param values
   */
  async function handleUpdateFeature(values: Partial<Feature> & { companiesId: number[] }) {
    if (!values) return;

    // Check if all required fields are filled
    if (!values.name?.trim() || !values.description?.trim()) return;

    try {
      await updateSingleFeature({ id: Number(featureId), ...values }).unwrap();

      message.success(t('featuresEditSuccess'));
    } catch (e) {
      message.error(t('featuresEditError'));
    }

    setIsEditing(false);
  }

  async function handleDeleteFeature() {
    try {
      await deleteSingleFeature({ id: Number(featureId) }).unwrap();

      message.success(t('delete.success'));

      navigate('/admin/features');
    } catch (e) {
      message.error(t('delete.error'));
    }
  }

  /**
   * Change edit state
   */
  function handleChangeEdit() {
    setIsEditing((prev) => !prev);
  }

  /**
   * Cancel editing and reset fields which were edited
   */
  function handleCancel() {
    handleChangeEdit();

    form.resetFields();
  }

  if (!hasFeatureAdminRole) {
    return (
      <div className="font-medium flex items-center justify-center w-full h-full text-gray-400">{t('errorPage')}</div>
    );
  }

  return (
    <PageWrapper title={feature?.name}>
      <div>
        {!isEditing ? (
          <FeatureInfo
            isLoading={isLoading || isCompanyLoading}
            feature={feature as Feature}
            companies={companies?.data || []}
            chosenCompaniesId={featureCompaniesId as number[]}
          />
        ) : (
          <EditFeatureForm
            feature={feature as Feature}
            isLoading={isFormLoading}
            companies={companies?.data || []}
            form={form}
            chosenCompaniesId={featureCompaniesId as number[]}
            onSubmit={handleUpdateFeature}
          />
        )}

        <div className="flex gap-x-4 mt-12">
          {!isEditing ? (
            <div>
              <Button
                onClick={handleChangeEdit}
                className="flex items-center bg-white"
                disabled={isFormLoading || isLoading}
                data-t="edit-feature-button"
              >
                <EditOutlined />
                {t('edit')}
              </Button>
            </div>
          ) : (
            <div className="flex gap-x-4">
              <Button htmlType="button" onClick={handleCancel} disabled={isFormLoading} className="bg-white">
                {t('cancel')}
              </Button>

              <Button onClick={form.submit} className="text-[var(--action)] bg-white" disabled={isFormLoading}>
                {!isFormLoading ? (
                  t('save')
                ) : (
                  <div className="flex gap-x-2">
                    <Spinner />
                    {t('saveLoading')}
                  </div>
                )}
              </Button>
            </div>
          )}

          <Popconfirm
            title={t('delete.title')}
            description={t('delete.warning')}
            onConfirm={handleDeleteFeature}
            okText={t('delete.confirm')}
            okButtonProps={{ type: 'default', danger: true }}
            placement="topLeft"
            cancelText={t('delete.cancel')}
            disabled={isFormLoading || isLoading}
          >
            <Button danger disabled={isFormLoading || isLoading} data-t="delete-feature-button" className="bg-white">
              {t('delete.button')}
            </Button>
          </Popconfirm>
        </div>
      </div>
    </PageWrapper>
  );
}
