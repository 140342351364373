import type {
  Campaign,
  Company,
  FeatureMatrix,
  IntegrationToken,
  OnboardingConfig,
  UserInterface,
  UserPreferences
} from '../types';
import { RolesEnum, UserStatus } from '../types';

export class User implements UserInterface {
  id: number = 0;

  email = '';

  firstName = '';

  lastName = '';

  paragonToken = '';

  status: UserStatus = UserStatus.Inactive;

  createdAt = new Date();

  updatedAt = new Date();

  campaigns: Campaign[] = [];

  roles: { id: number; name: RolesEnum }[] = [];

  features: FeatureMatrix = {};

  companyId: number = 0;

  company = {} as Partial<Company>;

  password = '';

  integrationTokens: IntegrationToken[] = [];

  pictureUrl = '';

  userPreferences: UserPreferences = {};

  onboarding? = {} as OnboardingConfig;

  constructor(user?: UserInterface) {
    if (!user) return;

    this.id = user.id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.paragonToken = user.paragonToken;
    this.status = user.status;
    this.createdAt = user.createdAt;
    this.updatedAt = user.updatedAt;
    this.campaigns = user.campaigns;
    this.roles = user.roles;
    this.features = user.features;
    this.companyId = user.companyId;
    this.company = user.company;
    this.integrationTokens = user.integrationTokens;
    this.pictureUrl = user.pictureUrl;
    this.userPreferences = user.userPreferences;
    this.onboarding = user.onboarding;
  }

  /**
   * Check if user is logged in
   */
  public isLoggedIn() {
    return Boolean(this.id);
  }

  /**
   * Check is user has access to a feature
   * @param user
   * @param name
   */
  public hasFeature(user: UserInterface | null, name: string): boolean {
    if (!user) return false;

    return user.features.hasOwnProperty(name) ? user.features[name] : false;
  }

  /**
   * Check if use has particular role
   * @param user
   * @param name
   */
  public hasRole(user: UserInterface | null, name: RolesEnum): boolean {
    if (!user) return false;

    return user?.roles?.some((role) => role.name === name);
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
// export default new User();
