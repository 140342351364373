import { Button, Checkbox, Form, FormInstance, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { EnterFormValues, StatusesOptions } from '../Sqs';

interface ChooseCampaignLeadFormProps {
  form: FormInstance<EnterFormValues>;
  isSubmitLoading: boolean;
  onSubmit: (values: EnterFormValues) => Promise<void>;
  statusesOptions: StatusesOptions[];
}

const initialValues: EnterFormValues = {
  campaignLeadsId: '',
  newState: null,
  keepPrimaryStatus: true
};

export default function EnterCampaignLeadIdForm(props: ChooseCampaignLeadFormProps) {
  const { form, isSubmitLoading, statusesOptions, onSubmit } = props;
  const keepPrimaryStatus = Form.useWatch('keepPrimaryStatus', form);
  const { t } = useTranslation('translation', { keyPrefix: 'sqs' });

  return (
    <Form
      form={form}
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onSubmit}
      className="w-1/3"
      layout="vertical"
    >
      <Form.Item
        name="campaignLeadsId"
        label={t('enterCampaignLeadForm.enterCampaignLeadsId')}
        rules={[
          {
            required: true,
            message: t(`validations.campaignLeads`)
          }
        ]}
      >
        <Input placeholder={t('placeholders.leads')} disabled={isSubmitLoading} />
      </Form.Item>

      <Form.Item
        name="newState"
        label={t('enterCampaignLeadForm.selectStatus')}
        className="!mb-1"
        rules={[
          {
            required: !keepPrimaryStatus,
            message: t(`validations.newStatus`)
          }
        ]}
      >
        <Select
          popupClassName="!max-w-[100%] !whitespace-pre-wrap"
          popupMatchSelectWidth={false}
          placement="bottomRight"
          placeholder={t('placeholders.newStatus')}
          options={statusesOptions}
          disabled={isSubmitLoading || keepPrimaryStatus}
        />
      </Form.Item>

      <Form.Item name="keepPrimaryStatus" valuePropName="checked">
        <Checkbox id="keepPrimaryStatus">{t('enterCampaignLeadForm.keepStatus')}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" className="bg-white" disabled={isSubmitLoading}>
          {!isSubmitLoading ? t('addToQueue') : t('addToQueueLoading')}
        </Button>
      </Form.Item>
    </Form>
  );
}
