import type { Feature } from '../../types';
import { ProviderTags } from '../constants';
import { DefaultSearchParams } from '../types';

import { apiSlice } from './apiSlice';

export const featuresApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFeatures: builder.query<Feature[], { params?: DefaultSearchParams }>({
      query: ({ params }) => ({
        url: 'features',
        params
      }),
      providesTags: (result) => result?.map(({ id }) => ({ type: ProviderTags.Features, id })) ?? []
    }),
    getFeature: builder.query<Feature, Pick<Feature, 'id'>>({
      query: ({ id }) => `features/${id}`,
      providesTags: (result, error, { id }) => [{ type: ProviderTags.Features, id }]
    }),
    createFeature: builder.mutation<Feature, Partial<Feature>>({
      query: (body) => ({
        url: 'features',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      onQueryStarted: async (body, { dispatch, queryFulfilled }) => {
        const createFeaturePatch = dispatch(
          featuresApi.util.updateQueryData('getFeatures', {}, (draft) => {
            draft?.push(body as Feature);
          })
        );

        try {
          const { data } = await queryFulfilled;

          dispatch(
            featuresApi.util.updateQueryData('getFeatures', {}, (draft) => {
              draft[draft.length - 1] = data;
            })
          );
        } catch (error) {
          createFeaturePatch.undo();
        }
      }
    }),
    editFeature: builder.mutation<Feature, Partial<Feature> & { companiesId: number[] }>({
      query: (body) => ({
        url: `features/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Features, id }, { type: ProviderTags.Me }]
    }),
    deleteFeature: builder.mutation<void, Pick<Feature, 'id'>>({
      query: ({ id }) => ({
        url: `features/${id}`,
        method: 'DELETE'
      }),
      onQueryStarted: async ({ id }, { dispatch, queryFulfilled }) => {
        const deleteFeaturePatch = dispatch(
          featuresApi.util.updateQueryData('getFeatures', {}, (draft) => {
            const index = draft?.findIndex((feature) => feature.id === id);

            if (index !== undefined && index !== -1) {
              draft?.splice(index, 1);
            }
          })
        );

        try {
          await queryFulfilled;
        } catch {
          deleteFeaturePatch.undo();
        }
      }
    })
  })
});

export const {
  useGetFeaturesQuery,
  useGetFeatureQuery,
  useCreateFeatureMutation,
  useEditFeatureMutation,
  useDeleteFeatureMutation
} = featuresApi;
