export enum RocketReachManagementLevels {
  Owner = 'Owner',
  CXO = 'CXO',
  VP = 'VP',
  Director = 'Director',
  Manager = 'Manager',
  Individual_Contributor = 'Individual Contributor',
  Entry = 'Entry',
  Training = 'Training',
  Unpaid = 'Unpaid'
}
