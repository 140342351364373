import { Button, Checkbox, Form, FormInstance, Input, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import { SendWholeCampaignFormValues, StatusesOptions } from '../Sqs';

interface ChooseCampaignLeadFormProps {
  form: FormInstance<SendWholeCampaignFormValues>;
  isSubmitLoading: boolean;
  onSubmit: (values: SendWholeCampaignFormValues) => Promise<void>;
  statusesOptions: StatusesOptions[];
}

const initialValues: Partial<SendWholeCampaignFormValues> = {
  keepPrimaryStatus: true
};

/**
 * Helper function to check if campaignId is a number
 * @param campaignId
 * @returns
 */
function validateCampaignId(campaignId: string) {
  const numericalCampaignId = Number(campaignId);

  if (!isNaN(numericalCampaignId)) return Promise.resolve();

  return Promise.reject();
}

export default function ChooseCampaignForm(props: ChooseCampaignLeadFormProps) {
  const { form, isSubmitLoading, statusesOptions, onSubmit } = props;
  const keepPrimaryStatus = Form.useWatch('keepPrimaryStatus', form);
  const { t: tSqs } = useTranslation('translation', { keyPrefix: 'sqs' });

  return (
    <Form
      form={form}
      initialValues={initialValues}
      requiredMark={false}
      onFinish={onSubmit}
      className="w-1/3"
      layout="vertical"
    >
      <Form.Item
        name="campaignId"
        label={tSqs('pushWholeCampaignForm.enterCampaignId')}
        rules={[
          {
            required: true,
            message: tSqs(`validations.campaignId`)
          },
          {
            validator: (_, value) => validateCampaignId(value)
          }
        ]}
      >
        <Input placeholder={tSqs('placeholders.typeCampaignId')} disabled={isSubmitLoading} />
      </Form.Item>

      <Form.Item
        name="newState"
        label={tSqs('pushWholeCampaignForm.selectStatus')}
        className="!mb-1"
        rules={[
          {
            required: !keepPrimaryStatus,
            message: tSqs(`validations.newStatus`)
          }
        ]}
      >
        <Select
          popupClassName="!max-w-[100%] !whitespace-pre-wrap"
          popupMatchSelectWidth={false}
          placement="bottomRight"
          placeholder={tSqs('placeholders.newStatus')}
          options={statusesOptions}
          disabled={isSubmitLoading || keepPrimaryStatus}
        />
      </Form.Item>

      <Form.Item name="keepPrimaryStatus" valuePropName="checked">
        <Checkbox id="keepPrimaryStatus">{tSqs('pushWholeCampaignForm.keepStatus')}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button htmlType="submit" className="bg-white" disabled={isSubmitLoading}>
          {!isSubmitLoading ? tSqs('pushWholeCampaignForm.addCampaignToQueue') : tSqs('addToQueueLoading')}
        </Button>
      </Form.Item>
    </Form>
  );
}
