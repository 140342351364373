import cn from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert } from 'antd';
import { ReactNode } from 'react';

import { ReactComponent as InfoIcon } from '../../images/info.svg';

interface ResolveDuplicatesHeaderProps {
  type: 'error' | 'info' | 'warning';
  message: string;
  hasShadow?: boolean;
  button?: ReactNode;
}

export default function ResolveDuplicatesHeader(props: ResolveDuplicatesHeaderProps) {
  const { type, message, hasShadow, button } = props;
  /**
   * Set proper icon
   */
  const HeaderIcon = type === 'info' ? InfoIcon : ExclamationCircleOutlined;

  return (
    <Alert
      data-t="resolve-duplicates-header"
      className={cn(
        'relative z-50 min-h-[46px] mb-2 !p-0 !border !border-[var(--input-border)] rounded-[5px] bg-white'
      )}
      type={type}
      message={
        <div
          className={cn('flex flex-col gap-2.5 sm:flex-row justify-between items-center px-5 py-2.5 rounded-[5px]', {
            '!bg-[var(--bg-opacity)]': type === 'info',
            '!bg-[var(--danger-bg)]': type === 'error',
            '!bg-[var(--warning-bg)]': type === 'warning',
            'shadow-[0_5px_10px_0_var(--border-opacity)]': hasShadow
          })}
        >
          <div className="flex items-center gap-2.5 relative z-50">
            <HeaderIcon
              className={cn('flex items-center text-base', {
                '!text-[var(--cancel)] text-[20px]': type === 'error',
                '!text-[var(--action)] !text-[24px]': type === 'info',
                '!text-[var(--warning)] !text-[24px]': type === 'warning'
              })}
            />
            <div
              data-testid="duplicates-found-error-message"
              className="whitespace-pre-line text-[var(--placeholder-secondary)]"
            >
              {message}
            </div>
          </div>

          {button}
        </div>
      }
    />
  );
}
