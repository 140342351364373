import type { Company, Feature, SmsNumber } from '../../types';
import { ProviderTags } from '../constants';
import { DefaultPaginationParams } from '../types';

import { User } from '../../services/user';
import { apiSlice } from './apiSlice';
import { Replica } from '../../components/CompanyPage/components/ReplicasTable';

interface GetAllCompaniesResponse {
  data: Company[];
  totalCount: number;
}

interface GetAllCompaniesRequest {
  params?: DefaultPaginationParams & { search?: string };
}

interface GetAllCompanyUsersResponse {
  data: User[];
  totalCount: number;
}

interface GetAllCompanyUsersRequest {
  id: number;
  params?: DefaultPaginationParams & { search?: string; includeIntegrationTokens?: boolean };
}

export interface GetCompanyResponse extends Company {
  usersCount: number;
  features: Feature[];
  smsSettings: SmsNumber[];
  usedLeadSearchCount: number;
  leadSearchLimit: number;
}

interface ChurnCompanyRequest {
  id: number;
  deleteUsers: boolean;
  deleteCampaigns: boolean;
  deleteLeads: boolean;
}

interface SmsSettingsRequest {
  id: number;
  smsNumber: SmsNumber;
}

interface UpdateLeadSearchLimitRequest {
  id: number;
  leadSearchLimit: number;
}

export const companiesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCompanies: builder.query<GetAllCompaniesResponse, GetAllCompaniesRequest>({
      query: ({ params }) => ({
        url: 'companies',
        params
      }),
      providesTags: [ProviderTags.Company]
    }),

    getCompany: builder.query<GetCompanyResponse, Pick<Company, 'id'>>({
      query: ({ id }) => ({
        url: `companies/${id}`
      }),
      providesTags: (result, error, { id }) => [{ type: ProviderTags.Company, id }]
    }),

    getCompanyUsers: builder.query<GetAllCompanyUsersResponse, GetAllCompanyUsersRequest>({
      query: ({ id, params }) => ({
        url: `companies/${id}/users`,
        params
      }),
      providesTags: (result, error, { id: companyId }) => [
        ...(result?.data?.map(({ id }) => ({ type: ProviderTags.Users, id })) ?? []),
        { type: ProviderTags.Company, id: companyId }
      ]
    }),
    churnCompany: builder.mutation<void, ChurnCompanyRequest>({
      query: ({ id, ...params }) => ({
        url: `companies/${id}/churn`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params)
      })
    }),
    addSmsSettings: builder.mutation<void, SmsSettingsRequest>({
      query: ({ id, ...body }) => ({
        url: `companies/${id}/add-phone-number`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    }),
    updateSendoWebhookSecretTokenSettings: builder.mutation<void, SmsSettingsRequest>({
      query: ({ id, ...body }) => ({
        url: `companies/${id}/update-sendo-webhook`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    }),
    deleteSmsSettings: builder.mutation<void, SmsSettingsRequest>({
      query: ({ id, ...body }) => ({
        url: `companies/${id}/delete-phone-number`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    }),
    getReplicas: builder.query<Replica[], { companyId?: number }>({
      query: ({ companyId }) => ({
        url: `companies/${companyId}/replicas`
      }),
      providesTags: [ProviderTags.Company]
    }),
    addReplica: builder.mutation<void, Replica & { companyId: number }>({
      query: ({ companyId, ...body }) => ({
        url: `companies/${companyId}/add-replica`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    }),
    deleteReplica: builder.mutation<void, Replica & { companyId: number }>({
      query: ({ companyId, ...body }) => ({
        url: `companies/${companyId}/delete-replica`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    }),
    updateLeadSearchLimit: builder.mutation<void, UpdateLeadSearchLimitRequest>({
      query: ({ id, ...body }) => ({
        url: `companies/${id}/update-lead-search-limit`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Company }]
    })
  })
});

export const {
  useGetCompaniesQuery,
  useGetCompanyQuery,
  useGetCompanyUsersQuery,
  useChurnCompanyMutation,
  useAddSmsSettingsMutation,
  useUpdateSendoWebhookSecretTokenSettingsMutation,
  useDeleteSmsSettingsMutation,
  useGetReplicasQuery,
  useAddReplicaMutation,
  useDeleteReplicaMutation,
  useUpdateLeadSearchLimitMutation
} = companiesApi;
