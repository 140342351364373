import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormInstance, Switch } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import { useGetMeQuery } from '../../redux-store';
import { useDebouncedState } from '../../hooks/useDebouncedState';
import { isFormOneValueFilled } from '../../services/isFormOneValueFilled';
import { AiProspectingSearchMethods, CompanyProviders } from '../../types/aiProspecting';
import { Features } from '../../types';

import ResolveDuplicatesHeader from '../Builder/components/ResolveDuplicatesHeader/ResolveDuplicatesHeader';
import { AiProspectingBuilderForm, DisplayedIntentSearchResult, intentFields } from './AiProspectingBuilder';
import BasicFiltersSection from './filtersSections/BasicFiltersSection';
import HiringIntentSection from './filtersSections/HiringIntentSection';
import TechnologySearchSection from './filtersSections/TechnologySearchSection';
import LeadFiltersSection from './filtersSections/LeadFiltersSections';
import IntentLeadsLoader from './components/IntentLeadsLoader';
import SearchSectionButton from './filtersSections/SearchSectionButton';
import NewsSearchSection from './filtersSections/NewsSearchSection';
import WebSearchSection from './filtersSections/WebSearchSection';
import Hint from './components/Hint';
import { checkIsAnyFieldFilled } from './services/fieldValidationUtils';

interface AccountResearchFormProps {
  isLoading: boolean;
  isFormSubmitted: boolean;
  form: FormInstance<AiProspectingBuilderForm>;
  currentPhase: number;
  leadsToShowInTable: DisplayedIntentSearchResult;
  setLeadsToShowInTable: (
    values: DisplayedIntentSearchResult | ((prevValues: DisplayedIntentSearchResult) => DisplayedIntentSearchResult)
  ) => void;
  cancelStream: () => void;
  companiesSearchLimitReacted: boolean;
}

export const searchSectionsComponents = {
  [AiProspectingSearchMethods.Hiring]: HiringIntentSection,
  [AiProspectingSearchMethods.Web]: WebSearchSection,
  [AiProspectingSearchMethods.News]: NewsSearchSection,
  [AiProspectingSearchMethods.Technology]: TechnologySearchSection
};
export type SearchSectionKey = keyof typeof searchSectionsComponents;

export default function AccountResearchForm({
  isLoading,
  form,
  currentPhase,
  isFormSubmitted,
  leadsToShowInTable,
  setLeadsToShowInTable,
  cancelStream,
  companiesSearchLimitReacted
}: AccountResearchFormProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const [searchParams, setSearchParams] = useSearchParams();
  const isDeepSearchEnabled = Form.useWatch('isDeepSearchEnabled', { form });
  const selectedSearchSections = Form.useWatch('searchMethods', { form });
  const selectedCompanyProvider = Form.useWatch('companyProvider', { form });
  const fieldValues = form.getFieldsValue();
  const { currentData: user } = useGetMeQuery(null);
  const hasWebNewsSearchFeatureEnabled = user?.hasFeature(user, Features.WebNewsIntentSearch);
  const [search, setSearch] = useDebouncedState(
    {
      industry: '',
      location: '',
      hiringIntentJobTitle: '',
      leadJobTitle: '',
      technologyQuery: ''
    },
    300
  );
  // Check if at least one field is not empty
  const submitDisabled = !isFormOneValueFilled(form.getFieldsValue());
  const showLeadsCommonValidationError = !checkIsAnyFieldFilled(intentFields.leads, fieldValues) && isFormSubmitted;
  const showCompanyCommonValidationError = !checkIsAnyFieldFilled(intentFields.company, fieldValues) && isFormSubmitted;
  const wasSearchTriggered =
    leadsToShowInTable?.searchWasTriggered ||
    leadsToShowInTable?.nextPageParamForCompanies ||
    typeof leadsToShowInTable?.companiesBatchIdReqParam === 'number';
  const showIntentLoader = (wasSearchTriggered || isLoading) && isFormSubmitted;
  // Check if search button is disabled
  const isSearchDisabled =
    submitDisabled ||
    isLoading ||
    (companiesSearchLimitReacted && leadsToShowInTable?.searchWasTriggered) ||
    (isFormSubmitted && (showLeadsCommonValidationError || showCompanyCommonValidationError));

  /**
   * Get amount of leads with unique companies
   * @returns
   */
  const companiesWithLeads = useMemo(() => {
    const uniqueCompanies = new Set<string>();

    (leadsToShowInTable?.leads || []).forEach((lead) => {
      uniqueCompanies.add(lead.companyName);
    });

    return uniqueCompanies.size;
  }, [leadsToShowInTable?.leads]);

  // Currently, we cannot use Ocean.io in conjunction with Hiring Intent.
  // If Hiring Intent is selected, we have to deselect it and select another method(if hiring method was 1).
  // Specifically, it clears the 'size' field if the selected sizes are incompatible with the new selected provider.
  // This prevents CoreSignal data from being left in Ocean.io and vice versa.
  useEffect(() => {
    const isHiringIntentSelected = selectedSearchSections?.includes(AiProspectingSearchMethods.Hiring);
    const isOceanIoCompanyProvider = selectedCompanyProvider === CompanyProviders.OceanIo;

    if (!isHiringIntentSelected || !isOceanIoCompanyProvider) return;

    const params = new URLSearchParams(searchParams);
    const filteredSections = selectedSearchSections.filter(
      (selectedMethod) => selectedMethod !== AiProspectingSearchMethods.Hiring
    );
    const newSelectedSections = filteredSections.length ? filteredSections : [AiProspectingSearchMethods.Web];

    form.setFieldValue('searchMethods', newSelectedSections);
    params.set('searchMethods', JSON.stringify(newSelectedSections));
    setSearchParams(params);
  }, [selectedCompanyProvider]);

  /**
   * Change field value in form and in search params
   * @param fieldName
   * @param value
   */
  function handleChange<T>(fieldName: string, value: T) {
    const params: Record<string, string> = Object.fromEntries(searchParams);

    // When the company provider changes, we clear the 'industry' and 'size' fields in the form
    // and remove them from the URL, as these fields vary depending on the provider.
    if (fieldName === 'companyProvider') {
      form.setFieldValue('industry', []);
      form.setFieldValue('size', []);
      const newSearchParams = new URLSearchParams(searchParams);

      delete params.industry;
      delete params.size;

      setSearchParams(newSearchParams);
    }

    form.setFieldValue(fieldName, value ?? '');
    const isEmpty = typeof value === 'object' ? !(value as unknown[])?.length : !value && typeof value !== 'boolean';

    setLeadsToShowInTable((prev) =>
      prev
        ? {
            ...prev,
            searchWasTriggered: false,
            companiesBatchIdReqParam: undefined,
            nextPageParamForCompanies: undefined
          }
        : {
            phase: '',
            leads: []
          }
    );

    // Clear key from query, if all values are removing from input
    if (isEmpty) {
      const { [fieldName]: currentKey, nextPageParamForCompanies, companiesBatchIdReqParam, ...restParams } = params;

      return setSearchParams({ ...restParams });
    }

    const {
      nextPageParamForCompanies: searchAfterParam,
      companiesBatchIdReqParam: searchBatchIdParam,
      ...restParams
    } = params;

    setSearchParams({ ...restParams, [fieldName]: typeof value === 'object' ? JSON.stringify(value) : String(value) });
  }

  /**
   * Handles input changes in the search form for a specific label.
   * @function
   * @param value - The new input value.
   * @param label - The label corresponding to the field being updated in the search state.
   */
  function searchInputHandle(value: string, label: string) {
    setSearch({
      ...search,
      [label]: String(value)
    });
  }

  /**
   * Get button text based on params
   */
  function getButtonText() {
    if (isLoading) {
      return <div className="flex gap-x-2">{t('searchLoading')}</div>;
    }

    return wasSearchTriggered ? t('continueSearch') : t('search');
  }

  /**
   * Handles the selection of a search section.
   * @param sectionName - The name of the search section to select.
   */
  function selectSearchSectionHandler(sectionName: AiProspectingSearchMethods) {
    const isAlreadySelected = selectedSearchSections?.includes(sectionName);

    if (isAlreadySelected && selectedSearchSections.length === 1) return;

    if (isAlreadySelected && selectedSearchSections?.length > 1) {
      handleChange(
        'searchMethods',
        selectedSearchSections.filter((section) => section !== sectionName)
      );

      return;
    }
    handleChange('searchMethods', [...selectedSearchSections, sectionName]);
  }

  return (
    <div className="mt-5 bg-[var(--active-page)] rounded-[5px] p-[10px] sm:p-[20px] border-[var(--input-border)] border-[1px] border-solid">
      <div>
        <BasicFiltersSection
          form={form}
          search={search}
          disabled={isLoading}
          showCommonValidationError={showCompanyCommonValidationError}
          searchInputHandle={searchInputHandle}
          handleChange={handleChange}
        />
        {hasWebNewsSearchFeatureEnabled && (
          <>
            <ResolveDuplicatesHeader
              type="info"
              message={t(hasWebNewsSearchFeatureEnabled ? 'importantCombinedIntentInfo' : 'importantSingleIntentInfo')}
            />
            <div className="flex items-center gap-4 mt-5">
              <Form.Item name="isDeepSearchEnabled" noStyle className="flex items-center" valuePropName="checked">
                <Switch
                  onChange={(checked) => handleChange('isDeepSearchEnabled', checked)}
                  disabled={isLoading}
                  className="bg-gray-400"
                />
              </Form.Item>
              <label className="text-sm font-bold flex items-center gap-1">
                {t('deepSearch')}
                <Hint content={t(isDeepSearchEnabled ? 'deepSearchEnabledInfo' : 'deepSearchDisabledInfo')}>
                  <InfoCircleOutlined className="cursor-pointer text-[var(--button-primary)]" />
                </Hint>
              </label>
            </div>
          </>
        )}
        {/* This Form.Item is used without children to manage correct selected section */}
        <Form.Item className="hidden" name="searchMethods" />
        <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 gap-5  my-5 ">
          {(Object.keys(searchSectionsComponents) as SearchSectionKey[]).map((method) => {
            const Component = searchSectionsComponents[method];

            const commonProps = {
              form,
              search,
              searchInputHandle,
              handleChange
            };

            return (
              <SearchSectionButton
                key={method}
                method={method}
                isLoading={isLoading}
                selectedSearchSections={selectedSearchSections}
                onClick={selectSearchSectionHandler}
                Component={Component}
                commonProps={commonProps}
              />
            );
          })}
        </div>
        <LeadFiltersSection
          form={form}
          disabled={isLoading}
          search={search}
          showCommonValidationError={showLeadsCommonValidationError}
          searchInputHandle={searchInputHandle}
          handleChange={handleChange}
        />
      </div>
      {showIntentLoader && (
        <IntentLeadsLoader
          isLoading={isLoading}
          funnelData={leadsToShowInTable?.funnelData}
          selectedSearchSections={selectedSearchSections}
          isDeepSearchEnabled={isDeepSearchEnabled}
          companiesWithLeads={companiesWithLeads}
          leadsCount={leadsToShowInTable?.leadsProcessed}
          vacanciesCount={leadsToShowInTable?.vacanciesProcessed}
          companiesOpened={leadsToShowInTable?.companiesOpened}
          currentPhase={currentPhase}
          cancelStream={cancelStream}
        />
      )}
      <Button
        data-testid="create-search-btn"
        aria-label="new-persona"
        size="large"
        htmlType="submit"
        className="mt-[30px] !px-[30px] !text-white !text-base !rounded-[5px] border-none bg-[var(--button-primary)] active:bg-[var(--button-primary-active)] enabled:hover:shadow-primary disabled:bg-[var(--button-disabled)]"
        disabled={isSearchDisabled}
      >
        <div className="flex">{getButtonText()}</div>
      </Button>
    </div>
  );
}
