import { useCallback, useRef, useState } from 'react';

import i18n from '../../../i18n';
import { CsrfResponse, useAppSelector } from '../../../redux-store';
import { baseUrl } from '../../../redux-store/constants';

export interface StreamParsedData {
  phase: string;
  data: string | number | Object;
}

export function useFetch() {
  const csrfQueryResponse = useAppSelector((state) => state.api.queries['getCsrf(null)']?.data as CsrfResponse);
  const csrfTokenFromState = csrfQueryResponse?.token;
  const [loading, setLoading] = useState(false);
  const [isStreaming, setStreaming] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);

  /**
   * Aborts the current fetch request if it is in progress.
   */
  const abortStream = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  }, []);

  /**
   * Fetch data with credentials, including CSRF token and language headers.
   * @param endpoint - The API endpoint to fetch data from.
   * @param  params - The parameters to send in the request body.
   * @returns  The response data or readable stream reader.
   */
  async function fetchWithCredentials(endpoint: string, params: string | number | Object) {
    setLoading(true);
    setStreaming(true);
    const url = `${baseUrl}/${endpoint}`;
    const abortController = new AbortController();

    abortControllerRef.current = abortController;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfTokenFromState,
          'Accept-Language': i18n.language
        },
        credentials: 'include',
        body: JSON.stringify(params),
        signal: abortController.signal
      });

      const contentType = response.headers.get('content-type');

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      let result;

      if (contentType === 'text/event-stream') {
        result = response.body?.pipeThrough(new TextDecoderStream()).getReader();
      } else {
        result = await response.json();
      }

      return result;
    } catch (responseError: unknown) {
      // `unknown` is used since errors can be any type. We check if `responseError` is an instance of Error.
      setStreaming(false);
      if (responseError instanceof Error) {
        setError(responseError);
      } else {
        setError(new Error(`An unknown error occurred: ${responseError}`));
      }
    } finally {
      setLoading(false);
    }
  }

  /**
   * Processes a readable stream in a loop, parsing the stream data and handling it using a callback function.
   * @param  stream - The readable stream to process.
   * @param handleValueCallback - The callback function to handle parsed data.
   */
  async function streamProcessingLoop(
    stream: ReadableStreamDefaultReader<string>,
    handleValueCallback: (parsedData: StreamParsedData) => void
  ) {
    let iteration = 0;
    const MAX_ITERATIONS = 10000;
    let buffer = '';

    while (iteration < MAX_ITERATIONS) {
      const { value: StreamValue, done } = await stream.read();

      if (done) {
        break;
      }

      if (StreamValue !== undefined) {
        buffer += StreamValue;
        const lines = buffer.split('\n\n');

        buffer = lines.pop() as string;

        for (const line of lines) {
          if (line.trim().length > 0) {
            const parsedData = parseStreamData(line.trim());

            handleValueCallback(parsedData);
          }
        }
      }
      ++iteration;
    }
    setStreaming(false);
  }

  /**
   * Parses stream data from a given string.
   * @param StreamValue - The string containing the stream data to be parsed.
   * @returns  An object containing the parsed phase and data.
   */
  function parseStreamData(StreamValue: string) {
    const lines = StreamValue.split('\n');
    const parsedData: StreamParsedData = { phase: '', data: {} };

    lines.forEach((line) => {
      const colonIndex = line.indexOf(':');

      const key = line.substring(0, colonIndex).trim();
      const value = line.substring(colonIndex + 1).trim();

      if (key === 'event') {
        parsedData.phase = value;
      } else if (key === 'data') {
        parsedData.data = JSON.parse(value);
      }
    });

    return parsedData;
  }

  return { loading, error, isStreaming, fetchWithCredentials, streamProcessingLoop, abortStream };
}
