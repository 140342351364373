import { Descriptions, Form, FormInstance, Input, Select, Switch } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useTranslation } from 'react-i18next';

import type { Company, Feature } from '../../../types';

interface EditFeatureFormProps {
  feature: Partial<Feature>;
  companies: Company[];
  form: FormInstance<Partial<Feature> & { companiesId: number[] }>;
  isLoading: boolean;
  chosenCompaniesId: number[];
  onSubmit: (values: Partial<Feature> & { companiesId: number[] }) => void;
}

export default function EditFeatureForm(props: EditFeatureFormProps) {
  const { feature, companies, form, isLoading, chosenCompaniesId, onSubmit } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'features' });

  /**
   * Show formatted validation error
   * @param name
   * @returns
   */
  const requiredValidation = (name: string) => [
    { required: true, message: t('validationError', { value: name.toLowerCase() }) }
  ];

  /**
   * Filter leads by first name, last name, email
   * @param input
   * @param option
   * @returns
   */
  function handleFilterOption(input: string, option?: DefaultOptionType) {
    const children = option?.children;

    return typeof children === 'string' ? (children as string)?.toLowerCase().includes(input.toLowerCase()) : false;
  }

  return (
    <Form
      form={form}
      layout="vertical"
      validateTrigger="onSubmit"
      initialValues={{ ...feature, companiesId: chosenCompaniesId }}
      onFinish={onSubmit}
    >
      <div className="flex gap-x-4 items-center mb-4">
        <Form.Item className="sm:!w-auto !m-0 !p-0" name="name">
          <Input size="large" className="sm:!w-auto !text-4xl font-extrabold" disabled={isLoading} />
        </Form.Item>
      </div>

      <Descriptions title={t('featureDetails')} bordered column={1} size="small" className="w-auto">
        <Descriptions.Item label={t('table.description')} className="bg-white rounded-r-lg">
          <Form.Item name="description" className="!w-auto !mb-0" rules={requiredValidation(t('table.description'))}>
            <Input.TextArea disabled={isLoading} autoSize />
          </Form.Item>
        </Descriptions.Item>
        <Descriptions.Item label={t('table.isDefaultEnabled')} className="bg-white rounded-r-lg">
          <Form.Item name="isDefaultEnabled" className="!w-auto" noStyle valuePropName="checked">
            <Switch
              disabled={isLoading}
              className="bg-gray-400"
              checkedChildren={t('table.enabled')}
              unCheckedChildren={t('table.disabled')}
            />
          </Form.Item>
        </Descriptions.Item>
      </Descriptions>

      <div className="mt-8">
        <Form.Item
          name="companiesId"
          labelCol={{ className: '!p-0' }}
          label={t('companiesSelectLabel', {
            condition: feature?.isDefaultEnabled ? t('isDefaultEnabled') : t('isDefaultDisabled')
          })}
        >
          <Select
            mode="multiple"
            className="w-full mt-2 multiple-select"
            allowClear
            bordered
            filterOption={handleFilterOption}
            disabled={isLoading}
            placeholder={t('companiesSelectPlaceholder')}
          >
            {companies?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </div>
    </Form>
  );
}
