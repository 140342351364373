import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * This hook used for set state with debounce
 * @param defaultValue
 * @param wait
 * @param options
 * @returns
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDebouncedState<T = any>(defaultValue: T, wait: number) {
  const [value, setValue] = useState(defaultValue);
  const timeoutRef = useRef<number | null>(null);
  const leadingRef = useRef(true);

  const clearTimeout = () => window.clearTimeout(timeoutRef.current!);

  useEffect(() => clearTimeout, []);

  const debouncedSetValue = useCallback((newValue: T) => {
    clearTimeout();

    timeoutRef.current = window.setTimeout(() => {
      leadingRef.current = true;
      setValue(newValue);
    }, wait);
    leadingRef.current = false;
  }, []);

  return [value, debouncedSetValue] as const;
}
