import { PlusOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useCreateFeatureMutation, useGetFeaturesQuery, useGetMeQuery } from '../../redux-store';
import { REFRESH_INTERVAL } from '../../redux-store/constants';
import { RolesEnum, type Feature } from '../../types';
import CreateFeatureModal from './components/CreateFeatureModal';

export default function Features() {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'features' });
  const navigate = useNavigate();
  const { currentData: currentUser, isLoading: isUserLoading } = useGetMeQuery(null);
  const hasFeatureAdminRole = !!currentUser?.hasRole(currentUser, RolesEnum.FeatureAdmin);

  const { currentData: features, isLoading } = useGetFeaturesQuery(
    {},
    {
      pollingInterval: REFRESH_INTERVAL
    }
  );
  const [createFeature] = useCreateFeatureMutation();

  const columns: ColumnsType<Feature> = [
    {
      title: t('table.name'),
      dataIndex: 'name',
      key: 'name',
      render: (value) => <div data-t={`feature-name-${value}`}>{value}</div>
    },
    {
      title: t('table.description'),
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: t('table.isDefaultEnabled'),
      dataIndex: 'isDefaultEnabled',
      key: 'isDefaultEnabled',
      render: (value) => <div data-t="feature-row">{value ? t('table.enabled') : t('table.disabled')}</div>
    }
  ];

  /**
   * Create single feature
   * @param values
   */
  async function handleCreateFeature(values: Pick<Feature, 'name' | 'description' | 'isDefaultEnabled'>) {
    try {
      await createFeature(values).unwrap();

      setOpenCreateModal(false);

      message.success(t('featuresCreateSuccess'));
    } catch (e) {
      message.error(t('featuresCreateError'));
    }
  }

  /**
   * Open "create feature modal
   */
  function handleOpenCreateModal() {
    setOpenCreateModal(true);
  }

  /**
   * Close "create feature modal
   */
  function handleCloseCreateModal() {
    setOpenCreateModal(false);
  }

  /**
   * Navigate to clicked feature
   * @param record
   */
  function onRowClick(record: Feature) {
    hasFeatureAdminRole ? navigate(`/admin/features/${record.id}`) : message.error(t('featureAccessError'));
  }

  if (!hasFeatureAdminRole) {
    return (
      <div className="font-medium flex items-center justify-center w-full h-full text-gray-400">{t('errorPage')}</div>
    );
  }

  return (
    <div>
      <div className="py-6">
        <div>
          <h2 className="mb-2 text-4xl font-extrabold" data-t="feature-page-header">
            {t('features')}
          </h2>

          <div className="flex justify-end mb-2">
            <Button
              data-t="create-feature-button"
              disabled={!hasFeatureAdminRole}
              htmlType="button"
              className={`flex gap-x items-center justify-center bg-white h-[46px] ${
                hasFeatureAdminRole ? ' hover:!text-[var(--action)] hover:!border-[var(--action)] ' : ''
              }`}
              onClick={handleOpenCreateModal}
            >
              {t('createFeature')}
              <PlusOutlined />
            </Button>
          </div>

          <div className="mt-4">
            <Table
              rowKey="id"
              dataSource={features}
              columns={columns}
              loading={{
                spinning: isLoading || isUserLoading,
                style: {
                  bottom: 0,
                  height: 'calc(100% - 50px)',
                  maxHeight: 'none',
                  top: 'initial'
                }
              }}
              rowClassName="cursor-pointer"
              onRow={(record) => ({
                onClick: () => onRowClick(record)
              })}
              pagination={false}
              scroll={{ x: 150 }}
              locale={{
                emptyText: <div className="my-[40px] h-[22px]">{isLoading ? '' : t('noFeatures')}</div>
              }}
            />
          </div>
          <CreateFeatureModal
            opened={openCreateModal}
            onClose={handleCloseCreateModal}
            onSubmit={handleCreateFeature}
          />
        </div>
      </div>
    </div>
  );
}
