import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import DynamicIntentHint from './DynamicIntentHint';
import { AiProspectingSearchMethods } from '../../../types/aiProspecting';
import { calculatePercentage } from '../../../services/calculatePercentage';
import { FunnelData } from '../AiProspectingBuilder';
import { ListIntentHint } from './ListIntentHint';

interface SearchFunnelProgressProps {
  companiesOpened: number;
  companiesWithLeads: number;
  isDeepSearchEnabled: boolean;
  selectedSearchSections: AiProspectingSearchMethods[];
  funnelData?: FunnelData;
  isLoading: boolean;
}

export enum FunnelSections {
  companiesProcessed = 'companiesProcessed',
  enrichmentSuccessful = 'enrichmentSuccessful',
  companiesWithLeads = 'companiesWithLeads'
}

export default function SearchFunnelProgress({
  isDeepSearchEnabled,
  companiesWithLeads,
  selectedSearchSections = [],
  companiesOpened = 0,
  funnelData,
  isLoading
}: SearchFunnelProgressProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const moreThanOneSearchSelected = selectedSearchSections.length > 1;
  const isHiringSearch =
    !moreThanOneSearchSelected && selectedSearchSections.includes(AiProspectingSearchMethods.Hiring);
  // Calculate successfulEnrichmentAmount
  const successfulEnrichmentAmount = calculateSuccessfulEnrichmentAmount();
  const companiesProcessedAmount = isHiringSearch ? funnelData?.companiesProcessed : companiesOpened;
  const enrichmentPercentage = calculatePercentage(successfulEnrichmentAmount, companiesProcessedAmount);
  // Calculate the percentage of companies with leads
  const leadsFoundPercentage = calculatePercentage(companiesWithLeads, companiesProcessedAmount);
  const blocks = [
    {
      name: t(`${FunnelSections.companiesProcessed}`),
      section: FunnelSections.companiesProcessed,
      percentage: 100,
      amount: companiesProcessedAmount,
      background: 'bg-[var(--funnel-purple)]'
    },
    {
      name: t(`${FunnelSections.enrichmentSuccessful}`),
      section: FunnelSections.enrichmentSuccessful,
      percentage: enrichmentPercentage,
      amount: successfulEnrichmentAmount,
      isDeepSearch: isDeepSearchEnabled,
      background: 'bg-[var(--funnel-blue)]'
    },
    {
      name: t(`${FunnelSections.companiesWithLeads}`),
      section: FunnelSections.companiesWithLeads,
      percentage: leadsFoundPercentage,
      amount: companiesWithLeads,
      background: 'bg-[var(--funnel-green)]'
    }
  ];

  /**
   * Calculate data for the block in the centre
   * @returns
   */
  function calculateSuccessfulEnrichmentAmount(): number {
    // If it's a Hiring search, use amount of companies with vacancies
    if (isHiringSearch) return funnelData?.companiesWithVacancy.length || 0;
    // If Deep Search is enabled, use the count from the last selected section
    if (isDeepSearchEnabled) {
      const lastSelectedSection = selectedSearchSections[selectedSearchSections.length - 1];

      return funnelData?.[lastSelectedSection] || 0;
    }

    // For not deep search, sum up the counts from all selected sections
    return selectedSearchSections.reduce((sum, section) => sum + (funnelData?.[section] || 0), 0);
  }

  return (
    <div className="flex justify-center items-center" data-test-id="aaaaaaa">
      <div className="flex flex-col gap-[10px] w-full text-[var(--dark)] rounded p-1.5 m-[5px 10px 5px]">
        {blocks.map(({ percentage, name, section, amount, background, isDeepSearch = false }) => (
          <div className="flex min-w-[200px] w-full">
            <div
              className="flex items-center text-[var(--placeholder)] font-bold w-[45px] mr-2.5"
              data-testid={`${section}-percentage`}
            >
              {percentage}%
            </div>
            <div className="relative flex justify-center w-full">
              <div
                className={cn('h-[45px] absolute top-0 rounded min-w-[5px]', background)}
                style={{ width: `${percentage}%`, transition: 'width 0.5s linear' }}
              />
              <div className="relative z-10 flex flex-col items-center">
                <div className="flex gap-[5px] justify-center  items-center text-center">
                  <div data-testid={`${section}-name`}>{name}</div>
                  {section === FunnelSections.companiesProcessed && !isHiringSearch && (
                    <ListIntentHint items={funnelData?.companyNames || []} isLoading={isLoading} />
                  )}
                  {isDeepSearch && moreThanOneSearchSelected && (
                    <div className="flex items-center" data-testid="dynamic-hint">
                      <DynamicIntentHint
                        selectedSearchSections={selectedSearchSections}
                        totalCount={companiesOpened}
                        funnelData={funnelData}
                      />
                    </div>
                  )}
                </div>
                <div className="font-bold" data-testid={`${section}-amount`}>
                  {amount || 0}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
