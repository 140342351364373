import { Button, Form, FormInstance, Select } from 'antd';
import { DefaultOptionType } from 'antd/es/select';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useGetCampaignLeadsQuery, useGetCampaignsQuery } from '../../../redux-store';
import { REFRESH_INTERVAL } from '../../../redux-store/constants';
import { SelectFormValues, StatusesOptions } from '../Sqs';

interface ChooseCampaignLeadFormProps {
  form: FormInstance<SelectFormValues>;
  isSubmitLoading: boolean;
  onSubmit: (values: SelectFormValues) => Promise<void>;
  statusesOptions: StatusesOptions[];
}

const initialValues: SelectFormValues = {
  campaignId: null,
  campaignLeadId: null,
  newState: null
};

export default function ChooseCampaignLeadForm(props: ChooseCampaignLeadFormProps) {
  const { form, statusesOptions, isSubmitLoading, onSubmit } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'sqs' });
  const selectedCampaignId = Form.useWatch('campaignId', form);
  const selectedCampaignLeadId = Form.useWatch('campaignLeadId', form);
  const selectedNewStatus = Form.useWatch('newState', form);
  const { currentData: leadsData, isLoading: isCampaignLeadsLoading } = useGetCampaignLeadsQuery(
    {
      id: Number(selectedCampaignId)
    },
    {
      skip: !selectedCampaignId,
      pollingInterval: REFRESH_INTERVAL
    }
  );
  const { currentData: campaignsData, isLoading: isCampaignsLoading } = useGetCampaignsQuery(
    {},
    {
      pollingInterval: REFRESH_INTERVAL
    }
  );

  const isAllFieldsFilled = selectedCampaignId && selectedCampaignLeadId && selectedNewStatus;

  /**
   * Campaigns select options
   */
  const campaignsSelectOptions: DefaultOptionType[] = useMemo(
    () =>
      campaignsData?.data?.map((item) => ({
        label: item.name,
        labelText: item.name,
        value: item.id
      })) || [],
    [campaignsData]
  );

  /**
   * Leads select options
   */
  const leadsSelectOptions: DefaultOptionType[] = useMemo(
    () =>
      leadsData?.data?.map((item) => ({
        label: (
          <div role="button" aria-hidden="true" tabIndex={0}>
            <span>
              {item.firstName} {item.lastName}
            </span>{' '}
            <span>{`<${item.email}>`}</span> <span className="text-gray-400/70">ID: {item.id}</span>
          </div>
        ),
        labelText: `${item.firstName} ${item.lastName} ${item.email} ${item.id}`,
        value: item.id
      })) || [],
    [leadsData]
  );

  /**
   * Filter leads by first name, last name, email
   * @param input
   * @param option
   * @returns
   */
  function handleFilterOption(input: string, option?: DefaultOptionType) {
    const value: string = option?.labelText;

    return value.toLowerCase().includes(input.toLowerCase());
  }

  return (
    <Form form={form} className="w-1/3" initialValues={initialValues} onFinish={onSubmit} layout="vertical">
      <Form.Item name="campaignId" label={t('selectCampaignLeadForm.selectCampaign')}>
        <Select
          showSearch
          onChange={() => form.resetFields(['campaignLeadId'])}
          popupClassName="!max-w-[100%] !whitespace-pre-wrap"
          popupMatchSelectWidth={false}
          placement="bottomRight"
          placeholder={t('placeholders.campaign')}
          options={campaignsSelectOptions}
          filterOption={handleFilterOption}
          disabled={isCampaignsLoading || isSubmitLoading}
        />
      </Form.Item>
      <Form.Item name="campaignLeadId" label={t('selectCampaignLeadForm.selectLead')}>
        <Select
          showSearch
          popupClassName="!max-w-[100%] !whitespace-pre-wrap"
          popupMatchSelectWidth={false}
          placement="bottomRight"
          placeholder={t('placeholders.lead')}
          options={leadsSelectOptions}
          filterOption={handleFilterOption}
          disabled={isCampaignLeadsLoading || !selectedCampaignId || isSubmitLoading}
        />
      </Form.Item>

      {selectedCampaignLeadId ? (
        <Form.Item name="newState" label={t('selectCampaignLeadForm.selectStatus')}>
          <Select
            popupClassName="!max-w-[100%] !whitespace-pre-wrap"
            popupMatchSelectWidth={false}
            placement="bottomRight"
            placeholder={t('placeholders.newStatus')}
            options={statusesOptions}
            disabled={!selectedCampaignLeadId || isSubmitLoading}
          />
        </Form.Item>
      ) : null}

      <Form.Item>
        <Button htmlType="submit" className="bg-white" disabled={!isAllFieldsFilled || isSubmitLoading}>
          {!isSubmitLoading ? t('addToQueue') : t('addToQueueLoading')}
        </Button>
      </Form.Item>
    </Form>
  );
}
