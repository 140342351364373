import { useMemo } from 'react';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';

import MultiSelect from '../components/MultiSelect';
import ResolveDuplicatesHeader from '../../Builder/components/ResolveDuplicatesHeader/ResolveDuplicatesHeader';
import {
  ApolloManagementLevels,
  Features,
  LeadProvider,
  RocketReachDepartments,
  RocketReachManagementLevels,
  SwarmDepartments,
  SwarmManagementLevels,
  YearsExperience
} from '../../../types';
import {
  LeadProviderSuggestionsFields,
  useGetLeadProviderFieldSuggestionsQuery,
  useGetMeQuery
} from '../../../redux-store';
import { SectionsProps } from './ProspectsWrapper';
import { REFRESH_INTERVAL } from '../../../redux-store/constants';
import { transformEnumToOptions, transformSuggestionsToOptions } from '../../../services/transformOption';
import { ApolloDepartments } from '../../../types/apollo/apolloDepartments';
import { ZoomInfoDepartments } from '../../../types/zoomInfo/zoomInfoDepartments';
import { ZoomInfoManagementLevels } from '../../../types/zoomInfo/zoomInfoManagementLevels';

import { ReactComponent as LeadFiltersSectionIcon } from '../images/leadFiltersIcon.svg';
import ApolloLogo from '../../Builder/images/apolloLogo.svg';
import RocketReachLogo from '../../Builder/images/rocketReachLogo.svg';
import AiSdrLogo from '../../Builder/images/aiSdrLogo.svg';
import ZoomInfoLogo from '../../Builder/images/zoominfoLogo.svg';

export default function LeadFiltersSection({
  form,
  disabled,
  search,
  showCommonValidationError,
  searchInputHandle,
  handleChange
}: SectionsProps) {
  const leadProvider = Form.useWatch('leadProvider', { form });
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const { t: tBuilder } = useTranslation('translation', { keyPrefix: 'builder' });
  const isApolloProviderSelected = leadProvider === LeadProvider.Apollo;
  const isSwarmProviderSelected = leadProvider === LeadProvider.Swarm;
  const isZoomInfoProviderSelected = leadProvider === LeadProvider.ZoomInfo;
  const { currentData: user } = useGetMeQuery(null, {
    pollingInterval: REFRESH_INTERVAL
  });
  const { currentData: jobTitles, isFetching: isJobTitleLoading } = useGetLeadProviderFieldSuggestionsQuery(
    {
      field: LeadProviderSuggestionsFields.CurrentTitle,
      input: search?.leadJobTitle,
      leadProviderOptions: { leadProvider }
    },
    { skip: !search?.leadJobTitle.trim() || isSwarmProviderSelected }
  );
  const hasApolloIntegrationFeature = user?.hasFeature(user, Features.ApolloIntegration);
  const hasSwarmFeatureEnabled = user?.hasFeature(user, Features.SwarmIntegration);
  const hasZoomInfoFeatureEnabled = user?.hasFeature(user, Features.ZoomInfoIntegration);
  const leadProviderDepartments = useMemo(() => {
    if (leadProvider === LeadProvider.Apollo) return ApolloDepartments;

    if (leadProvider === LeadProvider.Swarm) return SwarmDepartments;

    if (isZoomInfoProviderSelected) return ZoomInfoDepartments;

    return RocketReachDepartments;
  }, [leadProvider]);
  const managementLevelsOptions = useMemo(() => {
    if (leadProvider === LeadProvider.Apollo)
      return transformEnumToOptions({
        value: ApolloManagementLevels,
        key: 'apolloManagementLevels',
        baseLabel: 'leadFilters',
        t
      });

    if (leadProvider === LeadProvider.Swarm)
      return transformEnumToOptions({
        value: SwarmManagementLevels,
        key: 'swarmManagementLevels',
        baseLabel: 'leadFilters',
        t
      });

    if (isZoomInfoProviderSelected)
      return transformEnumToOptions({
        value: ZoomInfoManagementLevels,
        key: 'zoomInfoManagementLevels',
        baseLabel: 'advancedSearch.form.selects',
        t: tBuilder
      });

    return transformEnumToOptions({
      value: RocketReachManagementLevels,
      key: 'rocketReachManagementLevels',
      baseLabel: 'leadFilters',
      t
    });
  }, [leadProvider]);
  const availableLeadProviderOptions = useMemo(() => {
    const leadProviderOptions = [{ value: LeadProvider.RocketReach, icon: RocketReachLogo }];

    if (hasApolloIntegrationFeature) leadProviderOptions.push({ value: LeadProvider.Apollo, icon: ApolloLogo });

    if (hasSwarmFeatureEnabled) leadProviderOptions.push({ value: LeadProvider.Swarm, icon: AiSdrLogo });

    if (hasZoomInfoFeatureEnabled) leadProviderOptions.push({ value: LeadProvider.ZoomInfo, icon: ZoomInfoLogo });

    return leadProviderOptions;
  }, [hasApolloIntegrationFeature, hasSwarmFeatureEnabled]);

  /**
   * Handle lead provider change
   * @param value
   */
  function handleLeadProviderChange(value: string) {
    handleChange('department', []);
    handleChange('managementLevel', []);
    handleChange('leadProvider', value);
  }

  return (
    <div data-testid="lead-filters-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2">
        <LeadFiltersSectionIcon />
        <span>{t('leadFilters.blockName')}</span>
      </div>
      {showCommonValidationError && (
        <div data-testid="leads-filters-warning">
          <ResolveDuplicatesHeader message={t('commonValidationAlert')} type="error" />
        </div>
      )}
      <div className="mt-5 bg-white rounded-lg p-5 border border-solid border-[var(--input-border)]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          {availableLeadProviderOptions.length > 1 && (
            <Form.Item
              data-t="lead-filters-select-provider-dropdown"
              name="leadProvider"
              label={<span className="font-bold">{t('leadFilters.labels.provider')}</span>}
              className="mb-0"
            >
              <Select
                onChange={handleLeadProviderChange}
                disabled={disabled}
                placeholder={t('leadFilters.placeholders.leadProvider')}
              >
                {availableLeadProviderOptions?.map(({ value, icon }) => (
                  <Select.Option data-t={`lead-filters-select-${value}-provider-option`} key={value}>
                    <div className="flex items-center gap-[5px]">
                      <img src={icon} alt="providerIcon" />
                      <span>{value}</span>
                    </div>
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          <Form.Item
            name="leadJobTitle"
            label={<span style={{ fontWeight: 'bold' }}>{t('leadFilters.labels.leadJobTitle')}</span>}
            className="!mb-0"
          >
            <MultiSelect
              showSearch
              id="leadJobTitle"
              mode="tags"
              disabled={disabled}
              placeholder={t('leadFilters.placeholders.leadJobTitle')}
              isLoading={isJobTitleLoading}
              options={transformSuggestionsToOptions(jobTitles, leadProvider)}
              setSearch={(value) => searchInputHandle(value, 'leadJobTitle')}
              setSelected={(value) => handleChange('leadJobTitle', value)}
            />
          </Form.Item>
          <Form.Item
            name="managementLevel"
            label={<span style={{ fontWeight: 'bold' }}>{t('leadFilters.labels.managementLevel')}</span>}
            className="!mb-0"
          >
            <MultiSelect
              showSearch
              id="managementLevel"
              mode={isSwarmProviderSelected ? 'multiple' : 'tags'}
              disabled={disabled}
              placeholder={t('leadFilters.placeholders.managementLevel')}
              options={managementLevelsOptions}
              setSelected={(value) => handleChange('managementLevel', value)}
            />
          </Form.Item>

          <Form.Item
            data-t="ai-prospecting-department-input"
            name="department"
            label={<span style={{ fontWeight: 'bold' }}>{t('leadFilters.labels.department')}</span>}
            className="!mb-0"
          >
            <MultiSelect
              showSearch
              id="department"
              mode={isApolloProviderSelected || isSwarmProviderSelected ? 'multiple' : 'tags'}
              disabled={disabled}
              placeholder={t('leadFilters.placeholders.department')}
              options={transformEnumToOptions({
                key: 'departments',
                value: leadProviderDepartments,
                baseLabel: 'leadFilters',
                t
              })}
              setSelected={(value) => handleChange('department', value)}
            />
          </Form.Item>

          {!isSwarmProviderSelected && !isZoomInfoProviderSelected && (
            <Form.Item
              name="experience"
              label={<span style={{ fontWeight: 'bold' }}>{t('leadFilters.labels.experience')}</span>}
              className="!mb-0"
            >
              <MultiSelect
                id="experience"
                mode="tags"
                disabled={disabled}
                placeholder={t('leadFilters.placeholders.experience')}
                options={transformEnumToOptions({
                  key: 'experience',
                  value: YearsExperience,
                  baseLabel: 'leadFilters',
                  t
                })}
                setSelected={(value) => handleChange('experience', value)}
              />
            </Form.Item>
          )}
        </div>
      </div>
    </div>
  );
}
