import classNames from 'classnames';
import { Form, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

import { NewsProviders } from '../../../types/aiProspecting';

import DatePicker from '../components/DatePicker';
import { InputTags } from '../components/InputTags';
import Hint from '../components/Hint';
import { SectionsProps } from './ProspectsWrapper';

import NewspaperIcon from '../images/newspaper.svg';
import NewsApiIcon from '../images/newsApi.svg';
import NewsAiIcon from '../images/newsAi.svg';

const newsProviders = [
  { label: NewsProviders.NewsApi, icon: NewsApiIcon },
  { label: NewsProviders.NewsAi, icon: NewsAiIcon }
];
const newsApiEarliestDate = '2017-10-01';

export default function NewsSearchSection({ handleChange, isSelected, disabled }: SectionsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });

  return (
    <div className="h-full flex flex-col" data-testid="news-search-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2">
        <img src={NewspaperIcon} alt="Newspaper" />
        <span data-testid="news-search-title">{t('newsSearch.blockName')}</span>
      </div>
      <div
        className={classNames('h-full bg-white rounded-[5px] p-[20px] border border-solid flex flex-col gap-5', {
          'border-[1.5px] border-[var(--button-primary)]': isSelected,
          'hover:border-[var(--tertiary)] hover:shadow-secondary': !isSelected && !disabled
        })}
        data-testid="news-search-container"
      >
        <Form.Item
          name="newsProvider"
          label={<span className="font-bold">{t('newsSearch.newsProvider')}</span>}
          className="mb-0"
        >
          <Select
            disabled={disabled}
            className="h-[48px]"
            onChange={(value) => {
              handleChange('newsProvider', value);
            }}
            data-testid="news-provider-select"
          >
            {newsProviders?.map(({ label, icon }) => (
              <Select.Option key={label}>
                <div className="flex items-center gap-[5px]">
                  <img src={icon} alt="providerIcon" />
                  <span>{label}</span>
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[{ required: isSelected, message: t('newsSearch.keywordsRequired') }]}
          name="newsQuery"
          label={
            <div className="flex items-center gap-1">
              <span style={{ fontWeight: 'bold' }}>{t('newsSearch.searchKeywords')}</span>
              <Hint content={t('inputTagsTooltip')}>
                <InfoCircleOutlined className="cursor-pointer text-[var(--button-primary)]" />
              </Hint>
            </div>
          }
          className="!mb-0"
        >
          <InputTags
            placeholder={t('newsSearch.keywordsPlaceholder')}
            disabled={disabled}
            callback={(value) => {
              handleChange('newsQuery', value);
            }}
          />
        </Form.Item>
        <Form.Item
          name="newsDate"
          label={<span style={{ fontWeight: 'bold' }}>{t('newsSearch.latestDate')}</span>}
          data-testid="news-date-picker"
          className="!mb-0"
        >
          <DatePicker
            startDate={dayjs(newsApiEarliestDate)}
            endDate={dayjs()}
            disabled={disabled}
            onChange={(_, value) => {
              handleChange('newsDate', value);
            }}
            placeholder={t('newsSearch.datePlaceholder')}
            format="YYYY-MM-DD"
          />
        </Form.Item>
      </div>
    </div>
  );
}
