import { Role } from '../../types/role';
import { DefaultSearchParams } from '../types';

import { apiSlice } from './apiSlice';

export const rolesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<Role[], { params?: DefaultSearchParams }>({
      query: ({ params }) => ({
        url: 'roles',
        params
      })
    })
  })
});

export const { useGetRolesQuery } = rolesApi;
