import { Loading3QuartersOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import cn from 'classnames';

interface SpinnerProps {
  spinnerClassName?: string;
  size?: number;
}

export default function Spinner(props: SpinnerProps) {
  const { spinnerClassName, size } = props;

  return (
    <Spin
      aria-label="spinner"
      indicator={
        <Loading3QuartersOutlined style={{ fontSize: size || 20 }} size={size || 20} className="!text-current" spin />
      }
      className={cn('!text-current', spinnerClassName)}
    />
  );
}

Spinner.defaultProps = {
  spinnerClassName: '',
  size: 20
};
