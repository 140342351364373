import { ReactNode } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

interface PageWrapperProps {
  children: ReactNode;
  title?: string;
}

/**
 * This component used for setting title for page
 */
export default function PageWrapper({ children, title }: PageWrapperProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  return (
    <>
      <Helmet>
        <title>{`${title ? `${title} - ` : ''}${t('titleSuffix')}` || t('defaultTitle')}</title>
      </Helmet>
      {children}
    </>
  );
}
