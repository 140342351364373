import type { Campaign, Lead, Persona } from '../../types';

import { CampaignLeadState, CampaignStatus, Event, EventSelectType } from '../../types';
import { CampaignLead } from '../../types/campaignLead';
import { ProviderTags } from '../constants';
import { DefaultPaginationParams, DefaultSearchParams } from '../types';
import { apiSlice } from './apiSlice';

interface GetAllCampaignsResponse {
  data: Campaign[];
  totalCount: number;
}

interface GetAllCampaignsRequest {
  params?: DefaultPaginationParams & { search?: string; status?: CampaignStatus };
}

export interface CreateCampaignBuilderRequest {
  status: CampaignStatus;
  name: string;
  description: string;
  numberOfFollowUps: number;
  daysBetweenFollowUps: number;
  personaId: number | null;
  personaType: 'new' | 'existing';
  persona: Pick<Persona, 'name' | 'prompt'> | null;
  leadsId: number[];
  leads: Partial<Lead>[];
}

interface GetCampaignEventsResponse {
  data: Event[];
  totalCount: number;
}

interface GetCampaignEventsRequest {
  id: number;
  params?: DefaultPaginationParams & { event: EventSelectType };
}

interface DeleteCampaignRequest {
  id: number;
  personaId: number;
}

interface GetCampaignLeadsResponse {
  data: CampaignLead[];
  totalCount: number;
}

interface GetCampaignLeadsRequest {
  id: number;
  params?: {
    page?: number;
    limit?: number;
    search?: string;
    status?: CampaignLeadState | 'all';
  };
}

interface GetCampaignLeadRequest {
  campaignId: number;
  campaignLeadId: number;
}

export interface GetCampaignLeadResponse {
  id: number;
  state: CampaignLeadState;
  lead: Lead;
}

interface SendCampaignLeadToQueueRequest {
  id: number;
  newState?: CampaignLeadState;
}

interface SendWholeCampaignToQueueRequest {
  campaignId: number;
  newState?: CampaignLeadState;
}

interface ResolveCrossCampaignDuplicatesResponse {
  message?: string;
  error?: string;
}

export interface ExtendedCampaignLead extends Partial<Lead> {
  id: number;
  campaignId: number;
  state: CampaignLeadState;
  campaignName: string;
  linkedin: string;
  campaignStatus: string;
  // This fields is used by antd table to check if row is expandable
  children?: ExtendedCampaignLead[];
}

interface GetCrossCampaignDuplicatedLeadsRequest {
  leadsEmails: string[];
  currentCampaignId?: number;
}

interface GetCrossCampaignDuplicatedLeadsResponse {
  crossCampaignDuplicates: Record<string, ExtendedCampaignLead[]>;
}

interface SendEmailManuallyRequestBody {
  campaignLeadId: number;
  manualReply: string;
}

export const campaignsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaigns: builder.query<GetAllCampaignsResponse, GetAllCampaignsRequest>({
      query: ({ params }) => {
        let requestedParams;

        if (params) {
          const { status, ...restParams } = params;

          // Exclude 'all' value
          if (status !== CampaignStatus.All && status) {
            requestedParams = {
              ...restParams,
              status
            };
          } else {
            requestedParams = {
              ...restParams
            };
          }
        }

        return {
          url: `campaigns`,
          params: requestedParams
        };
      },

      providesTags: (result) => {
        const allCampaigns = result?.data?.map(({ id }) => ({ type: ProviderTags.Campaigns, id }));

        return allCampaigns?.length ? allCampaigns : [{ type: ProviderTags.Campaigns }];
      }
    }),
    getCampaign: builder.query<Campaign, Pick<Campaign, 'id'> & { params?: DefaultSearchParams }>({
      query: ({ id, params }) => ({
        url: `campaigns/${id}`,
        params
      }),
      providesTags: (result, error, { id }) => [{ type: ProviderTags.CampaignGeneralDetails, id }]
    }),
    getCampaignEvents: builder.query<GetCampaignEventsResponse, GetCampaignEventsRequest>({
      query: ({ id, params }) => {
        let requestedParams;

        if (params) {
          const { event, ...restParams } = params;

          // Exclude 'all' value
          if (event !== 'all') {
            requestedParams = {
              ...restParams,
              event
            };
          } else {
            requestedParams = {
              ...restParams
            };
          }
        }

        return {
          url: `campaigns/${id}/events`,
          params: requestedParams
        };
      },
      providesTags: (result, error, { id }) => [{ type: ProviderTags.CampaignEvents, id }]
    }),
    createCampaign: builder.mutation<Campaign, Partial<CreateCampaignBuilderRequest>>({
      query: (body) => ({
        url: 'campaigns',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.Campaigns, ProviderTags.Persona, ProviderTags.Leads, ProviderTags.Me]
    }),
    editCampaign: builder.mutation<Campaign, Partial<Campaign>>({
      query: (body) => ({
        url: `campaigns/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: ProviderTags.CampaignGeneralDetails, id },
        { type: ProviderTags.CampaignLeads, id },
        { type: ProviderTags.CampaignLeads, id },
        { type: ProviderTags.CampaignEvents, id }
      ]
    }),
    deleteCampaign: builder.mutation<void, DeleteCampaignRequest>({
      query: ({ id }) => ({
        url: `campaigns/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { personaId }) => [
        { type: ProviderTags.Campaigns },
        { type: ProviderTags.Leads },
        { type: ProviderTags.Persona, id: personaId },
        { type: ProviderTags.Campaigns },
        ProviderTags.Me
      ]
    }),
    getCampaignLeads: builder.query<GetCampaignLeadsResponse, GetCampaignLeadsRequest>({
      query: ({ id, params }) => {
        let requestedParams;

        if (params) {
          const { status, ...restParams } = params;

          // Exclude 'all' value
          if (status !== 'all' && status) {
            requestedParams = {
              ...restParams,
              status
            };
          } else {
            requestedParams = {
              ...restParams
            };
          }
        }

        return {
          url: `campaigns/${id}/leads`,
          params: requestedParams
        };
      },
      providesTags: (result, error, { id }) => [{ type: ProviderTags.CampaignLeads, id }]
    }),
    getCampaignLead: builder.query<GetCampaignLeadResponse, GetCampaignLeadRequest>({
      query: ({ campaignId, campaignLeadId }) => ({
        url: `campaigns/${campaignId}/leads/${campaignLeadId}`
      }),
      providesTags: (result, error, { campaignLeadId }) => [
        { type: ProviderTags.CampaignLeads, id: campaignLeadId },
        { type: ProviderTags.CampaignLead, id: campaignLeadId }
      ]
    }),
    followUpCampaignLead: builder.mutation({
      query: ({ campaignLeadId, action }) => ({
        url: `campaign-lead/${campaignLeadId}/change-state`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ action })
      }),
      invalidatesTags: (result, error, { id, leadId }) => [{ type: ProviderTags.CampaignLeads, id }]
    }),
    changeCampaignLeadsState: builder.mutation<void, void>({
      query: () => ({
        url: `campaign-lead/change-campaign-leads-state`,
        method: 'GET'
      }),
      invalidatesTags: [{ type: ProviderTags.CampaignLeads }]
    }),
    sendCampaignLeadToQueue: builder.mutation<void, SendCampaignLeadToQueueRequest>({
      query: ({ id, newState }) => ({
        url: `campaign-lead/${id}/send-to-queue`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ newState })
      }),
      invalidatesTags: [{ type: ProviderTags.CampaignLeads }]
    }),
    deleteCampaignLead: builder.mutation({
      query: ({ id, campaignLeadId }) => ({
        url: `campaigns/${id}/leads/${campaignLeadId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, { id, leadId }) => [
        { type: ProviderTags.Leads },
        { type: ProviderTags.Campaigns },
        { type: ProviderTags.CampaignLeads, id }
      ]
    }),
    addCampaignLead: builder.mutation({
      query: ({ id, leadId }) => ({
        url: `campaigns/${id}/leads/${leadId}`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, { id, leadId }) => [
        { type: ProviderTags.Campaigns },
        { type: ProviderTags.CampaignLeads, id },
        { type: ProviderTags.Leads }
      ]
    }),
    getCrossCampaignDuplicatedLeads: builder.mutation<
      GetCrossCampaignDuplicatedLeadsResponse,
      GetCrossCampaignDuplicatedLeadsRequest
    >({
      query: ({ leadsEmails, currentCampaignId }) => ({
        url: 'cross-campaign-duplicates/get',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: { leadsEmails, currentCampaignId }
      })
    }),
    resolveCrossCampaignDuplicates: builder.mutation<
      ResolveCrossCampaignDuplicatesResponse,
      { leadsToClose: ExtendedCampaignLead[] }
    >({
      query: ({ leadsToClose }) => ({
        url: 'cross-campaign-duplicates/resolve',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: { leadsToClose }
      })
    }),
    sendWholeCampaignToQueue: builder.mutation<void, SendWholeCampaignToQueueRequest>({
      query: (body) => ({
        url: `campaigns/push-campaign-to-queue`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      })
    }),
    sendEmailManually: builder.mutation<void, SendEmailManuallyRequestBody>({
      query: ({ campaignLeadId, manualReply }) => ({
        url: `manual-reply/send`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: { campaignLeadId, manualReply }
      }),
      invalidatesTags: (result, error, { campaignLeadId }) => [
        { type: ProviderTags.CampaignLead, campaignLeadId },
        { type: ProviderTags.CampaignLeads, campaignLeadId },
        { type: ProviderTags.CampaignEvents, campaignLeadId }
      ]
    })
  })
});

export const {
  useGetCampaignsQuery,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useEditCampaignMutation,
  useDeleteCampaignMutation,
  useGetCampaignLeadsQuery,
  useDeleteCampaignLeadMutation,
  useAddCampaignLeadMutation,
  useLazyGetCampaignLeadsQuery,
  useGetCampaignEventsQuery,
  useGetCampaignLeadQuery,
  useFollowUpCampaignLeadMutation,
  useChangeCampaignLeadsStateMutation,
  useSendCampaignLeadToQueueMutation,
  useGetCrossCampaignDuplicatedLeadsMutation,
  useResolveCrossCampaignDuplicatesMutation,
  useSendWholeCampaignToQueueMutation,
  useSendEmailManuallyMutation
} = campaignsApi;
