import classNames from 'classnames';
import { useEffect, useState, useCallback } from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { unstable_useBlocker as useBlocker } from 'react-router-dom';

import StepsLoader from './StepsLoader';
import SearchFunnelProgress from './SearchFunnelProgress';
import { FunnelData, Phases } from '../AiProspectingBuilder';
import { RouterHistoryInterface } from '../../Builder/services/usePrompt';
import { AiProspectingSearchMethods } from '../../../types/aiProspecting';

import { ReactComponent as Phase1Step1 } from '../images/phase1step1.svg';
import { ReactComponent as Phase1Step2 } from '../images/phase1step2.svg';
import { ReactComponent as Phase1Step3 } from '../images/phase1step3.svg';
import { ReactComponent as Phase2Step1 } from '../images/phase2step1.svg';
import { ReactComponent as Phase2Step2 } from '../images/phase2step2.svg';
import { ReactComponent as Phase2Step3 } from '../images/phase2step3.svg';
import { ReactComponent as Phase2Step4 } from '../images/phase2step4.svg';
import { ReactComponent as Phase2Step5 } from '../images/phase2step5.svg';
import { ReactComponent as Phase2Step6 } from '../images/phase2step6.svg';
import { ReactComponent as Phase3Step1 } from '../images/phase3step1.svg';
import { ReactComponent as Phase3Step2 } from '../images/phase3step2.svg';

import styles from './intentLeadsLoader.module.css';

type StepImage = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

interface IntentLeadsLoaderProps {
  isLoading: boolean;
  isDeepSearchEnabled: boolean;
  selectedSearchSections: AiProspectingSearchMethods[];
  companiesWithLeads: number;
  currentPhase: number;
  vacanciesCount?: number;
  companiesOpened?: number;
  leadsCount?: number;
  cancelStream: () => void;
  funnelData?: FunnelData;
}

export default function IntentLeadsLoader({
  isLoading,
  isDeepSearchEnabled,
  selectedSearchSections,
  currentPhase,
  vacanciesCount = 0,
  companiesOpened = 0,
  leadsCount = 0,
  companiesWithLeads,
  funnelData,
  cancelStream
}: IntentLeadsLoaderProps) {
  const { t: tProspects } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const [currentStep, setCurrentPhase] = useState(0);
  const stepImages: { [key: number]: StepImage[] } = {
    [Phases.Companies]: [Phase1Step1, Phase1Step2, Phase1Step3],
    [Phases.Intent]: [Phase2Step1, Phase2Step2, Phase2Step3, Phase2Step4, Phase2Step5, Phase2Step6],
    [Phases.Finish]: [Phase3Step1, Phase3Step2],
    [Phases.SearchCompleted]: [Phase3Step1]
  };
  const totalSteps = Object.keys(stepImages).length;
  const CurrentImage = stepImages[currentPhase][currentStep] || null;

  /**
   * We are using useBlocker to access nextLocation property and validate whether user is leaving a page to call cancelStream
   */
  useBlocker(
    useCallback((history: RouterHistoryInterface) => {
      const { currentLocation, nextLocation } = history;
      const shouldCancelStream = currentLocation.pathname !== nextLocation.pathname;

      if (shouldCancelStream) cancelStream();

      return false;
    }, [])
  );

  /**
   * Cyclic change of step state, which is used to animate images changes
   */
  useEffect(() => {
    // On every phase we should start from first step
    setCurrentPhase(0);

    if (stepImages[currentPhase].length > 1) {
      const interval = setInterval(() => {
        setCurrentPhase((prevPhase) => (prevPhase + 1) % stepImages[currentPhase].length);
      }, 500);

      return () => clearInterval(interval);
    }
  }, [currentPhase]);

  /**
   * Returns the appropriate loader text key based on the phase value and available data.
   * @param phaseValue - The current phase value.
   * @returns  The key for the appropriate loader text.
   */
  function getLoaderTextBasedOnValues(phaseValue: Phases) {
    if (phaseValue !== Phases.Intent && phaseValue !== Phases.SearchCompleted)
      return `animatedIntentLoader.phase${phaseValue}.dataProcessedText`;

    if (vacanciesCount && companiesOpened) return `animatedIntentLoader.phase${phaseValue}.dataProcessedText.withBoth`;

    if (vacanciesCount) return `animatedIntentLoader.phase${phaseValue}.dataProcessedText.withVacancies`;

    if (companiesOpened) return `animatedIntentLoader.phase${phaseValue}.dataProcessedText.withCompaniesOpened`;

    return `animatedIntentLoader.phase${phaseValue}.dataProcessedText.default`;
  }

  return (
    <div
      style={{ boxShadow: 'var(--glovo-loader-box-shadow)' }}
      className="bg-white rounded-lg p-5 mt-[30px] border border-solid border-[var(--input-border)]"
    >
      <div className={classNames(styles.loaderWrapper)}>
        <div className={classNames(styles.imageWrapper, 'flex justify-center mr-4')}>
          {CurrentImage && <CurrentImage />}
        </div>
        <div
          className={classNames(
            styles.textWrapper,
            'flex flex-col justify-center text-center lg:text-left flex-grow flex-1'
          )}
        >
          <div className="text-[var(--dark)] font-semibold leading-normal text-[24px]">
            {tProspects(`animatedIntentLoader.phase${currentPhase}.title`)}
          </div>
          {currentPhase !== Phases.Finish && (
            <div className="text-[var(--dark)] leading-normal text-[16px] mt-[10px]">
              {tProspects(`animatedIntentLoader.phase${currentPhase}.subtitle`)}
            </div>
          )}
          {/* On screens smaller than 1024px, SearchFunnelProgress will appear here */}
          <div className="lg:hidden flex-1">
            <SearchFunnelProgress
              isDeepSearchEnabled={isDeepSearchEnabled}
              selectedSearchSections={selectedSearchSections}
              companiesWithLeads={companiesWithLeads}
              companiesOpened={companiesOpened}
              funnelData={funnelData}
              isLoading={isLoading}
            />
          </div>
          {[Phases.Intent, Phases.SearchCompleted].includes(currentPhase) && (
            <div className="text-[var(--placeholder-secondary)] leading-normal text-[16px] mt-5 whitespace-pre-line">
              {tProspects(getLoaderTextBasedOnValues(currentPhase), {
                vacanciesCount,
                companiesOpened,
                leadsCount
              })}
            </div>
          )}
        </div>
        {/* On screens larger than 1024px, SearchFunnelProgress will appear in its original position */}
        <div className="hidden lg:block lg:order-last flex-1">
          <SearchFunnelProgress
            isDeepSearchEnabled={isDeepSearchEnabled}
            selectedSearchSections={selectedSearchSections}
            companiesWithLeads={companiesWithLeads}
            companiesOpened={companiesOpened}
            funnelData={funnelData}
            isLoading={isLoading}
          />
        </div>
      </div>
      <StepsLoader totalSteps={totalSteps} currentStep={currentPhase} isLoading={isLoading} />
      <div className="flex justify-center sm:justify-end mt-5">
        {isLoading && (
          <Button
            onClick={cancelStream}
            className="text-white bg-[var(--action)] hover:!text-[var(--action)] hover:!bg-white border-[var(--action)] !rounded-[5px] !text-base !px-[30px]"
            data-testid="cancel-search"
          >
            {tProspects('cancelSearch')}
          </Button>
        )}
      </div>
    </div>
  );
}
