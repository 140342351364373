import cn from 'classnames';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AiProspectingSearchMethods } from '../../../types/aiProspecting';
import { calculatePercentage } from '../../../services/calculatePercentage';

interface DynamicDeepIntentProgressModalProps {
  selectedSearchSections: AiProspectingSearchMethods[];
  totalCount: number;
  funnelData?: Record<AiProspectingSearchMethods, number>;
}

interface FunnelHintSection {
  name: string;
  section: AiProspectingSearchMethods;
  percentage: number;
  amount?: number;
  background: string;
}

export default function DynamicIntentHint({
  selectedSearchSections,
  totalCount,
  funnelData
}: DynamicDeepIntentProgressModalProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const blocks: FunnelHintSection[] = [
    {
      name: t('hiringIntent.blockName'),
      section: AiProspectingSearchMethods.Hiring,
      percentage: calculatePercentage(funnelData?.hiring, totalCount),
      amount: funnelData?.hiring,
      background: 'bg-[var(--funnel-pink)]'
    },
    {
      name: t('webSearch.blockName'),
      section: AiProspectingSearchMethods.Web,
      percentage: calculatePercentage(funnelData?.web, totalCount),
      amount: funnelData?.web,
      background: 'bg-[var(--funnel-peach)]'
    },
    {
      name: t('newsSearch.blockName'),
      section: AiProspectingSearchMethods.News,
      percentage: calculatePercentage(funnelData?.news, totalCount),
      amount: funnelData?.news,
      background: 'bg-[var(--funnel-purple)]'
    },
    {
      name: t('technologySearch.blockName'),
      section: AiProspectingSearchMethods.Technology,
      percentage: calculatePercentage(funnelData?.technology, totalCount),
      amount: funnelData?.technology,
      background: 'bg-[var(--funnel-blue)]'
    }
  ];
  // Filter and order funnel blocks based on selected search sections
  const filteredBlocks = useMemo(
    () =>
      selectedSearchSections
        .map((section) => blocks.find((block) => block.section === section))
        .filter(Boolean) as FunnelHintSection[],
    [selectedSearchSections, blocks]
  );

  return (
    <Tooltip
      color="var(--white)"
      overlayInnerStyle={{ minWidth: '250px' }}
      title={
        <div className="flex justify-center items-center">
          <div className="flex flex-col gap-[10px] w-full bg-[var(--light-secondary-bg)] border border-solid border-[var(--input-border)] rounded text-[var(--dark)] p-1.5 m-[5px 10px 10px]">
            {filteredBlocks.map(({ percentage, section, name, amount, background }) => (
              <div className="flex">
                <div
                  className="flex items-center text-[var(--placeholder)] font-bold w-1/5"
                  data-testid={`${section}-percentage`}
                >
                  {percentage}%
                </div>
                <div className="relative flex justify-center w-4/5">
                  <div
                    className={cn('h-[45px] absolute top-0 rounded min-w-[5px]', background)}
                    style={{ width: `${percentage}%`, transition: 'width 0.5s linear' }}
                  />
                  <div className="relative z-10">
                    <div data-testid={`${section}-name`}>{name}</div>
                    <div className="font-bold" data-testid={`${section}-amount`}>
                      {amount || 0}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      }
    >
      <InfoCircleOutlined className="cursor-pointer text-[var(--button-primary)]" />
    </Tooltip>
  );
}
