import classNames from 'classnames';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';

import { WebSearchPeriod } from '../../../types/aiProspecting';
import { transformEnumToOptions } from '../../../services/transformOptionHelpers';

import { InputTags } from '../components/InputTags';
import MultiSelect from '../components/MultiSelect';
import Hint from '../components/Hint';
import { SectionsProps } from './ProspectsWrapper';

import { ReactComponent as WebSearchSectionIcon } from '../images/webSearchIcon.svg';

export default function WebSearchSection({ handleChange, isSelected, disabled }: SectionsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });

  return (
    <div className="h-full flex flex-col" data-testid="web-search-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2 ">
        <WebSearchSectionIcon />
        <span>{t('webSearch.blockName')}</span>
      </div>
      <div
        className={classNames('h-full bg-white rounded-[5px] p-[20px]   border border-solid flex flex-col gap-5', {
          'border-[1.5px] border-[var(--button-primary)]': isSelected,
          'hover:border-[var(--tertiary)] hover:shadow-secondary': !isSelected && !disabled
        })}
      >
        <Form.Item
          rules={[{ required: isSelected, message: t('queryRequiredInfo') }]}
          name="webQuery"
          label={
            <div className="flex items-center gap-1">
              <span style={{ fontWeight: 'bold' }}>{t('webSearch.searchQuery')}</span>
              <Hint content={t('inputTagsTooltip')}>
                <InfoCircleOutlined className=" cursor-pointer text-[var(--button-primary)]" />
              </Hint>
            </div>
          }
          className="!mb-0"
        >
          <InputTags disabled={!!disabled} callback={(value) => handleChange('webQuery', value)} />
        </Form.Item>
        <Form.Item
          name="webPeriod"
          label={<span className="font-bold">{t('webSearch.latestPeriod')}</span>}
          className="!mb-0"
        >
          <MultiSelect
            disabled={disabled}
            id="webPeriod"
            placeholder={t('webSearch.periodPlaceholder')}
            options={transformEnumToOptions({
              value: WebSearchPeriod,
              key: 'webPeriod',
              baseLabel: 'webSearch',
              t
            })}
            setSelected={(value) => handleChange('webPeriod', value)}
          />
        </Form.Item>
      </div>
    </div>
  );
}
