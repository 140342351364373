import cn from 'classnames';

import styles from './stepsLoader.module.css';

interface StepsLoaderProps {
  totalSteps: number;
  currentStep: number;
  isLoading: boolean;
}

export default function StepsLoader({ totalSteps, currentStep, isLoading }: StepsLoaderProps) {
  /**
   * Get proper class for per step
   * @param index
   * @returns
   */
  function getStepStyling(index: number) {
    let stepClass;

    if (index < currentStep || !isLoading) {
      stepClass = styles.finishedStep;
    } else if (index === currentStep) {
      stepClass = styles.animatedGradient;
    } else {
      stepClass = styles.unfinishedStep;
    }

    return stepClass;
  }

  return (
    <div className="flex justify-between gap-[10px] mt-5">
      {Array.from({ length: totalSteps }, (_, index) => (
        <div data-testid="steps-loader-el" key={index} className={cn(styles.basicStyle, getStepStyling(index))} />
      ))}
    </div>
  );
}
