// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stepsLoader_basicStyle__teUfL {
  width: 100%;
  height: 6px;
  border-radius: 6px;
}

.stepsLoader_finishedStep__pcPd7 {
  background-color: var(--action);
}

.stepsLoader_unfinishedStep__NEMdC {
  background-color: var(--input-border);
}

.stepsLoader_animatedGradient__Ey9u6 {
  background: repeating-linear-gradient(to right, var(--input-border) 0%, var(--action) 100%);
  background-size: 200% auto;
  background-position: 0 100%;
  animation: stepsLoader_gradient__izMjm 3s infinite linear forwards;
}

@keyframes stepsLoader_gradient__izMjm {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: -200% 0;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AiProspecting/components/stepsLoader.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,qCAAqC;AACvC;;AAEA;EACE,2FAA2F;EAC3F,0BAA0B;EAC1B,2BAA2B;EAC3B,kEAA+C;AACjD;;AAEA;EACE;IACE,wBAAwB;EAC1B;EACA;IACE,4BAA4B;EAC9B;AACF","sourcesContent":[".basicStyle {\n  width: 100%;\n  height: 6px;\n  border-radius: 6px;\n}\n\n.finishedStep {\n  background-color: var(--action);\n}\n\n.unfinishedStep {\n  background-color: var(--input-border);\n}\n\n.animatedGradient {\n  background: repeating-linear-gradient(to right, var(--input-border) 0%, var(--action) 100%);\n  background-size: 200% auto;\n  background-position: 0 100%;\n  animation: gradient 3s infinite linear forwards;\n}\n\n@keyframes gradient {\n  0% {\n    background-position: 0 0;\n  }\n  100% {\n    background-position: -200% 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"basicStyle": `stepsLoader_basicStyle__teUfL`,
	"finishedStep": `stepsLoader_finishedStep__pcPd7`,
	"unfinishedStep": `stepsLoader_unfinishedStep__NEMdC`,
	"animatedGradient": `stepsLoader_animatedGradient__Ey9u6`,
	"gradient": `stepsLoader_gradient__izMjm`
};
export default ___CSS_LOADER_EXPORT___;
