import { IntegrationProvider } from '../types';

export enum AuthMode {
  Login = 'login',
  ConsentOnly = 'consentOnly'
}

export const isProd = document.location.host === 'app.aisdr.com';
export const isNamedEnv = document.location.host.match(/app-[a-z]*\.aisdr\.com/gi);
export const generateStreamEndpoint = 'campaigns/generate-stream';
export const generateVideoStreamEndpoint = 'campaigns/generate-video-script-stream';

/**
 * Get environment prefix
 */
export function getEnvPrefix() {
  return document.location.host.split('.')[0].replace(':3000', '');
}

export function getApiUrl() {
  const productionApi = 'https://api.aisdr.com';
  const testApi = 'http://localhost:4000';

  if (isProd) return productionApi;

  if (isNamedEnv?.length) return `${document.location.origin.replace('app', 'api')}`;

  return process.env.REACT_APP_API_URL || testApi;
}

export function getWebPageUrl() {
  const productionUrl = 'https://aisdr.com';
  const testUrl = 'http://localhost:3000';

  if (isProd) return productionUrl;

  if (isNamedEnv?.length) return document.location.origin;

  return testUrl;
}

/**
 * Generates the redirect URL for the email provider with the appropriate authentication mode and optional email hint.
 * @param provider - The email provider to integrate with.
 * @param authMode - The mode of authentication (e.g., login or consent only).
 * @param hintEmail - (Optional) The email hint to pre-fill in the authentication form.
 * @returns The fully constructed URL to redirect the user to the appropriate authentication flow.
 */
export function getEmailProviderRedirectUrl(provider: IntegrationProvider, authMode: AuthMode, hintEmail?: string) {
  const authModeParam = `?authMode=${authMode}`;
  const emailParam = hintEmail ? `&email=${encodeURIComponent(hintEmail)}` : '';

  return `${getApiUrl()}/users/${provider}${authModeParam}${emailParam}`;
}
