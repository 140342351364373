import {
  HubspotConfig,
  HubspotContactProperty
} from '../../components/Settings/components/Integrations/HubSpotConfigurationModal';
import { SuppressionList } from '../../components/SuppressionList/HubSpotSuppressedTab';
import { LinkedinCookie } from '../../components/Settings/components/Integrations/Integrations';
import { getItemFromLocalStorage, setItemToLocalStorage } from '../../services/localStorageHelpers';
import { User } from '../../services/user';
import type {
  ContactList,
  IntegrationProvider,
  IntegrationToken,
  OnboardingSpotlights,
  UpdateUserInterface,
  UserInterface
} from '../../types';
import { LocalStorageAccountsData } from '../../types';
import { ProviderTags } from '../constants';
import { DefaultPaginationParams } from '../types';
import { apiSlice } from './apiSlice';

interface GetAllUsersResponse {
  data: User[];
  totalCount: number;
}

export interface InviteUserResponse {
  data: {
    status: number;
    message: string;
  };
}

interface GetAllUsersRequest {
  params?: DefaultPaginationParams & { search?: string } & { status?: string };
}

interface LinkNewMailboxRequest {
  mailbox: string;
  provider: IntegrationProvider;
  ownerId?: string;
}

interface CreateRecordRequest {
  cookies: LinkedinCookie[];
}

export interface CsrfResponse {
  token: string;
}

export const usersApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMe: builder.query<User, null>({
      query: () => 'users/me',
      transformResponse: (response: UserInterface) => {
        if (response) {
          // Set actual account data to localstorage
          const accounts: LocalStorageAccountsData = getItemFromLocalStorage('accounts') || {};

          accounts[response.email] = {
            fullName: `${response.firstName} ${response.lastName}`,
            email: response.email
          };

          setItemToLocalStorage('accounts', accounts);
        }

        // Format user data
        return new User(response);
      },
      providesTags: [{ type: ProviderTags.Me }]
    }),
    getCsrf: builder.query<CsrfResponse, null>({
      query: () => 'csrf',
      providesTags: [{ type: ProviderTags.Csrf }]
    }),
    editMe: builder.mutation<User, Pick<User, 'firstName' | 'lastName' | 'userPreferences'>>({
      query: (body) => ({
        url: `users/me`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.Campaigns }]
    }),
    getUsers: builder.query<GetAllUsersResponse, GetAllUsersRequest>({
      query: ({ params }) => ({
        url: 'users',
        params
      }),
      providesTags: (result) => result?.data?.map(({ id }) => ({ type: ProviderTags.Users, id })) ?? []
    }),
    getUser: builder.query<UserInterface, Pick<UserInterface, 'id'>>({
      query: ({ id }) => `users/${id}`,
      providesTags: (result, error, { id }) => [{ type: ProviderTags.Users, id }, { type: ProviderTags.Me }]
    }),
    createUser: builder.mutation<UserInterface, Partial<UserInterface>>({
      query: (body) => ({
        url: 'users',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.Users]
    }),
    editUser: builder.mutation<User, Partial<UpdateUserInterface>>({
      query: (body) => ({
        url: `users/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Users, id }]
    }),
    markSpotlightSuggestionAsCompleted: builder.mutation<User, { key: keyof OnboardingSpotlights }>({
      query: ({ key }) => ({
        url: `users/me/spotlights/${key}`,
        method: 'PATCH'
      }),
      invalidatesTags: [{ type: ProviderTags.Me }]
    }),
    sendReconnectMail: builder.mutation<void, Pick<UserInterface, 'id'> & { integration: string }>({
      query: (body) => ({
        url: `users/${body.id}/send-reconnect-mail`,
        method: 'POST',
        body: {
          integration: body.integration
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Users, id }]
    }),
    addNewUserMailboxRecord: builder.mutation<void, LinkNewMailboxRequest>({
      query: (body) => ({
        url: `users/mailbox/add`,
        method: 'POST',
        body: {
          mailbox: body.mailbox,
          provider: body.provider,
          ownerId: Number(body.ownerId)
        },
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: [{ type: ProviderTags.Me }]
    }),
    updateUserMailboxRecord: builder.mutation<void, { id: number; mailboxFullName: string; userId: number }>({
      query: ({ id, ...body }) => ({
        url: `users/mailbox/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: ProviderTags.Users, id },
        { type: ProviderTags.Users },
        { type: ProviderTags.Me }
      ]
    }),
    disconnectMailbox: builder.mutation<void, Pick<IntegrationToken, 'id'> & { userId: number }>({
      query: ({ id, ...body }) => ({
        url: `users/mailbox/disconnect/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.CampaignGeneralDetails }]
    }),
    updatetMailboxTokenCreator: builder.mutation<void, Pick<IntegrationToken, 'id'> & { userId: number }>({
      query: ({ id, ...body }) => ({
        url: `users/mailbox/disconnect/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.CampaignGeneralDetails }]
    }),
    integrateHubspot: builder.mutation<void, HubspotConfig>({
      query: (body) => ({
        url: 'users/me/integrations/hubspot',
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.SuppressionList }]
    }),
    getContactLists: builder.query<{ lists: ContactList[]; suppressionLists: SuppressionList[] } | null, null>({
      query: () => ({
        url: 'users/me/hubspot/lists'
      }),
      providesTags: [{ type: ProviderTags.SuppressionList }]
    }),
    syncListContacts: builder.mutation<void, { listId: number; listName: string; campaignId?: number }>({
      query: (body) => ({
        url: 'users/me/hubspot/lists/sync',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.SuppressionList }]
    }),
    deactivateUser: builder.mutation<void, Pick<UserInterface, 'id'>>({
      query: ({ id }) => ({
        url: `users/deactivate/${id}`,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Users, id }]
    }),
    activateUser: builder.mutation<void, Pick<UserInterface, 'id'>>({
      query: ({ id }) => ({
        url: `users/activate/${id}`,
        method: 'PATCH'
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Users, id }]
    }),
    deleteIntegration: builder.mutation<void, Pick<IntegrationToken, 'id'>>({
      query: ({ id }) => ({
        url: `users/me/integration/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.Users }]
    }),
    updateDailyLimit: builder.mutation<
      void,
      { id?: number; user?: { roles?: string[]; id?: number } } & Pick<IntegrationToken, 'dailySendingLimit'>
    >({
      query: ({ id, ...body }) => ({
        url: `users/mailbox/update-daily-limit/${id}`,
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      }),
      invalidatesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.Users }]
    }),
    inviteTeamMember: builder.mutation<
      InviteUserResponse['data'],
      { isCampaignAdmin: boolean } & Pick<User, 'email' | 'firstName' | 'lastName'>
    >({
      query: (body) => ({
        url: 'users/invite-team-member',
        method: 'POST',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      })
    }),
    getContactProperties: builder.query<HubspotContactProperty[], null>({
      query: () => 'hubspot/contact-properties',
      providesTags: [{ type: ProviderTags.Me }, { type: ProviderTags.SuppressionList }]
    }),
    getSelectedHubSpotProperties: builder.query<HubspotConfig, null>({
      query: () => 'users/me/hubspot/selected-properties'
    }),
    createCloudCruiseRecord: builder.mutation<null, CreateRecordRequest>({
      query: (body) => ({
        url: '/cloud-cruise',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body,
        credentials: 'include'
      }),
      invalidatesTags: [{ type: ProviderTags.Me }]
    }),
    getTenRecentContacts: builder.query<void, { listId: number | null }>({
      query: ({ listId }) => ({
        url: `hubspot/recent-contacts/${listId}`
      }),
      providesTags: [ProviderTags.Me]
    })
  })
});

export const {
  useGetMeQuery,
  useGetCsrfQuery,
  useEditMeMutation,
  useGetUsersQuery,
  useGetUserQuery,
  useMarkSpotlightSuggestionAsCompletedMutation,
  useGetContactListsQuery,
  useIntegrateHubspotMutation,
  useSyncListContactsMutation,
  useSendReconnectMailMutation,
  useAddNewUserMailboxRecordMutation,
  useUpdateUserMailboxRecordMutation,
  useDisconnectMailboxMutation,
  useUpdatetMailboxTokenCreatorMutation,
  useEditUserMutation,
  useCreateUserMutation,
  useActivateUserMutation,
  useDeactivateUserMutation,
  useDeleteIntegrationMutation,
  useUpdateDailyLimitMutation,
  useInviteTeamMemberMutation,
  useGetContactPropertiesQuery,
  useGetSelectedHubSpotPropertiesQuery,
  useCreateCloudCruiseRecordMutation,
  useGetTenRecentContactsQuery
} = usersApi;
