export enum MinPostedPeriod {
  '7 days' = '7',
  '1 month' = '30',
  '3 months' = '90'
}

export enum CompanyFunding {
  Seed = 'Seed',
  PreSeed = 'Pre seed',
  Grant = 'Grant',
  NonEquityAssistance = 'Non equity assistance',
  PrivateEquity = 'Private equity',
  SeriesUnknown = 'Series unknown',
  PostIpoEquity = 'Post IPO equity',
  ConvertibleNote = 'Convertible note',
  SecondaryMarket = 'Secondary market',
  Undisclosed = 'Undisclosed',
  DebtFinancing = 'Debt financing',
  CorporateRound = 'Corporate round',
  PostIpoDebt = 'Post IPO debt',
  PostIpoSecondary = 'Post IPO secondary',
  InitialCoinOffering = 'Initial coin offering',
  ProductCrowdFunding = 'Product crowdfunding',
  EquityCrowdFunding = 'Equity crowdfunding',
  Angel = 'Angel',
  SeriesA = 'Series A',
  SeriesB = 'Series B',
  SeriesC = 'Series C',
  SeriesD = 'Series D',
  SeriesE = 'Series E',
  SeriesF = 'Series F',
  SeriesG = 'Series G',
  SeriesH = 'Series H',
  SeriesI = 'Series I',
  SeriesJ = 'Series J'
}

export enum CoresSignalCompanySize {
  '1-10' = '1-10',
  '11-50' = '11-50',
  '51-200' = '51-200',
  '201-500' = '201-500',
  '501-1000' = '501-1000',
  '1001-5000' = '1001-5000',
  '5001-10000' = '5001-10000',
  '10,001+' = '10,001+'
}

export enum OceanIoCompanySize {
  '0-1' = '0-1',
  '2-10' = '2-10',
  '11-50' = '11-50',
  '51-200' = '51-200',
  '201-500' = '201-500',
  '501-1000' = '501-1000',
  '1001-5000' = '1001-5000',
  '5001-10000' = '5001-10000',
  '10001-50000' = '10001-50000',
  '50001-100000' = '50001-100000',
  '100001-500000' = '100001-500000',
  '500000+' = '500000+'
}

export enum CompanyProviders {
  CoreSignal = 'CoreSignal',
  OceanIo = 'OceanIo'
}

export enum AiProspectingSearchMethods {
  Hiring = 'hiring',
  Web = 'web',
  News = 'news',
  Technology = 'technology'
}

export enum NewsProviders {
  NewsAi = 'NewsAi',
  NewsApi = 'NewsApi'
}

export enum WebSearchPeriod {
  '1 day' = '1 day',
  '3 days' = '3 days',
  '1 week' = '1 week',
  '2 weeks' = '2 weeks',
  '20 days' = '20 days',
  '1 month' = '1 month',
  '2 month' = '2 month',
  '3 month' = '3 month'
}

export enum AiProspectingFilterUrl {
  name = 'name',
  industry = 'industry',
  location = 'location',
  size = 'size',
  fundingLastRoundType = 'fundingLastRoundType',
  fundingLastRoundDate = 'fundingLastRoundDate',
  categoriesAndKeywords = 'categoriesAndKeywords',
  monthlyPercentageEmployeeGrowth = 'monthlyPercentageEmployeeGrowth',
  searchMethods = 'searchMethods',
  webQuery = 'webQuery',
  webPeriod = 'webPeriod',
  newsProvider = 'newsProvider',
  newsQuery = 'newsQuery',
  newsDate = 'newsDate',
  newsResources = 'newsResources',
  hiringIntentJobTitle = 'hiringIntentJobTitle',
  jobPostContains = 'jobPostContains',
  minPostedDate = 'minPostedDate',
  leadJobTitle = 'leadJobTitle',
  leadProvider = 'leadProvider',
  department = 'department',
  experience = 'experience',
  managementLevel = 'managementLevel',
  technologyQuery = 'technologyQuery',
  isDeepSearchEnabled = 'isDeepSearchEnabled',
  searchCompaniesWithUrl = 'searchCompaniesWithUrl',
  domain = 'domain',
  companyProvider = 'companyProvider'
}
