export enum RolesEnum {
  Internal = 'Internal',
  CampaignAdmin = 'Campaign Admin',
  RoleAdmin = 'Role Admin',
  FeatureAdmin = 'Feature Admin'
}

export interface Role {
  id: number;
  name: RolesEnum;
  description: string;
  createdAt: Date;
  updatedAt: Date;
}
