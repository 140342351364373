import { Campaign, Event } from '.';

export enum LeadSource {
  hubspot = 'hubspot',
  csv = 'csv',
  aisdrB = 'aisdrB'
}

export enum CampaignLeadState {
  new = 'new',
  preparing = 'preparing',
  awaitingReply = 'awaitingReply',
  booked = 'booked',
  paused = 'paused',
  positiveResponse = 'positiveResponse',
  closed = 'closed'
}

export enum CampaignLeadStatus {
  active = 'active',
  bounced = 'bounced'
}

export interface Lead {
  id: number;

  firstName: string;
  lastName: string;
  email: string;
  phone: string;

  linkedin: string;

  companyName: string;
  companyWebsite: string;

  location?: string;
  jobTitle?: string;

  campaign: Campaign;
  campaigns: Partial<Campaign>[];
  industry: string;
  events: Event[];

  source: LeadSource;

  state?: CampaignLeadState;
  status?: CampaignLeadStatus;
  personalizationInfo?: string;

  createdAt: Date;
  updatedAt: Date;

  /* Additional fields */
  campaignsCount?: number;
  activeCampaignsCount?: number;
}

export type LeadStatusSelections = CampaignLeadState | 'all';
