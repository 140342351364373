import { Company } from './company';

export enum Features {
  Dashboard = 'dashboard',
  YCLaunch = 'yc_launch',
  WorkingHours = 'working_hours',
  SanFranciscoTimezone = 'san_francisco_timezone',
  PersonaSignature = 'persona_signature',
  CsvValidationWithGpt = 'csv_filtration_using_openai',
  ChristmasMood = 'christmas_mood',
  LeadDiscovery = 'lead_discovery',
  ActiveCampaignOnCreation = 'activate_campaign_on_creation',
  EscalationRecognition = 'escalation_recognition_classification',
  NativeHubspot = 'native_hubspot',
  EmailWarmUp = 'email_warmup',
  UnsubscribeOption = 'unsubscribe_option',
  Onboarding = 'onboarding',
  SMS = 'sms',
  LinkedinPersonalization = 'linkedin_personalization',
  MicrosoftOutlook = 'microsoft_outlook',
  HubSpotMeetings = 'hubspot_meetings',
  SuppressionLists = 'suppression_lists',
  HubspotPersonalization = 'hubspot_personalization',
  ApolloIntegration = 'apollo_integration',
  VacancyPersonalization = 'vacancy_personalization',
  StopAutoReply = 'stop_auto_reply',
  VideoInEmails = 'videos_in_email',
  WebNewsIntentSearch = 'web_news_intent_search',
  memesInEmails = 'memes_in_email',
  SwarmIntegration = 'swarm_integration',
  OceanIoIntegration = 'ocean_io_integration',
  ZoomInfoIntegration = 'zoom_info_integration',
  LinkedinAutomation = 'linkedin_automation'
}

export interface FeatureMatrix {
  [key: string]: boolean;
}

export interface Feature {
  id: number;
  name: string;
  description: string;
  isDefaultEnabled: boolean;
  companies: Company[];

  createdAt: Date;
  updatedAt: Date;
}
