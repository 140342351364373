import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enBuilder from './locales/en/builder.json';
import enCampaigns from './locales/en/campaigns.json';
import enCommon from './locales/en/common.json';
import enCompanies from './locales/en/companies.json';
import enDashboard from './locales/en/dashboard.json';
import enFeatures from './locales/en/features.json';
import enInviteTeamMember from './locales/en/inviteTeamMember.json';
import enLeads from './locales/en/leads.json';
import enLogin from './locales/en/login.json';
import enOnboarding from './locales/en/onboarding.json';
import enPersonaQuestionnaire from './locales/en/personaQuestionnaire.json';
import enPersonas from './locales/en/personas.json';
import enPlannedSendTime from './locales/en/plannedSendTime.json';
import enRedeem from './locales/en/redeem.json';
import enSettings from './locales/en/settings.json';
import enMailboxes from './locales/en/mailboxes.json';
import enSpotlights from './locales/en/spotlights.json';
import enSqs from './locales/en/sqs.json';
import enSubjectLine from './locales/en/subjectLine.json';
import enTimezones from './locales/en/timezones.json';
import enUsers from './locales/en/users.json';
import enSuppressionList from './locales/en/suppressionList.json';
import enProspecting from './locales/en/prospecting.json';
import enVideoHosting from './locales/en/videoHosting.json';
import enRemoteMachine from './locales/en/remoteMachine.json';

const resources = {
  en: {
    translation: {
      builder: enBuilder,
      login: enLogin,
      common: enCommon,
      settings: enSettings,
      campaigns: enCampaigns,
      leads: enLeads,
      personas: enPersonas,
      users: enUsers,
      dashboard: enDashboard,
      features: enFeatures,
      sqs: enSqs,
      timezones: enTimezones,
      plannedSendTime: enPlannedSendTime,
      subjectLine: enSubjectLine,
      mailboxes: enMailboxes,
      companies: enCompanies,
      redeem: enRedeem,
      onboarding: enOnboarding,
      spotlights: enSpotlights,
      inviteTeamMember: enInviteTeamMember,
      personaQuestionnaire: enPersonaQuestionnaire,
      suppressionList: enSuppressionList,
      prospecting: enProspecting,
      videoHosting: enVideoHosting,
      remoteMachine: enRemoteMachine
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en',

  interpolation: {
    escapeValue: false
  },
  react: { useSuspense: false }
});

export default i18n;
