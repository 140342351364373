import { apiSlice } from './apiSlice';
import { RemoteMachineInstance } from '../../components/RemoteMachines/RemoteMachines';

export const ec2Api = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchInstances: builder.query<RemoteMachineInstance[], void>({
      query: () => ({
        url: '/ec2/instances',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
    }),
    rebootInstance: builder.mutation<void, { instanceId: string }>({
      query: ({ instanceId }) => ({
        url: `/ec2/reboot/${instanceId}`,
        method: 'POST'
      })
    })
  })
});

export const { useFetchInstancesQuery, useRebootInstanceMutation } = ec2Api;
