import { Button, Form, Input, Modal, Switch } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '../../Spinner/Spinner';

interface FormValues {
  name: string;
  description: string;
  isDefaultEnabled: boolean;
}

interface CreateFeatureModalProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: FormValues) => Promise<void> | void;
}

export default function CreateFeatureModal(props: CreateFeatureModalProps) {
  const { opened, onSubmit, onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm<FormValues>();
  const { t: tFeatures } = useTranslation('translation', { keyPrefix: 'features' });

  /**
   * Show formatted validation error
   * @param name
   * @returns
   */
  const requiredValidation = (name: string) => [
    { required: true, message: tFeatures('validationError', { value: name.toLowerCase() }) }
  ];

  /**
   * Create feature wrapper function
   * @param values
   */
  async function handleSubmit(values: FormValues) {
    setIsLoading(true);

    await onSubmit(values);

    setIsLoading(false);

    form.resetFields();
  }

  return (
    <Modal open={opened} title={tFeatures('create.title')} footer={[]} onCancel={onClose}>
      <Form
        aria-label="create-persona"
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        validateTrigger="onSubmit"
        requiredMark={false}
        initialValues={{
          name: '',
          description: '',
          isDefaultEnabled: false
        }}
      >
        <Form.Item name="name" label={tFeatures('create.name')} rules={requiredValidation(tFeatures('create.name'))}>
          <Input placeholder={tFeatures('placeholders.name')} />
        </Form.Item>

        <Form.Item
          name="description"
          label={tFeatures('create.description')}
          rules={requiredValidation(tFeatures('create.description'))}
        >
          <TextArea autoSize className="!h-28" placeholder={tFeatures('placeholders.description')} />
        </Form.Item>

        <Form.Item name="isDefaultEnabled" valuePropName="checked" label={tFeatures('create.isDefaultEnabled')}>
          <Switch
            checkedChildren={tFeatures('table.enabled')}
            unCheckedChildren={tFeatures('table.disabled')}
            className="bg-gray-400"
          />
        </Form.Item>

        <div className="flex gap-x-2 justify-end">
          <Button key="back" onClick={onClose}>
            {tFeatures('create.cancel')}
          </Button>
          <Button htmlType="submit" disabled={isLoading}>
            {isLoading ? (
              <div className="flex gap-x-2 items-center">
                <Spinner />
                {tFeatures('create.createFeatureLoading')}
              </div>
            ) : (
              tFeatures('create.createFeature')
            )}
          </Button>
        </div>
      </Form>
    </Modal>
  );
}
