import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, message, Select, Spin } from 'antd';

import { useFetchInstancesQuery, useRebootInstanceMutation } from '../../redux-store';

const { Option } = Select;

export interface RemoteMachineInstance {
  instanceId: string;
  instanceName: string;
}

export default function RemoteMachines() {
  const { t } = useTranslation('translation', { keyPrefix: 'remoteMachine' });
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [rebootInstance, { isLoading: isRebooting }] = useRebootInstanceMutation();
  const { data: instances, isLoading: isFetching } = useFetchInstancesQuery();

  /**
   * Handle reboot remote machine
   */
  async function handleReboot() {
    if (!selectedInstance) return message.error('Please select an instance to reboot.');

    try {
      const selectedInstanceObj = instances?.find(
        (instance: RemoteMachineInstance) => instance.instanceName === selectedInstance
      );
      const selectedInstanceId = selectedInstanceObj?.instanceId;

      if (!selectedInstanceId) return await message.error('Failed to find the selected instance ID.');

      await rebootInstance({ instanceId: selectedInstanceId }).unwrap();
      message.success(`Instance ${selectedInstance} rebooted successfully!`);
    } catch (error) {
      message.error('Failed to reboot instance.');
    }
  }

  return (
    <div>
      <div className="py-6 w-[400px]">
        <h1 className="text-2xl font-bold mb-4">{t('remoteMachineAdminCenter')}</h1>

        <section className="mt-5 p-5 bg-white border border-[var(--input-border)] rounded-[10px]">
          <h3 className="mb-2.5">{t('rebootMachine')}</h3>
          {isFetching ? (
            <Spin tip={t('loadingInstances')} />
          ) : (
            <Select
              className="w-full"
              placeholder={t('selectInstance')}
              onChange={(value) => setSelectedInstance(value)}
              value={selectedInstance}
            >
              {instances &&
                instances.map((remoteMachineInstance: RemoteMachineInstance) => (
                  <Option key={remoteMachineInstance.instanceId} value={remoteMachineInstance.instanceName}>
                    {remoteMachineInstance.instanceName} ({remoteMachineInstance.instanceId})
                  </Option>
                ))}
            </Select>
          )}
          <Button
            className="w-full items-center h-[40px] justify-center mt-4 gap-2.5 !px-[30px] py-2 text-white bg-[var(--action)] border-[var(--action)] hover:bg-transparent hover:!text-[var(--action)] !text-base !rounded-[5px]"
            onClick={handleReboot}
            loading={isRebooting}
            disabled={!selectedInstance}
          >
            {t('rebootMachine')}
          </Button>
        </section>
      </div>
    </div>
  );
}
