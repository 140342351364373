import { TFunction } from 'i18next';

import { LeadProviderSuggestionsResponse } from '../redux-store';
import { LeadProvider } from '../types';

/**
 * Transform suggestions, which came from BE, to select options
 * @param leadProvider
 * @param suggestions
 * @returns
 */
export function transformSuggestionsToOptions(
  suggestions?: LeadProviderSuggestionsResponse,
  leadProvider?: LeadProvider
) {
  if (leadProvider === LeadProvider.Apollo) {
    return (
      suggestions?.map((item) => {
        // The response can be object, so check if it's not object to return right option
        if (typeof item === 'string') return { value: item, label: item };

        if (leadProvider === LeadProvider.Apollo) return { value: item.cleaned_name, label: item.cleaned_name };

        return { value: item.value, label: item.value };
      }) || []
    );
  }

  return (
    suggestions?.map((item) => {
      // The response can be object, so check if it's not object to return right option
      if (typeof item === 'string') return { value: item, label: item };

      return { value: item.value, label: item.value };
    }) || []
  );
}

/**
 * Transform enum values to select options
 * @param params
 * @returns
 */
export function transformEnumToOptions<T extends object>({
  value,
  key,
  baseLabel,
  t
}: {
  value: T;
  key: string;
  baseLabel: string;
  t: TFunction;
}) {
  return Object.values(value).map((item) => ({
    value: item,
    label: t(`${baseLabel}.${key}.${item}`)
  }));
}
