/**
 * Check if at least one form value is entered (otherwise we should disable submit button)
 * @param values
 * @returns
 */
export function isFormOneValueFilled(values = {}): boolean {
  return Object.values(values).some((value: unknown) => {
    if (Array.isArray(value)) {
      return value.length;
    }

    if (typeof value === 'string') {
      return value.trim() !== '';
    }

    return true;
  });
}
