import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { Input, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseCircleFilled, CloseOutlined } from '@ant-design/icons';

interface InputEvent extends React.FormEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

interface KeyboardEvent extends React.KeyboardEvent<HTMLInputElement> {
  target: HTMLInputElement;
}

interface InputTagsProps {
  disabled: boolean;
  value?: string[];
  callback?: (selectedTags: string[]) => void;
  placeholder?: string;
}

export function InputTags({ disabled, value, callback, placeholder = '' }: InputTagsProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const inputPlaceholder = placeholder || t('searchByQuery');
  const [notificationEmailInputFocus, setNotificationEmailInputFocus] = useState<boolean>(false);
  const [notificationEmailInputHover, setNotificationEmailInputHover] = useState<boolean>(false);
  const [tags, setTags] = useState<string[]>((value as string[]) || []);
  const [inputText, setInputText] = useState('');
  const hasMounted = useRef(0);
  const isClearAllTagsIconVisible = notificationEmailInputHover && !disabled && (tags.length || inputText);

  /**
   * Synchronizes tags with external value prop.
   */
  useEffect(() => {
    if (!value || !value.length) return;
    setTags(value);
  }, [value]);

  /**
   * Invokes callback on tags change.
   */
  useEffect(() => {
    if (!hasMounted.current || !callback) return;
    callback(tags);
  }, [tags]);

  /**
   * Handles the addition of a new tag based on keyboard event.
   * @param e - The keyboard event object.
   */
  function handleAddNewTag(e: KeyboardEvent) {
    hasMounted.current = 1;
    e.stopPropagation();
    e.preventDefault();

    const inputValue = e.target.value.trim();

    if (inputValue === '' || inputValue === ' ') return;

    // Split input by comma or semicolon followed by optional space
    const newTags = inputValue.split(/[,\s]*[,;]\s*/);

    // Remove any empty or whitespace-only tags
    const filteredNewTags = newTags.filter((tag) => tag.trim() !== '');

    // Use a Set to ensure uniqueness
    const uniqueTags = new Set([...tags, ...filteredNewTags]);

    setTags(Array.from(uniqueTags));
    setInputText('');
  }

  /**
   * Handles the deletion of a tag from the tags list.
   * @param  key - The key (tag) to be deleted from the tags list.
   */
  function handleDeleteTag(key: string) {
    hasMounted.current = 1;
    const filteredTags = tags.filter((tag: string) => tag !== key);

    setTags(filteredTags);
  }

  /**
   * Removes the last element from the tags array.
   */
  function handleDeleteLastTag() {
    hasMounted.current = 1;
    if (!tags.length) return;
    const newTags = [...tags];

    newTags.pop();
    setTags(newTags);
  }

  /**
   * Handles the change event of the input field.
   * Checks for special conditions and delegates actions accordingly.
   * @param e - The input event object.
   */
  function changeInputHandler(e: InputEvent) {
    e.stopPropagation();
    e.preventDefault();
    hasMounted.current = 1;
    const inputValue = e.target.value;

    setInputText(inputValue);
  }

  /**
   * Handles the key down event for the input field.
   * Deletes the last tag if Backspace key is pressed and input is empty.
   * @param  event - The keyboard event object.
   */
  function handleKeyDown(event: KeyboardEvent) {
    hasMounted.current = 1;
    const inputVariable = event.target.value.trim();

    if (event.key === 'Backspace' && inputVariable === '' && tags.length > 0) {
      handleDeleteLastTag();
    }
  }

  /**
   * Clears all values in the tags field and the input text field.
   */
  function clearAllValues() {
    hasMounted.current = 1;
    setTags([]);
    setInputText('');
  }

  return (
    <div
      data-testid="input-tags-container"
      onMouseEnter={() => setNotificationEmailInputHover(true)}
      onMouseLeave={() => setNotificationEmailInputHover(false)}
      className={classNames(
        'flex flex-wrap relative items-center border border-[var(--input-border)] rounded-[5px] px-[20px] py-[4px] gap-x-[5px] gap-y-[5px] min-h-[48px] hover:border-[var(--tertiary)] hover:shadow-[0_5px_10px_0_var(--border-opacity)] transition-all',
        {
          'border-[var(--tertiary)] shadow-[0_5px_10px_0_var(--border-opacity)]': notificationEmailInputFocus,
          'cursor-not-allowed bg-[var(--disabled-background)]': disabled
        }
      )}
    >
      <CloseCircleFilled
        data-testid="input-tags-clear-all"
        className={`${
          isClearAllTagsIconVisible ? 'block' : 'hidden'
        } absolute right-3 opacity-[50%] hover:opacity-[100%] cursor-pointer z-10`}
        onClick={clearAllValues}
      />
      {tags?.map((email: string, index: number) => (
        <Tag
          data-testid="input-tags-tag"
          key={email}
          closable={!disabled}
          className={` overflow-hidden m-0 border-[var(--input-border)] bg-[var(--light-secondary-bg)] text-base leading-5 px-[10px] py-[4px] flex items-center ${
            disabled ? '!text-[var(--disabled-text)]' : ''
          }`}
          onClose={() => handleDeleteTag(email)}
          closeIcon={
            <CloseOutlined
              data-testid="input-tags-delete"
              className="text-base ml-[7px] flex items-center !text-[10px]"
            />
          }
        >
          <span className="truncate">{email}</span>
        </Tag>
      ))}
      <Input
        data-testid="input-tags-input"
        disabled={disabled}
        className={`!p-[0px] max-h-[34px] border-none flex-1 hover:!shadow-none ${disabled ? '!bg-transparent' : ''}`}
        placeholder={tags?.length ? '' : inputPlaceholder}
        type="text"
        value={inputText}
        onChange={changeInputHandler}
        onFocus={() => setNotificationEmailInputFocus(true)}
        onBlur={() => setNotificationEmailInputFocus(false)}
        onPressEnter={handleAddNewTag}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}
