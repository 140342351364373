import { Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

interface HintProps {
  children: React.ReactNode;
  content: string;
}

export default function Hint({ children, content }: HintProps) {
  return (
    <Tooltip
      color="var(--light-secondary-bg)"
      title={
        <div className="w-full flex justify-center items-center">
          <InfoCircleOutlined className="text-[var(--button-primary)]" style={{ fontSize: '16px' }} />
          <p className="text-[var(--placeholder-secondary)] whitespace-pre-wrap ml-1">{content}</p>
        </div>
      }
    >
      {children}
    </Tooltip>
  );
}
