/**
 * Calculates the percentage of a partial value relative to a total value, rounded to a specified number of decimal places.
 * @param partialValue - The part of the total value for which the percentage needs to be calculated.
 * @param totalValue - The total value that represents 100%.
 * @param decimals - The number of decimal places to round the result to. Defaults to 2.
 * @returns The calculated percentage, rounded to the specified number of decimal places.
 */
export function calculatePercentage(partialValue = 0, totalValue?: number, decimals = 2) {
  if (!totalValue) return 0;

  // Create a factor to shift the decimal point for accurate rounding.
  const factor = 10 ** decimals;

  // Calculates the percentage, rounds it to the specified number of decimal places, and ensures the result is valid
  return Math.round((partialValue / totalValue) * 100 * factor) / factor || 0;
}
