export enum RocketReachDepartments {
  'Product & Engineering' = 'Product & Engineering',
  'Graphic Design' = 'Graphic Design',
  'Product Design' = 'Product Design',
  'Web Design' = 'Web Design',
  'Data' = 'Data',
  'Devops' = 'Devops',
  'Electrical' = 'Electrical',
  'Mechanical' = 'Mechanical',
  'Network' = 'Network',
  'Information Technology' = 'Information Technology',
  'Project Engineering' = 'Project Engineering',
  'Quality Assurance' = 'Quality Assurance',
  'Security' = 'Security',
  'Software' = 'Software',
  'Systems' = 'Systems',
  'Web' = 'Web',
  'Finance' = 'Finance',
  'Accounting' = 'Accounting',
  'Investment' = 'Investment',
  'Tax' = 'Tax',
  'HR' = 'HR',
  'Compensation' = 'Compensation',
  'Employee Development' = 'Employee Development',
  'Recruiting' = 'Recruiting',
  'Legal' = 'Legal',
  'Judicial' = 'Judicial',
  'Lawyer' = 'Lawyer',
  'Paralegal' = 'Paralegal',
  'Marketing' = 'Marketing',
  'Brand Marketing' = 'Brand Marketing',
  'Content Marketing' = 'Content Marketing',
  'Product Marketing' = 'Product Marketing',
  'Events' = 'Events',
  'Media Relations' = 'Media Relations',
  'Broadcasting' = 'Broadcasting',
  'Editorial' = 'Editorial',
  'Journalism' = 'Journalism',
  'Video' = 'Video',
  'Writing' = 'Writing',
  'Health' = 'Health',
  'Dental' = 'Dental',
  'Doctor' = 'Doctor',
  'Fitness' = 'Fitness',
  'Nursing' = 'Nursing',
  'Therapy' = 'Therapy',
  'Wellness' = 'Wellness',
  'Operations' = 'Operations',
  'Logistics' = 'Logistics',
  'Office Management' = 'Office Management',
  'Product' = 'Product',
  'Project Management' = 'Project Management',
  'Customer Success' = 'Customer Success',
  'Support' = 'Support',
  'Sales' = 'Sales',
  'Accounts' = 'Accounts',
  'Business Development' = 'Business Development',
  'Pipeline' = 'Pipeline'
}
