import { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Spinner from '../../Spinner/Spinner';

interface ListIntentHintProps {
  items: string[];
  isLoading: boolean;
}

export function ListIntentHint({ items, isLoading }: ListIntentHintProps) {
  const { t: tProspecting } = useTranslation('translation', { keyPrefix: 'prospecting' });

  return (
    <Tooltip
      color="var(--white)"
      overlayInnerStyle={{ minWidth: '250px' }}
      title={
        <div className="bg-[var(--light-secondary-bg)] border border-solid border-[var(--input-border)] rounded text-[var(--dark)] m-[5px 10px 10px]">
          {items?.length ? (
            <ol
              data-testid="tooltip-list-content"
              className="max-h-[300px] px-2  py-1 list-decimal list-inside text-start overflow-y-auto "
            >
              {items.map((item) => (
                <li className="break-all" key={item}>
                  {item}
                </li>
              ))}
            </ol>
          ) : (
            <div className="flex justify-center">
              {isLoading ? (
                <div data-testid="tooltip-list-loader" className="flex flex-col gap-1">
                  <Spinner />
                  <span>{tProspecting('loading1')}</span>
                </div>
              ) : (
                <span data-testid="tooltip-list-not-found"> {tProspecting('noCompaniesFound')}</span>
              )}
            </div>
          )}
        </div>
      }
    >
      <InfoCircleOutlined data-testid="info-icon" className="cursor-pointer text-[var(--button-primary)]" />
    </Tooltip>
  );
}
