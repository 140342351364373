import { RolesEnum } from '.';

import { Campaign } from './campaign';
import { Company } from './company';
import { FeatureMatrix } from './feature';

export enum UserStatus {
  Active = 'Active',
  Inactive = 'Inactive'
}

export interface HubSpotCampaignsConfig {
  campaignId?: number;
  listId?: number;
  campaignName?: string;
}

export interface HubSpotSingleCampaignConfig {
  campaignId?: number;
}

export interface ContactList {
  id: number;
  name: string;
}

export enum IntegrationProvider {
  calendly = 'calendly',
  hubspot = 'hubspot',
  salesforce = 'salesforce',
  google = 'google',
  microsoft = 'microsoft',
  cloudcruise = 'cloudcruise'
}

export type OnboardingSpotlights = {
  // Integrations
  isIntegrationsSectionTitleOpened: boolean;
  isMailboxesOpened: boolean;
  isGoogleOpened: boolean;
  isCalendlyOpened: boolean;
  isHubspotOpened: boolean;

  // Campaign
  isNameOpened: boolean;
  isGoalOpened: boolean;
  isCalendlyLinkOpened: boolean;
  isNumberOfFollowUpsOpened: boolean;
  isDaysBetweenFollowUpsOpened: boolean;
  isPlannedSendTimeOpened: boolean;
  isTimePresetsOpened: boolean;
  isSubjectLinesOpened: boolean;
  isEmailTrackingOpened: boolean;
  isUnsubscribeOpened: boolean;
  isSmsSendingOpened: boolean;
  isPreviewEmailOpened: boolean;
  isCreateCampaignOpened: boolean;

  // Leads
  isLeadsSectionTitleOpened: boolean;
  isSelectFromExistingLeadsOpened: boolean;
  isCsvOpened: boolean;
  isFindLeadsOpened: boolean;

  // Persona
  isPersonaSectionTitleOpened: boolean;
  isChoosePersonaOpened: boolean;
  isSelectExistingOpened: boolean;
  isDetailsOpened: boolean;
  // Warm-up
  isWarmupOpened: boolean;
};

export interface GoogleIntegrationTokenConfig {
  historyId?: string;
  warmupMailboxId?: number;
}

export interface HubSpotIntegrationTokenConfig {
  connections: HubSpotCampaignsConfig[];
}

export interface CloudCruiseIntegrationTokenConfig {
  linkedinProfileUrl: string;
}

export interface CalendlyProfile {
  uri: string;
  name: string;
  slug: string;
  timezone: string;
  avatar_url: string;
  created_at: string;
  updated_at: string;
  scheduling_url: string;
  current_organization: string;
}

export interface GoogleProfile {
  sub: string;
  email: string;
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  locale: string;
  hd: string;
}

export interface HubspotProfile {
  app_id: number;
  hub_domain: string;
  hub_id: number;
  scopes: string[];
  user: string;
  user_id: number;
  token_type: string;
}

export interface MicrosoftProfile {
  id: string;
  mail: string;
  surname: string;
  jobTitle: string | null;
  givenName: string;
  displayName: string;
  mobilePhone: string | null;
  '@odata.context': string;
  businessPhones: string[];
  officeLocation: string | null;
  preferredLanguage: string;
  userPrincipalName: string;
}

export interface IntegrationTokenUser {
  id: number;
  email: string;
}

export interface IntegrationToken {
  id: number;
  slug: string;
  provider: IntegrationProvider;
  expiresAt: Date;
  dailySendingLimit: number;
  mailboxFullName?: string;
  isActive: boolean;
  warmupMailboxId?: number;
  config?: GoogleIntegrationTokenConfig | HubSpotIntegrationTokenConfig | CloudCruiseIntegrationTokenConfig;
  profile?: MicrosoftProfile | HubspotProfile | CalendlyProfile | GoogleProfile;
  user?: IntegrationTokenUser;
}

export interface UserPreferences {
  notificationEmails?: string[];
  isOnboardingCompleted?: boolean;
  spotlightSuggestions?: OnboardingSpotlights;
}

export interface OnboardingConfig {
  isGoogleIntegrated?: boolean;
  isPersonaCreated?: boolean;
  isCalendlyIntegrated?: boolean;
  isCampaignLaunched?: boolean;
  isWarmupWithGoogleEnabled?: boolean;

  [key: string]: boolean | undefined;
}

export interface UserInterface {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  status: UserStatus;
  campaigns: Campaign[];

  companyId: number;
  company: Partial<Company>;
  roles: {
    id: number;
    name: RolesEnum;
  }[];
  features: FeatureMatrix;

  paragonToken: string;

  integrationTokens: IntegrationToken[];

  pictureUrl: string;

  userPreferences: UserPreferences;

  onboarding?: OnboardingConfig;

  createdAt: Date;
  updatedAt: Date;
}

export interface UpdateUserInterface extends Pick<UserInterface, 'id' | 'firstName' | 'lastName' | 'email'> {
  company: number;
  roles: number[];
  notificationEmails?: string[];
  newNotificationEmail?: string;
}

export interface LocalStorageAccountData {
  fullName: string;
  email: string;
}

export interface LocalStorageAccountsData {
  [email: string]: LocalStorageAccountData;
}
