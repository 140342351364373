import {
  ApolloCompany,
  ApolloLead,
  type Campaign,
  type Lead,
  LeadProvider,
  Revenue,
  RocketReachCompany,
  RocketReachCompanySize,
  RocketReachLead,
  YearsExperience
} from '../../types';

import { ProviderTags } from '../constants';
import { DefaultPaginationParams } from '../types';
import { apiSlice } from './apiSlice';

export interface GetAllLeadsResponse {
  data: Lead[];
  totalCount: number;
}

interface LeadSearchOptions {
  search?: string;
  excludedCampaignIds?: string[] | string;
}

interface GetAllLeadsRequest {
  params?: DefaultPaginationParams & LeadSearchOptions & { leadsId?: number[] };
}

interface GetLeadsDuplicationsResponse {
  [email: string]: Lead[];
}

interface GetLeadsDuplicationsStatusResponse {
  exists: boolean;
}

interface DeleteLeadsDuplicationsRequest {
  leads: number[];
}

interface DeleteLeadDuplicationsResponse {
  status: 'ok';
}

export interface LeadProviderOptions {
  leadProvider?: LeadProvider;
}

export interface RocketReachSearchQuery {
  name: string[];
  current_title: string[];
  employer: string[];
  geo: string[];
  company_industry: string[];
  company_size: RocketReachCompanySize[];
  years_experience: YearsExperience[];
  department: string[];
  management_levels: string[];
  current_employer: string[];
  company_revenue: Revenue[];
  keywords?: string[];
  description?: string[];
  company_funding_min?: string[];
  company_funding_max?: string[];
}

export interface ApolloSearchQuery {
  q_person_name: string;
  employer: string[];
  person_titles: string[];
  person_locations: string[];
  organization_industries?: string[];
  organization_num_employees_ranges: string[];
  person_total_yoe_range: string[];
  company_revenue: string[];
  person_seniorities: string[];
  q_keywords?: string;
  organization_latest_funding_stage_cd: string[];
  q_organization_keyword_tags?: string[];
  organization_domains?: string[];
}

export interface SwarmSearchQuery {
  name: string[];
  current_title: string[];
  current_employer: string[];
  geo: string[];
  company_industry: string[];
  company_size: string[];
  company_websites: string[];
  department: string[];
  management_levels: string[];
}

export interface ZoomInfoSearchQuery {
  leadName: string;
  companyName: string[];
  companyWebsites: string[];
  industry: string[];
  country: string[];
  companySize: string[];
  companyRevenue: string[];
  jobTitle: string[];
  department: string[];
  managementLevels: string[];
}

export interface LeadProviderCompanySearchQuery {
  name: string | string[];
  description?: string[];
}

export interface ApolloCompanySearchQuery {
  q_organization_name: string;
  organization_locations: string[];
}

interface LeadProviderRequestBody {
  start: number;
  pageSize: number;
}

interface ApolloRequestBody {
  page: number;
  pageSize: number;
}

export type LeadProvidersType = RocketReachSearchQuery | ApolloSearchQuery | SwarmSearchQuery | ZoomInfoSearchQuery;

export interface LeadProviderPersonSearchRequest extends LeadProviderRequestBody {
  query: LeadProvidersType;
  leadProviderOptions: LeadProviderOptions;
  paginationToken?: string;
}

export interface LeadProviderCompanySearchRequest extends LeadProviderRequestBody {
  query: Partial<LeadProviderCompanySearchQuery>;
  leadProviderOptions?: LeadProviderOptions;
}

export interface ApolloCompanySearchRequest extends ApolloRequestBody {
  query: ApolloCompanySearchQuery;
}

interface RateLimitError {
  message?: string;
  statusCode?: number;
  type?: string;
}

interface LeadProviderResponseBody {
  pagination?: {
    start: number;
    next: number;
    total: number;
    paginationToken?: string;
  };
  error?: RateLimitError;
}

export interface LeadProviderSearchPersonResponse extends LeadProviderResponseBody {
  profiles: RocketReachLead[];
}

export interface ApolloSearchPersonResponse extends LeadProviderResponseBody {
  profiles: ApolloLead[];
}

export interface LeadProviderSearchCompanyResponse extends LeadProviderResponseBody {
  companies: RocketReachCompany[] | ApolloCompany[];
}

export enum LeadProviderSuggestionsFields {
  CurrentTitle = 'current_title',
  CompanyIndustry = 'company_industry',
  Geo = 'geo',
  Name = 'name'
}

interface LeadProviderBatchSuggestion {
  value: string;
  cleaned_name: string;
  sub_count: number;
}

export type LeadProviderSuggestionsResponse = (string | LeadProviderBatchSuggestion)[];

export interface LeadAdditionalInformation {
  [key: string]: { id: number; description: string; value: string };
}

interface UpdateLeadAdditionalInformationRequest {
  leadId: string;
  changedValues: LeadAdditionalInformation;
}

interface EnrichedProviderLead {
  lead: Lead;
  error?: RateLimitError;
}

export const leadsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getLeads: builder.query<GetAllLeadsResponse, GetAllLeadsRequest>({
      query: ({ params }) => ({
        url: 'leads',
        params
      }),
      providesTags: (result) => result?.data.map(({ id }) => ({ type: ProviderTags.Leads, id })) ?? []
    }),
    getLeadsDuplications: builder.query<GetLeadsDuplicationsResponse, null>({
      query: () => ({
        url: 'leads/duplicates'
      }),
      providesTags: [ProviderTags.LeadsDuplications]
    }),
    getLeadsDuplicationsStatus: builder.query<GetLeadsDuplicationsStatusResponse, null>({
      query: () => ({
        url: 'leads/duplicates/status'
      }),
      providesTags: [ProviderTags.LeadsDuplications]
    }),
    deleteLeadDuplications: builder.mutation<DeleteLeadDuplicationsResponse, Pick<Lead, 'id'>>({
      query: ({ id }) => ({
        url: `leads/${id}/duplicates`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: [
        ProviderTags.LeadsDuplications,
        ProviderTags.CampaignLeads,
        ProviderTags.Campaigns,
        ProviderTags.Leads,
        ProviderTags.CampaignEvents
      ]
    }),
    deleteLeadsDuplications: builder.mutation<DeleteLeadDuplicationsResponse, DeleteLeadsDuplicationsRequest>({
      query: (body) => ({
        url: `leads/duplicates`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body
      }),
      invalidatesTags: [
        ProviderTags.LeadsDuplications,
        ProviderTags.CampaignLeads,
        ProviderTags.Campaigns,
        ProviderTags.Leads,
        ProviderTags.CampaignEvents
      ]
    }),
    getLead: builder.query<Lead, Pick<Lead, 'id'>>({
      query: ({ id }) => `leads/${id}`,
      providesTags: (result, error, { id }) => [{ type: ProviderTags.Leads, id }]
    }),
    createLead: builder.mutation<
      Lead,
      Partial<Lead> & {
        campaigns: {
          campaign: {
            id: Partial<Campaign>;
          };
        }[];
      }
    >({
      query: (body) => ({
        url: 'leads',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.Leads, ProviderTags.LeadsDuplications]
    }),
    editLead: builder.mutation<Lead, Partial<Lead>>({
      query: (body) => ({
        url: `leads/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: ProviderTags.Leads, id },
        { type: ProviderTags.LeadsDuplications }
      ]
    }),
    deleteLead: builder.mutation<void, Pick<Lead, 'id'>>({
      query: ({ id }) => ({
        url: `leads/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [ProviderTags.Leads, ProviderTags.LeadsDuplications]
    }),
    getLeadProviderLeads: builder.query<LeadProviderSearchPersonResponse, LeadProviderPersonSearchRequest>({
      query: (body) => {
        const url = (() => {
          const leadProvider = body?.leadProviderOptions?.leadProvider;

          if (leadProvider === LeadProvider.Apollo) return 'leads/green/search';

          if (leadProvider === LeadProvider.Swarm) return 'swarm/person-search';

          if (leadProvider === LeadProvider.ZoomInfo) return 'zoom-info/person-search';

          return 'leads/blue/search';
        })();

        return {
          url,
          params: {
            ...body,
            query: JSON.stringify(body.query)
          }
        };
      }
    }),
    getLeadProviderFieldSuggestions: builder.query<
      LeadProviderSuggestionsResponse,
      { field: LeadProviderSuggestionsFields; input: string; leadProviderOptions?: LeadProviderOptions }
    >({
      query: ({ field, input, leadProviderOptions }) => {
        const url = (() => {
          const leadProvider = leadProviderOptions?.leadProvider;

          if (leadProvider === LeadProvider.Apollo) return `leads/green/suggestions/${field}?input=${input}`;

          if (leadProvider === LeadProvider.Swarm) return `swarm/suggestions/${field}?input=${input}`;

          return `leads/blue/suggestions/${field}?input=${input}`;
        })();

        return { url };
      }
    }),
    enrichProvidedLead: builder.mutation<
      EnrichedProviderLead,
      { id: number; campaignId?: number; leadProviderOptions?: LeadProviderOptions }
    >({
      query: (body) => {
        const url = (() => {
          const leadProvider = body?.leadProviderOptions?.leadProvider;

          if (leadProvider === LeadProvider.Apollo) return 'leads/green/enrichment';

          if (leadProvider === LeadProvider.Swarm) return 'swarm/person-lookup';

          if (leadProvider === LeadProvider.ZoomInfo) return 'zoom-info/enrichment';

          return 'leads/blue/lookup';
        })();

        return {
          url,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(body)
        };
      },
      invalidatesTags: (result, error, { campaignId }) => [
        { type: ProviderTags.Campaigns },
        { type: ProviderTags.CampaignLeads, id: campaignId },
        { type: ProviderTags.Leads }
      ]
    }),
    getLeadProviderCompanies: builder.query<LeadProviderSearchCompanyResponse, LeadProviderCompanySearchRequest>({
      query: (body) => {
        const url =
          body?.leadProviderOptions?.leadProvider === LeadProvider.Apollo
            ? 'leads/green/search-companies'
            : 'leads/blue/search-companies';

        return {
          url,
          params: {
            ...body,
            query: JSON.stringify(body.query)
          }
        };
      }
    }),
    getLeadAdditionalInformation: builder.query<LeadAdditionalInformation, { leadId: string }>({
      query: ({ leadId }) => ({
        url: `lead-additional-information/${leadId}`
      }),
      providesTags: [ProviderTags.LeadAdditionalInformation]
    }),
    editLeadAdditionalInformation: builder.mutation<null, UpdateLeadAdditionalInformationRequest>({
      query: ({ leadId, ...body }) => ({
        url: `lead-additional-information/${leadId}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body.changedValues)
      }),
      invalidatesTags: [ProviderTags.LeadAdditionalInformation]
    })
  })
});

export const {
  useGetLeadsQuery,
  useGetLeadQuery,
  useEditLeadMutation,
  useDeleteLeadMutation,
  useCreateLeadMutation,
  useLazyGetLeadsQuery,
  useGetLeadsDuplicationsQuery,
  useGetLeadsDuplicationsStatusQuery,
  useDeleteLeadDuplicationsMutation,
  useDeleteLeadsDuplicationsMutation,
  useLazyGetLeadProviderLeadsQuery,
  useGetLeadProviderLeadsQuery,
  useGetLeadProviderCompaniesQuery,
  useGetLeadProviderFieldSuggestionsQuery,
  useEnrichProvidedLeadMutation,
  useGetLeadAdditionalInformationQuery,
  useEditLeadAdditionalInformationMutation
} = leadsApi;
