import type { Persona } from '../../types';
import { ProviderTags } from '../constants';
import { DefaultPaginationParams } from '../types';

import { apiSlice } from './apiSlice';

interface GetAllPersonasResponse {
  data: Persona[];
  totalCount: number;
}

interface GetAllPersonasRequest {
  params?: DefaultPaginationParams & { search?: string; canGetAll?: boolean };
}

export const personasApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPersonas: builder.query<GetAllPersonasResponse, GetAllPersonasRequest>({
      query: ({ params }) => ({ url: 'personas', params }),
      providesTags: (result, error, arg) =>
        result?.data?.length
          ? [...result.data.map(({ id }) => ({ type: ProviderTags.Persona, id })), ProviderTags.Persona]
          : [ProviderTags.Persona]
    }),
    getPersona: builder.query<Persona, Pick<Persona, 'id'>>({
      query: ({ id }) => `personas/${id}`,
      providesTags: (result, error, { id }) => [{ type: ProviderTags.Persona, id }]
    }),
    createPersona: builder.mutation<Persona, Partial<Persona>>({
      query: (body) => ({
        url: 'personas',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Persona }, ProviderTags.Me]
    }),
    editPersona: builder.mutation<Persona, Partial<Persona>>({
      query: (body) => ({
        url: `personas/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.Persona, id }]
    }),
    deletePersona: builder.mutation<void, Pick<Persona, 'id'>>({
      query: ({ id }) => ({
        url: `personas/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: [{ type: ProviderTags.Persona }, ProviderTags.Me]
    }),
    copyPersona: builder.mutation<Persona, Pick<Persona, 'id'> & { personaName: string }>({
      query: (body) => ({
        url: `personas/copy/${body.id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [{ type: ProviderTags.Persona }]
    })
  })
});

export const {
  useGetPersonasQuery,
  useGetPersonaQuery,
  useCreatePersonaMutation,
  useEditPersonaMutation,
  useCopyPersonaMutation,
  useDeletePersonaMutation
} = personasApi;
