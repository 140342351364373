import { Empty, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import Spinner from '../../Spinner/Spinner';

interface SearchableMultiSelectCollapseProps<T> {
  id: string;
  value?: T;
  placeholder?: string;
  options?: { label: T | string; value: T | string }[];
  setSelected?: (value?: T) => void;
  mode?: 'multiple' | 'tags';
  isLoading?: boolean;
  disabled?: boolean;
  showSearch?: boolean;
  setSearch?: (value: string) => void;
}

export default function MultiSelect<T>({
  id,
  value,
  isLoading,
  options,
  placeholder = 'Search',
  disabled = false,
  showSearch = false,
  mode,
  setSelected,
  setSearch
}: SearchableMultiSelectCollapseProps<T>) {
  const { t } = useTranslation('translation', { keyPrefix: 'builder.advancedSearch.form' });

  /**
   * Render search dropdown with loader when entering values
   * @param menu
   * @returns
   */
  function renderSearchDropdown(menu: ReactElement) {
    return isLoading ? (
      <Empty
        description={
          <div className="flex gap-2.5 justify-center mb-2.5">
            <Spinner />
            {t('suggestionsLoading')}
          </div>
        }
      />
    ) : (
      menu
    );
  }

  /**
   * Hadle select change
   * @param fieldValue
   */
  function onChange(fieldValue: T) {
    setSelected?.(fieldValue);
    if (mode && Array.isArray(fieldValue) && !fieldValue.length && setSearch) {
      setSearch('');
    }
  }

  return (
    <Select
      allowClear
      id={id}
      virtual={false}
      data-t={`intent-filter-${id}-select`}
      mode={mode}
      disabled={disabled}
      tokenSeparators={['enter']}
      className={cn('multiple-select min-w-[120px] w-full')}
      suffixIcon={<DownOutlined className="text-[var(--dark)] text-sm" />}
      placeholder={placeholder}
      options={options}
      optionLabelProp="label"
      value={value || null}
      rootClassName={id}
      onChange={onChange}
      showSearch={showSearch}
      onSearch={setSearch}
      onBlur={() => setSelected?.(value)}
      onClear={() => setSearch?.('')}
      dropdownRender={renderSearchDropdown}
    />
  );
}
