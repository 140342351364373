import { Descriptions, Select, Skeleton } from 'antd';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { Company, Feature } from '../../../types';

interface FeatureInfoProps {
  feature: Feature;
  isLoading: boolean;
  companies: Company[];
  chosenCompaniesId: number[];
}

export default function FeatureInfo(props: FeatureInfoProps) {
  const { feature, companies, chosenCompaniesId, isLoading } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'features' });

  /**
   * Show proper text according to isDefaultEnabled value
   */
  const isDefaultEnabledText = useMemo(
    () => (feature?.isDefaultEnabled ? t('table.enabled') : t('table.disabled')),
    [feature?.isDefaultEnabled]
  );

  return (
    <div>
      {!isLoading ? (
        <div className="flex mb-4 gap-x-4 items-center">
          <h2 className="text-4xl font-extrabold">{feature?.name}</h2>
        </div>
      ) : (
        <div className="flex flex-col gap-y-2 mb-4 p-0">
          <Skeleton.Input size="large" active className="m-0 p-0" />
        </div>
      )}

      <Descriptions
        data-t="feature-info"
        title={t('featureDetails')}
        bordered
        column={1}
        size="small"
        className="w-auto"
      >
        <Descriptions.Item label={t('table.description')} className="bg-white !rounded-tr-lg">
          {!isLoading ? (
            feature?.description || t('table.noDescription')
          ) : (
            <Skeleton.Input size="small" active className="m-0 p-0 !h-5" />
          )}
        </Descriptions.Item>

        <Descriptions.Item label={t('table.isDefaultEnabled')} className="bg-white !rounded-br-lg">
          <div data-t="feature-default-enabled">
            {!isLoading ? isDefaultEnabledText : <Skeleton.Input size="small" active className="m-0 p-0 !h-5" />}
          </div>
        </Descriptions.Item>
      </Descriptions>

      <div className="mt-8 flex flex-col">
        <label htmlFor="companiesId" className="text-sm">
          {t('companiesSelectLabel', {
            condition: feature?.isDefaultEnabled ? t('isDefaultEnabled') : t('isDefaultDisabled')
          })}
        </label>
        {!isLoading ? (
          <Select
            size="large"
            mode="multiple"
            className="w-full mt-2 multiple-select"
            allowClear
            bordered
            value={chosenCompaniesId}
            disabled
            id="companiesId"
            placeholder={t('companiesSelectPlaceholder')}
          >
            {companies?.map((item) => (
              <Select.Option value={item.id} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        ) : (
          <Skeleton.Input size="large" active className="m-0 p-0 !mt-1 !w-full" />
        )}
      </div>
    </div>
  );
}
