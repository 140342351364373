import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'antd';

import { useGetMeQuery } from '../../../redux-store';
import { AiProspectingSearchMethods, CompanyProviders } from '../../../types/aiProspecting';
import { Features } from '../../../types';
import { SectionsProps } from './ProspectsWrapper';

import CheckMark from '../images/checkMark.svg';

interface SearchSectionButtonProps {
  selectedSearchSections: string[];
  method: AiProspectingSearchMethods;
  onClick: (searchMethod: AiProspectingSearchMethods) => void;
  Component: React.ComponentType<SectionsProps>;
  isLoading: boolean;
  commonProps: Omit<SectionsProps, 'disabled' | 'isSelected' | 'showCommonValidationError'>;
}

export default function SearchSectionButton({
  method,
  selectedSearchSections,
  onClick,
  Component,
  isLoading,
  commonProps
}: SearchSectionButtonProps) {
  const { currentData: user } = useGetMeQuery(null);
  const selectedCompanyProvider = Form.useWatch('companyProvider', { form: commonProps?.form });
  const hasWebNewsSearchFeatureEnabled = user?.hasFeature(user, Features.WebNewsIntentSearch);
  const isSelected = useMemo(
    () => !!(selectedSearchSections?.includes(method) && hasWebNewsSearchFeatureEnabled),
    [selectedSearchSections, hasWebNewsSearchFeatureEnabled]
  );
  const isHiringIntent = method === AiProspectingSearchMethods.Hiring;
  const isEnabledHiringIntent = useMemo(
    () => hasWebNewsSearchFeatureEnabled && isHiringIntent && selectedCompanyProvider === CompanyProviders.OceanIo,
    [hasWebNewsSearchFeatureEnabled, selectedCompanyProvider]
  );
  const isDisabled = useMemo(
    () => !!((!isHiringIntent && !hasWebNewsSearchFeatureEnabled) || isEnabledHiringIntent),
    [hasWebNewsSearchFeatureEnabled, isEnabledHiringIntent]
  );
  const newProps: SectionsProps = {
    ...commonProps,
    disabled: isDisabled || isLoading,
    isSelected
  };
  const selectedSectionNumber = selectedSearchSections?.length ? selectedSearchSections.indexOf(method) + 1 : 0;
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });

  /**
   * Handles the click event on the section.
   * @param e - The mouse event object.
   */
  function handleClick(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    // This is to prevent the function from being triggered by pressing the "space" or "enter" keys.
    if (!e?.detail) return;

    const target = e.target as HTMLElement;
    const shouldIgnoreClick =
      target.closest(
        '.ant-select-item, .ant-select, .ant-select-dropdown, .ant-picker-panel, .ant-picker-input, input, [data-testid="input-tags-container"]'
      ) !== null;

    if (shouldIgnoreClick) return;
    onClick(method);
  }

  return (
    <button
      data-testid={`${method}-intent-search-section`}
      disabled={isDisabled || isLoading}
      type="button"
      key={method}
      onClick={handleClick}
      className={`outline-none  w-full text-start relative rounded-lg cursor-pointer flex-1 ${
        isDisabled ? 'opacity-50 !cursor-not-allowed' : ''
      }`}
    >
      {isDisabled && (
        <div className="px-2 py-1 bg-[var(--light-secondary-bg)] absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 z-10 rounded-xl border border-[var(--border)] text-center">
          {t(isHiringIntent ? 'disabledInfo' : 'comingSoon')}
        </div>
      )}
      {isSelected && (
        <div
          data-testid="intent-section-selected-icon"
          className="w-8 h-8 rounded-full absolute right-[-9px] top-[32px] bg-[var(--action)] flex items-center justify-center"
        >
          {selectedSearchSections.length > 1 ? (
            <span className="text-white text-xl font-bold">{selectedSectionNumber}</span>
          ) : (
            <img src={CheckMark} alt="blue check mark" />
          )}
        </div>
      )}
      <Component {...newProps} />
    </button>
  );
}
