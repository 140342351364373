const LOCAL_STORAGE_KEY = 'aisdr';

/**
 * Set data to localstorage
 * @param name {string}
 * @param data {any}
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setItemToLocalStorage = (name: string, data: any) => {
  const localStorageObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

  localStorageObject[name] = data;
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(localStorageObject));
};

/**
 * Get item data from localstorage
 * @param name {string}
 */
export const getItemFromLocalStorage = (name: string) => {
  const localStorageObject = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '{}');

  return name === LOCAL_STORAGE_KEY ? localStorageObject : localStorageObject[name];
};
