export enum RocketReachCompanySize {
  '1-10' = '1-10',
  '11-50' = '11-50',
  '51-100' = '51-100',
  '101-500' = '101-500',
  '501-1000' = '501-1000',
  '1001-5000' = '1001-5000',
  '5001-10000' = '5001-10000',
  '10001-25000' = '10001-25000',
  More_Than_25001 = '25001<'
}
