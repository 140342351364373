export enum SwarmCompanySize {
  '1-10' = '1-10',
  '11-50' = '11-50',
  '51-200' = '51-200',
  '201-500' = '201-500',
  '501-1000' = '501-1000',
  '1001-5000' = '1001-5000',
  '5001-10000' = '5001-10000',
  '10001+' = '10001+'
}
