import { ChangeEvent, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Button, Checkbox, Form, FormInstance, Input, message, Table, Tooltip } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { useTranslation } from 'react-i18next';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { SearchOutlined } from '@ant-design/icons';

import { IntentLeadDataResult, useCreateIntentSearchRecordMutation } from '../../redux-store/slices/intentApiSlice';
import { AiProspectingSearchMethods } from '../../types/aiProspecting';
import Pagination, { ItemsPerPage } from '../Pagination/Pagination';
import { AiProspectingBuilderForm, IntentSearchResult } from './AiProspectingBuilder';

interface LeadsTableProps {
  leadsToShowInTable: IntentSearchResult;
  form: FormInstance<AiProspectingBuilderForm>;
}

const mainColumns = ['leadName', 'jobTitle', 'companyName', 'industry', 'location'];
const hiringColumns = ['vacancyJobTitle', 'website', 'jobPostContains', 'minPostedDate'];
const webColumns = ['companySummary'];
const newsColumns = ['companyNews'];
const technologyColumns = ['companyTechnologies'];
const companyVacanciesColumns = ['vacancies'];

export default function IntentLeadsTable({ leadsToShowInTable, form }: LeadsTableProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [wasSearchQueryChanged, setWasSearchQueryChanged] = useState(false);
  const [paginationLimit, setPaginationLimit] = useState<ItemsPerPage>(10);
  const [selectedIntentCompanies, setSelectedIntentCompanies] = useState<React.Key[]>([]);
  const searchMethod = useWatch('searchMethods', { form });
  const isNotHiringSearch = searchMethod?.length > 1 || !searchMethod?.includes(AiProspectingSearchMethods.Hiring);
  const fundingLastRoundType = Form.useWatch('fundingLastRoundType', { form, preserve: true });
  const companySize = Form.useWatch('size', { form, preserve: true });
  const fundingLastRoundDate = Form.useWatch('fundingLastRoundDate', { form, preserve: true });
  const categoriesAndKeywords = Form.useWatch('categoriesAndKeywords', { form, preserve: true });
  const monthlyPercentageEmployeeGrowth = Form.useWatch('monthlyPercentageEmployeeGrowth', { form, preserve: true });
  const [intentAutoUpdateEnabled, setIntentAutoUpdateEnabled] = useState(false);
  const { t: tProspects } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const leadStates = useMemo(
    () => ({
      hasHiringData: leadsToShowInTable.leads.some(({ vacancyJobTitle }) => vacancyJobTitle),
      hasWebData: leadsToShowInTable.leads.some(({ companySummary }) => companySummary),
      hasNewsData: leadsToShowInTable.leads.some(({ companyNews }) => companyNews?.length),
      hasTechnologyData: leadsToShowInTable.leads.some(({ companyTechnologies }) => companyTechnologies),
      hasCombinedHiringData: leadsToShowInTable.leads.some(({ vacancies }) => vacancies?.length)
    }),
    [leadsToShowInTable.leads]
  );
  const [createIntentSearchRecord, { isLoading }] = useCreateIntentSearchRecordMutation();
  const [searchParams] = useSearchParams();
  const searchedLeads = getSearchedLeads();
  const rowSelection = {
    selectedRowKeys: selectedIntentCompanies.map((id) => id),
    onSelect: (newSelectedLead: IntentLeadDataResult, selected: boolean) => {
      setSelectedIntentCompanies((prev) => {
        if (selected) return [...prev, newSelectedLead.id];

        // If the lead was unselected, remove its ID from the array of selected leads
        const updatedList = [...prev];
        // Find the index of the lead to remove
        const prevSelectedLeadIndex = updatedList.findIndex((id) => id === newSelectedLead.id);

        // Remove unselected lead from list
        updatedList.splice(prevSelectedLeadIndex, 1);

        return updatedList;
      });
    },
    onSelectAll: (selected: boolean, _: IntentLeadDataResult[], changeLeads: IntentLeadDataResult[]) => {
      setSelectedIntentCompanies((prev) => {
        if (!selected) return prev.filter((id) => !changeLeads.find((item) => id === item.id));

        const changeLeadsIds = changeLeads.map((lead) => lead.id);

        return [...prev, ...changeLeadsIds];
      });
    }
  };

  /**
   * Generates a list of columns based on the provided prospecting search methods.
   * @returns An array of column objects with `title`, `dataIndex`, and `key` properties.
   */
  function generateColumns() {
    const columns = [...mainColumns];
    const monthlyPercentageEmployeeGrowthValidated = monthlyPercentageEmployeeGrowth ?? 0;

    if (leadStates.hasHiringData) columns.push(...hiringColumns);
    if (leadStates.hasWebData) columns.push(...webColumns);
    if (leadStates.hasNewsData) columns.push(...newsColumns);
    if (leadStates.hasTechnologyData) columns.push(...technologyColumns);
    if (leadStates.hasCombinedHiringData) columns.push(...companyVacanciesColumns);

    if (isNotHiringSearch) {
      if (companySize && companySize.length > 0) columns.push('companySize');

      if (fundingLastRoundType && fundingLastRoundType.length > 0) columns.push('fundingLastRoundType');

      if (fundingLastRoundDate) columns.push('fundingLastRoundDate');

      if (categoriesAndKeywords && categoriesAndKeywords.length > 0) columns.push('categoriesAndKeywords');

      if (monthlyPercentageEmployeeGrowthValidated > 0) columns.push('monthlyPercentageEmployeeGrowth');
    }

    const uniqueColumns = Array.from(new Set(columns));

    return uniqueColumns.map((item) => ({
      title: tProspects(item),
      dataIndex: item,
      key: item,
      render:
        item === 'companyNews' || item === 'vacancies'
          ? (data: string[]) => <ul>{data?.map((dataItem, i) => <li key={dataItem}>{`${i + 1}: ${dataItem}`}</li>)}</ul>
          : undefined
    }));
  }

  /**
   * Getting leads which match with search value
   * @returns
   */
  function getSearchedLeads(): IntentLeadDataResult[] {
    const foundLeads: IntentLeadDataResult[] = [];
    const excludedFields = ['managementLevel', 'department', 'funding', 'id'];

    if (searchValue) {
      leadsToShowInTable.leads.forEach((lead) => {
        let isNecessaryLeadFound = false;

        // Check if any lead value match with seach value
        Object.entries(lead).forEach(([key, value]) => {
          const isLeadWithNecessaryValue =
            !isNecessaryLeadFound &&
            !excludedFields.includes(key) &&
            typeof value === 'string' &&
            value.toLowerCase().includes(searchValue.toLowerCase());

          if (isLeadWithNecessaryValue) {
            foundLeads.push(lead);
            isNecessaryLeadFound = true;
          }
        });
      });

      return foundLeads;
    }

    return leadsToShowInTable.leads;
  }

  /**
   * Handle add intent search to campaign builder
   */
  async function handleAddIntentSearchToCampaignBuilder() {
    try {
      const { intentSearchRecordId } = await createIntentSearchRecord({
        intentPreferences: form.getFieldsValue(),
        filterUrl: searchParams.toString(),
        intentAutoUpdateEnabled
      }).unwrap();

      window.location.replace(
        `/?intentSearchId=${intentSearchRecordId}&leadsId=${encodeURIComponent(
          JSON.stringify(selectedIntentCompanies)
        )}`
      );
    } catch {
      message.error('Something went wrong');
    }
  }

  /**
   * Callback function for handling pagination changes.
   * @param {number} page - The new page number.
   * @param {ItemsPerPage} itemsPerPage - The selected items per page.
   */
  function handlePaginationChange(page: number, itemsPerPage: ItemsPerPage): void {
    setCurrentPage(page);
    setPaginationLimit(itemsPerPage);
    setWasSearchQueryChanged(false);
  }

  /**
   * Handle checkbox change
   * @param e
   */
  function handleCheckboxChange(e: CheckboxChangeEvent) {
    setIntentAutoUpdateEnabled(e.target.checked);
  }

  /**
   * Handle serach input change
   * @param e
   */
  function onSearchChange(e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value);
    setWasSearchQueryChanged(true);
  }

  return (
    <div data-testid="intent-companies-table-container">
      <h2 className="text-xl font-medium my-5" data-testid="intent-companies-table-title">
        {tProspects('searchResults')}
      </h2>
      <div className="flex items-start mb-2.5 flex-col">
        <div className="mb-2.5">{tProspects('recordsCount', { count: leadsToShowInTable?.leads?.length || 0 })}</div>
        <div className="flex flex-col sm:flex-row sm:items-center gap-[10px] w-full">
          <Input
            data-t="campaign-exiting-leads-input"
            allowClear
            value={searchValue}
            onChange={onSearchChange}
            onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
            prefix={<SearchOutlined className="text-[var(--action)] text-[20px] mr-2.5" />}
            className="max-w-[380px]"
            size="large"
            placeholder={tProspects('searchLeadsInputPlaceholder')}
          />
          <div className="text-sm">
            {tProspects('selectedLeadsCountVsTotal', {
              selectedLeadsCount: selectedIntentCompanies?.length || 0,
              totalCount: leadsToShowInTable?.leads?.length || 0
            })}
          </div>
        </div>
      </div>
      <div className="mt-5 bg-[var(--active-page)] rounded-[5px] p-[20px] border-[var(--input-border)] border-[1px] border-solid">
        <Table
          bordered
          key="id"
          rowKey="id"
          className="existing-leads-table"
          dataSource={searchedLeads}
          scroll={{ x: 980 }}
          pagination={{
            current: currentPage,
            pageSize: paginationLimit
          }}
          loading={{
            spinning: isLoading,
            style: {
              bottom: 0,
              height: 'calc(100% - 120px)',
              maxHeight: 'none',
              top: 'initial'
            }
          }}
          columns={generateColumns()}
          locale={{
            emptyText: (
              <div className="my-[40px] h-[22px]">
                {!isLoading ? (
                  <div>
                    {searchValue
                      ? tProspects('noLeadsFound')
                      : tProspects(
                          leadsToShowInTable?.vacanciesProcessed ??
                            searchMethod?.includes(AiProspectingSearchMethods.Hiring)
                            ? 'noLeadsFoundFromVacanciesWithInfo'
                            : 'noLeadsFoundFromCompanyWithInfo',
                          {
                            companiesCount: leadsToShowInTable?.totalCompaniesProcessed || 0,
                            openedInfo:
                              leadsToShowInTable?.vacanciesProcessed || leadsToShowInTable?.companiesOpened || 0
                          }
                        )}
                  </div>
                ) : (
                  <>{tProspects('noLeadsFound')}</>
                )}
              </div>
            )
          }}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection
          }}
        />
      </div>
      <Pagination
        className="mt-5"
        wasSearchQueryChanged={wasSearchQueryChanged}
        changePaginationCallback={handlePaginationChange}
        totalItems={searchedLeads?.length || 0}
      />
      <div className="mb-2.5">
        <Tooltip
          title={tProspects('continuouslyTooltip')}
          overlayClassName="!border !border-[var(--input-border)] rounded-[5px]"
        >
          <Checkbox
            data-testid="intent-auto-update-checkbox"
            onChange={handleCheckboxChange}
            name="intentAutoUpdateEnabled"
          >
            {tProspects('continuouslyAdd')}
          </Checkbox>
        </Tooltip>
      </div>
      <Button
        data-testid="intent-add-to-campaigtn-btn"
        disabled={!selectedIntentCompanies.length || isLoading}
        data-t="create-persona-btn"
        aria-label="new-persona"
        size="large"
        htmlType="button"
        onClick={handleAddIntentSearchToCampaignBuilder}
        className="!px-[30px] !text-white !text-base !rounded-[5px] border-none bg-[var(--button-primary)] active:bg-[var(--button-primary-active)] enabled:hover:shadow-primary disabled:bg-[var(--button-disabled)]"
      >
        {tProspects('addToCampaign')}
      </Button>
    </div>
  );
}
