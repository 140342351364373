import { DashboardStatistics } from '../../components/Dashboard/components/DashboardStatistics';
import { EventResponse } from '../../components/Dashboard/components/RepliesTable';
import { apiSlice } from './apiSlice';
import { CampaignWithMetrics } from '../../types';

interface GetReplies {
  data: EventResponse[];
}

interface SendingVolumeResultResponse {
  date: string;
  count: number;
}

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNumbers: builder.query<
      DashboardStatistics,
      {
        startPeriodDay: string;
        endPeriodDay: string;
        campaignIds: string[];
      }
    >({
      query: ({ startPeriodDay, endPeriodDay, campaignIds }) => ({
        url: 'dashboard/numbers',
        params: {
          startPeriodDay,
          endPeriodDay,
          campaignIds
        }
      }),
      transformResponse: (response: { data: DashboardStatistics }) => response.data
    }),
    getSendingVolume: builder.query<
      SendingVolumeResultResponse[],
      {
        startPeriodDay: string;
        endPeriodDay: string;
        campaignIds: string[];
      }
    >({
      query: ({ startPeriodDay, endPeriodDay, campaignIds }) => ({
        url: 'dashboard/sending-volume',
        params: {
          startPeriodDay,
          endPeriodDay,
          campaignIds
        }
      }),
      transformResponse: (response: { data: SendingVolumeResultResponse[] }) => response.data
    }),
    getTopCampaigns: builder.query<
      CampaignWithMetrics[],
      {
        startPeriodDay: string;
        endPeriodDay: string;
        currentPage: number | null;
        pageSize: number | null;
        campaignIds: string[];
        limit?: number | null;
      }
    >({
      query: ({ startPeriodDay, endPeriodDay, currentPage, pageSize, campaignIds, limit }) => ({
        url: 'dashboard/top-campaigns',
        params: {
          startPeriodDay,
          endPeriodDay,
          campaignIds,
          ...(currentPage !== null && { currentPage }),
          ...(pageSize !== null && { pageSize }),
          ...(limit !== null && { limit })
        }
      }),
      transformResponse: (response: { data: CampaignWithMetrics[] }) => response.data
    }),
    getReplies: builder.query<
      EventResponse[],
      {
        startPeriodDay: string;
        endPeriodDay: string;
        campaignIds: string[];
        repliesType: string;
      }
    >({
      query: ({ startPeriodDay, endPeriodDay, campaignIds, repliesType }) => ({
        url: 'dashboard/replies',
        params: { startPeriodDay, endPeriodDay, campaignIds, repliesType }
      }),
      transformResponse: (response: GetReplies) => response?.data
    })
  })
});

export const { useGetNumbersQuery, useGetSendingVolumeQuery, useGetTopCampaignsQuery, useGetRepliesQuery } =
  dashboardApi;
