import { DatePicker as DatePickerAntd } from 'antd';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

export default function DatePicker({
  value,
  format = 'YYYY-MM-DD',
  startDate,
  endDate,
  disabled = false,
  placeholder = 'Select date',
  onChange
}: {
  value?: Dayjs | null;
  startDate?: Dayjs | null;
  endDate?: Dayjs | null;
  format?: string;
  disabled?: boolean;
  placeholder?: string;
  onChange: (date: Dayjs | null, dateString: string) => void;
}) {
  function disabledDate(current: Dayjs | null) {
    let startCheck;
    let endCheck;

    if (startDate) {
      startCheck = current && current.startOf('day') <= dayjs(startDate).startOf('day');
    }
    if (endDate) {
      endCheck = current && current.startOf('day') > dayjs(endDate).startOf('day');
    }

    return !!startCheck || !!endCheck;
  }

  return (
    <DatePickerAntd
      data-t="date-picker"
      inputReadOnly
      allowClear
      disabled={disabled}
      format={format}
      disabledDate={disabledDate}
      className="w-full"
      value={value ? dayjs(value) : null}
      size="large"
      placeholder={placeholder}
      onChange={onChange}
    />
  );
}
