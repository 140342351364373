import { SmsNumber } from '.';

export interface Company {
  id: number;
  name: string;

  createdAt: Date;
  updatedAt: Date;
  smsSettings?: SmsNumber[];
  leadSearchLimit: number;
}

export enum Revenue {
  Less_10M = '<10000000',
  '10M-50M' = '10000000-50000000',
  '50M-100M' = '50000000-100000000',
  '100M-500M' = '100000000-500000000',
  '500M-1B' = '500000000-1000000000',
  '1B-5B' = '1000000000-5000000000',
  More_5B = '5000000000<'
}

export enum CompanyFunding {
  '1M' = '1000000',
  '10M' = '10000000',
  '90M' = '90000000'
}

export enum ApolloCompanyFunding {
  Seed = 'Seed',
  Angel = 'Angel',
  Venture = 'Venture',
  SeriesA = 'SeriesA',
  SeriesB = 'SeriesB',
  SeriesC = 'SeriesC',
  SeriesD = 'SeriesD',
  SeriesE = 'SeriesE',
  SeriesF = 'SeriesF',
  DebtFinancing = 'DebtFinancing',
  EquityCrowdfunding = 'EquityCrowdfunding',
  ConvertibleNote = 'ConvertibleNote',
  PrivateEquity = 'PrivateEquity',
  Other = 'Other'
}
