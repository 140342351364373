import { AiProspectingBuilderForm, AiProspectingField } from '../AiProspectingBuilder';

/**
 * Check if field has value
 * @param field
 * @returns
 */
export function checkIsFieldWithValue(field: AiProspectingField) {
  if (Array.isArray(field)) return !!field.length;

  if (typeof field === 'string') return !!field.trim();

  return false;
}

/**
 * Check is any form fields filled with correct value
 * @param fields
 * @param fieldValues
 * @returns
 */
export function checkIsAnyFieldFilled(fields: AiProspectingField[], fieldValues: AiProspectingBuilderForm) {
  return fields.some((field) => checkIsFieldWithValue(fieldValues?.[field] as AiProspectingField));
}

/**
 * Validates if a given string is a valid domain or URL.
 * @param domain - The string to validate as a domain or URL.
 * @returns - Returns `true` if the string is a valid domain or URL, otherwise `false`.
 */
export function isValidDomain(domain: string): boolean {
  // This version avoids lookbehind to ensure compatibility with older browsers like Safari
  const domainRegex = /^((?!-)[A-Za-z0-9-]{1,63}[^-\s]\.)+[A-Za-z]{2,6}$/;
  const urlRegex =
    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)$/;

  return domainRegex.test(domain) || urlRegex.test(domain);
}
