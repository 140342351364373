import { Campaign, Lead } from '.';

export enum EventRate {
  like = 'like',
  dislike = 'dislike'
}

export enum EventType {
  outgoing = 'outgoing',
  reply = 'reply',
  incoming = 'incoming',
  outgoingSms = 'outgoingSms',
  incomingSms = 'incomingSms',
  opened = 'opened',
  positiveResponse = 'positiveResponse',
  booked = 'booked',
  closed = 'closed',
  linkedinOutgoing = 'linkedinOutgoing',
  linkedinIncoming = 'linkedinIncoming',
  linkedinConnectionRequestSent = 'linkedinConnectionRequestSent',
  linkedinConnectionRequestAccepted = 'linkedinConnectionRequestAccepted'
}

export enum EventAttachmentType {
  imageGif = 'image/gif',
  imagePng = 'image/png'
}

export interface EventAttachmentData {
  videoLink?: string;
  imageUrl: string;
  base64?: string;
}

export interface EventAttachment {
  id: string;
  type: EventAttachmentType;
  data: EventAttachmentData;
}

export interface Event {
  id: number;
  campaign: Campaign;
  lead: Lead;
  type: EventType;
  subject: string;
  body: string;
  to: string;
  from: string;
  emailProviderThreadId: string;
  feedbackRating: EventRate | null;
  campaignLeadId: number;
  attachments?: EventAttachment[];

  createdAt: Date;
  updatedAt: Date;
}

export type EventSelectType = EventType | 'all';
