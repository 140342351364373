import { DefaultPaginationParams } from '../types';

import { SuppressedEntry } from '../../types/suppressedEntry';
import { ProviderTags } from '../constants';
import { apiSlice } from './apiSlice';

interface GetSuppressionListRequest {
  params?: DefaultPaginationParams & { search?: string; type?: string; source?: string };
}

interface UpdateSuppressionListEntryRequest {
  id: number;
  body: Pick<SuppressedEntry, 'type' | 'value' | 'source'>;
}

interface DeleteSuppressionListEntryRequest {
  id: number;
}

interface GetSuppressionListResponse {
  data: SuppressedEntry[];
  totalCount: number;
}

export const suppressionListApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSuppressionList: builder.query<GetSuppressionListResponse, GetSuppressionListRequest>({
      query: ({ params }) => ({
        url: 'suppressed-entries',
        params
      }),
      providesTags: (result) => {
        result?.data.map(({ id }) => ({ type: ProviderTags.SuppressionList, id }));

        const suppressionList = result?.data?.map(({ id }) => ({ type: ProviderTags.SuppressionList, id }));

        return suppressionList?.length ? suppressionList : [{ type: ProviderTags.SuppressionList }];
      }
    }),
    addSuppressedEntries: builder.mutation<SuppressedEntry[], { suppressions: SuppressedEntry[] }>({
      query: (body) => ({
        url: 'suppressed-entries',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.SuppressionList]
    }),

    updateSuppressionListEntry: builder.mutation<void, UpdateSuppressionListEntryRequest>({
      query: ({ id, body }) => ({
        url: `suppressed-entries/${id}`,
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.SuppressionList]
    }),

    deleteSuppressionListEntry: builder.mutation<void, DeleteSuppressionListEntryRequest>({
      query: ({ id }) => ({
        url: `suppressed-entries/${id}`,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, { id }) => [{ type: ProviderTags.SuppressionList, id }]
    }),
    syncSuppressedEntries: builder.mutation<
      SuppressedEntry[],
      { listId: number; listName: string; addContiniously: boolean }
    >({
      query: (body) => ({
        url: 'hubspot-suppressed-entries/lists/sync',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      }),
      invalidatesTags: [ProviderTags.SuppressionList]
    })
  })
});

export const {
  useGetSuppressionListQuery,
  useUpdateSuppressionListEntryMutation,
  useDeleteSuppressionListEntryMutation,
  useAddSuppressedEntriesMutation,
  useSyncSuppressedEntriesMutation
} = suppressionListApi;
