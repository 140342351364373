export enum ApolloManagementLevels {
  Owner = 'owner',
  Founder = 'founder',
  CXO = 'c_suite',
  Partner = 'partner',
  VP = 'vp',
  Head = 'head',
  Director = 'director',
  Manager = 'manager',
  Senior = 'senior',
  Entry = 'entry',
  Intern = 'intern'
}
