import { Button, InputNumber, Select, Spin } from 'antd';
import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as PaginationButton } from './icons/pagination-arrow-right.svg';
import styles from './pagination.module.css';

const primaryButtonClasses =
  'flex items-center !p-0 w-[34px] h-[34px] justify-center bg-[var(--button-primary)] !text-[var(--white)] enabled:border-none enabled:hover:shadow-primary active:bg-[var(--button-primary-active)] border-[var(--input-border)] disabled:cursor-not-allowed disabled:bg-[var(--white)]';
const disabledIconColor = 'text-[var(--placeholder)]';

export enum ItemsPerPage {
  Five = 5,
  Ten = 10,
  Twenty = 20,
  Fifty = 50,
  Hundred = 100
}

export interface PaginationCallback {
  (currentPage: number, selectedItemsPerPage: number): void;
}

interface PaginationProps {
  totalItems: number | undefined;
  changePaginationCallback: PaginationCallback;
  defaultItemsPerPage?: ItemsPerPage;
  itemsPerPageLimit?: ItemsPerPage;
  className?: string;
  wasSearchQueryChanged?: boolean;
  isPageInputDisabled?: boolean;
  currentPageValue?: number;
}

/**
 * Pagination component for navigating through a list of items.
 * @param changePaginationCallback - A callback function with two parameters(page, itemsPerPage) triggered when the page or items per page changes.
 * @param totalItems - The total number of items available for pagination.
 * @param defaultItemsPerPage
 * @param className - The class name for the component wrapper.(Optional)
 * @param itemsPerPageLimit - The maximum number of items available in select to be displayed per page.(Optional)
 * @param isNewSearchRequest
 * @returns {JSX.Element} The Pagination component.
 */
export default function Pagination({
  changePaginationCallback,
  totalItems,
  defaultItemsPerPage,
  itemsPerPageLimit,
  className,
  wasSearchQueryChanged,
  isPageInputDisabled,
  currentPageValue = 1
}: PaginationProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'common' });
  const [currentPage, setCurrentPage] = useState<number>(currentPageValue);
  const [selectedItemsPerPage, setSelectedItemsPerPage] = useState<ItemsPerPage>(
    defaultItemsPerPage || itemsPerPageLimit || 10
  );
  // Memoized calculation of the total number of pages.
  const totalPages = useMemo(() => {
    // If there are no items, return 1 page (minimum 1 page).
    if (totalItems === 0) return 1;
    // If the totalItems is undefined (loading state), return 0 for the loading icon.
    if (totalItems === undefined) return 0;

    // Calculate the total pages based on the total items and selected items per page.
    return Math.ceil(totalItems / selectedItemsPerPage);
  }, [totalItems, selectedItemsPerPage]);
  const tPage = t('page');
  // Memoized options for selecting items per page.
  //  It filters out non-numeric values from the ItemsPerPage enum and maps the remaining numeric.
  const selectItemsPerPageOptions = useMemo(
    () =>
      Object.values(ItemsPerPage)
        .filter((value) => {
          if (typeof value === 'number' && itemsPerPageLimit) return value <= itemsPerPageLimit;

          return typeof value === 'number';
        })
        .map((option) => ({ value: option, label: `${option} / ${tPage}` })),
    []
  );
  const isPaginationPageInputDisabled = useMemo(
    () => totalPages === 1 || !totalPages || isPageInputDisabled,
    [totalPages, isPageInputDisabled]
  );

  // useEffect to trigger the changePaginationCallback  whenever currentPage or selectedItemsPerPage changes
  useEffect(() => {
    const safeTotalItems = totalItems || 0;
    const calculatedTotalPages = Math.ceil(safeTotalItems / selectedItemsPerPage);

    let newPage = currentPage;

    // Check if the current page exceeds the total pages after updates
    if (currentPage > calculatedTotalPages && typeof totalItems === 'number') {
      newPage = calculatedTotalPages || 1;
    }

    // If search button was clicked, reset the page to 1
    if (wasSearchQueryChanged) {
      newPage = 1;
    }

    setCurrentPage(newPage);
    changePaginationCallback(newPage, selectedItemsPerPage);
  }, [currentPage, selectedItemsPerPage, totalItems, changePaginationCallback]);

  /**
   * Function to go to the next page.
   */
  function goToNextPage() {
    setCurrentPage((prev) => prev + 1);
  }

  /**
   * Function to go to the previous page.
   */
  function goToPreviousPage() {
    setCurrentPage((prev) => prev - 1);
  }

  /**
   * Function to update the current page from the input number.
   * @param {number | null} newPageNumber - The new page number.
   */
  function updatePagesFromInput(newPageNumber: number | null) {
    if (newPageNumber && Number.isInteger(newPageNumber)) setCurrentPage(newPageNumber);
  }

  /**
   * Function to update the items per page from the select component.
   * @param {ItemsPerPage} selectedSize - The selected items per page.
   */
  function updateItemsPerPageFromSelect(selectedSize: ItemsPerPage) {
    setSelectedItemsPerPage(selectedSize);
    setCurrentPage(1);
  }

  return (
    <div className={cn('grid grid-cols-3', className, styles.container)}>
      <div className="col-start-2 justify-self-center flex items-center gap-x-[10px]">
        <Button
          data-t="previous-page-button"
          className={primaryButtonClasses}
          disabled={currentPage === 1}
          onClick={goToPreviousPage}
          aria-label="go-to-the-previous-page"
        >
          <PaginationButton className={`rotate-180 ${currentPage === 1 ? disabledIconColor : ''}`} />
        </Button>
        <InputNumber
          data-t="pagination-iput"
          type="number"
          className="pagination w-[36px] h-[32px]"
          onChange={updatePagesFromInput}
          value={currentPage}
          disabled={isPaginationPageInputDisabled}
          min={1}
          controls={false}
          max={totalPages}
          aria-label="update-page-from-input"
        />
        <div className="text-base text-[var(--placeholder)] select-none">
          {t('of')}
          <span className="min-w-[14px] inline-block">{totalPages || <Spin size="small" aria-label="spinner" />}</span>
        </div>
        <Button
          data-t="next-page-button"
          className={primaryButtonClasses}
          disabled={currentPage === totalPages || !totalPages}
          onClick={goToNextPage}
          aria-label="go-to-the-next-page"
        >
          <PaginationButton className={currentPage === totalPages || !totalPages ? disabledIconColor : ''} />
        </Button>
      </div>
      <Select
        data-t="items-per-page-select"
        className="col-start-3 justify-self-end"
        style={{ width: 127 }}
        value={selectedItemsPerPage}
        onChange={updateItemsPerPageFromSelect}
        options={selectItemsPerPageOptions}
        aria-label="select-items-per-page"
      />
    </div>
  );
}
