import posthog from 'posthog-js';
import { lazy, useEffect, useMemo } from 'react';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';

import FeaturePage from './components/Feature/Feature';
import Features from './components/Features/Features';
import SqsPage from './components/Sqs/Sqs';
import { useGetCsrfQuery, useGetMeQuery } from './redux-store';
import { getEnvPrefix, isProd } from './services/urlHelpers';
import AiProspectingBuilder from './components/AiProspecting/AiProspectingBuilder';
import RemoteMachines from './components/RemoteMachines/RemoteMachines';
import Spinner from './components/Spinner/Spinner';

const LeadPage = lazy(() => import('./components/Lead/Lead'));
const Leads = lazy(() => import('./components/Leads/Leads'));
const PersonaPage = lazy(() => import('./components/Persona/Persona'));
const Personas = lazy(() => import('./components/Personas/Personas'));
const CampaignPage = lazy(() => import('./components/Campaign/Campaign'));
const PrivateRoute = lazy(() => import('./components/PrivateRoute/PrivateRoute'));
const Settings = lazy(() => import('./components/Settings/Settings'));
const Campaigns = lazy(() => import('./components/Campaigns/Campaigns'));
const Builder = lazy(() => import('./components/Builder/Builder'));
const Users = lazy(() => import('./components/Users/Users'));
const UserPage = lazy(() => import('./components/User/User'));
const Companies = lazy(() => import('./components/Companies/Companies'));
const CompanyPage = lazy(() => import('./components/CompanyPage/CompanyPage'));
const Login = lazy(() => import('./components/Login/Login'));
const NotFoundPage = lazy(() => import('./components/NotFound/NotFound'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const LeadDuplicates = lazy(() => import('./components/LeadsDuplicates/LeadsDuplicates'));
const Redeem = lazy(() => import('./components/Redeem/RedeemPage'));
const SelfOnboarding = lazy(() => import('./components/SelfOnboarding/SelfOnboarding'));
const SuppressionList = lazy(() => import('./components/SuppressionList/SuppressionList'));
const VideoHostingWrapper = lazy(() => import('./components/VideoHosting/VideoHostingWrapper'));
const VideoQueryWrapper = lazy(() => import('./components/VideoHosting/VideoQueryWrapper'));

const getRouter = (t: TFunction) =>
  createBrowserRouter([
    {
      path: '/',
      element: (
        <PrivateRoute title={t('campaignBuilder')}>
          <Builder />
        </PrivateRoute>
      )
    },
    {
      path: 'ai-prospecting',
      element: (
        <PrivateRoute title={t('aiProspecting')}>
          <AiProspectingBuilder />
        </PrivateRoute>
      )
    },
    {
      path: '/campaigns',
      element: (
        <PrivateRoute title={t('campaigns')}>
          <Campaigns />
        </PrivateRoute>
      )
    },
    {
      path: '/campaigns/:campaignId',
      element: (
        <PrivateRoute>
          <CampaignPage />
        </PrivateRoute>
      )
    },
    {
      path: '/leads',
      element: (
        <PrivateRoute title={t('leads')}>
          <Leads />
        </PrivateRoute>
      )
    },
    {
      path: '/suppression-list',
      element: (
        <PrivateRoute title={t('suppressionList')}>
          <SuppressionList />
        </PrivateRoute>
      )
    },
    {
      path: '/leads/:leadId',
      element: (
        <PrivateRoute>
          <LeadPage />
        </PrivateRoute>
      )
    },
    {
      path: '/personas',
      element: (
        <PrivateRoute title={t('personas')} key="personas">
          <Personas />
        </PrivateRoute>
      )
    },
    {
      path: '/personas/:personaId',
      element: (
        <PrivateRoute>
          <PersonaPage />
        </PrivateRoute>
      )
    },
    {
      path: '/settings',
      element: (
        <PrivateRoute title={t('settings')}>
          <Settings />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/users',
      element: (
        <PrivateRoute title={t('users')}>
          <Users />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/users/:userId',
      element: (
        <PrivateRoute>
          <UserPage />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/companies',
      element: (
        <PrivateRoute title={t('companies')}>
          <Companies />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/companies/:companyId',
      element: (
        <PrivateRoute>
          <CompanyPage />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/sqs',
      element: (
        <PrivateRoute title={t('sqs')}>
          <SqsPage />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/remote-machines',
      element: (
        <PrivateRoute title={t('remoteMachines')}>
          <RemoteMachines />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/all-personas',
      element: (
        <PrivateRoute title={t('personas')} key="admin-all-personas">
          <Personas canGetAll />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/all-personas/:personaId',
      element: (
        <PrivateRoute>
          <PersonaPage canGetAll />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/features',
      element: (
        <PrivateRoute title={t('features')}>
          <Features />
        </PrivateRoute>
      )
    },
    {
      path: '/admin/features/:featureId',
      element: (
        <PrivateRoute>
          <FeaturePage />
        </PrivateRoute>
      )
    },
    {
      path: '/dashboard',
      element: (
        <PrivateRoute title={t('dashboard')}>
          <Dashboard />
        </PrivateRoute>
      )
    },
    {
      path: '/not-found',
      element: <NotFoundPage />
    },
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/redeem',
      element: <Redeem />
    },
    {
      path: '/leads/duplicates',
      element: (
        <PrivateRoute title={t('duplicatedLeads')}>
          <LeadDuplicates />
        </PrivateRoute>
      )
    },
    {
      path: '/self-onboarding',
      element: <SelfOnboarding />
    },
    {
      path: '/videos/:hash',
      element: <VideoHostingWrapper />
    },
    {
      path: '/videos',
      element: <VideoQueryWrapper />
    },
    {
      path: '/*',
      element: <Navigate to="/" replace />
    }
  ]);

export default function App() {
  // Get CSRF token before page load
  const { error: csrfError, currentData: csrfData } = useGetCsrfQuery(null);
  // Get current user data on page load
  const { error, currentData } = useGetMeQuery(null, { skip: !csrfData?.token });
  const { t } = useTranslation('translation', { keyPrefix: 'common' });

  const router = useMemo(() => getRouter(t), [t]);

  /**
   * Initialize Posthog
   */
  useEffect(() => {
    // Only use Posthog in production
    if (!isProd) return;

    posthog.init('phc_IXAbO6uCPYhd58ck4BKdqQinUCGSJ5iBgGBAcpiwxlj', {
      api_host: 'https://app.posthog.com',
      opt_in_site_apps: true
    });

    if (currentData) {
      const identifier = isProd ? currentData.id.toString() : `${getEnvPrefix()}_${currentData.id.toString()}`;
      const email = isProd ? currentData.email : `${getEnvPrefix()}_${currentData.email}`;
      const company = currentData.company as Record<string, string | number | Date>;
      const flatCompany = Object.keys(company).reduce(
        (accumulator, curr) => ({
          ...accumulator,
          [`company_${curr}`]: company[curr]
        }),
        {}
      );
      const features = currentData.features as Record<string, boolean>;
      const flatFeatures = Object.keys(features).reduce(
        (accumulator, curr) => ({
          ...accumulator,
          [`feature_${curr}`]: features[curr]
        }),
        {}
      );

      posthog.identify(
        identifier,
        {
          email,
          name: `${currentData.firstName} ${currentData.lastName}`,
          ...flatCompany,
          ...flatFeatures
        },
        {}
      );
    }
  }, [currentData]);

  return csrfError || error || currentData ? (
    <RouterProvider router={router} />
  ) : (
    <div className="flex items-center justify-center h-screen">
      <Spinner />
    </div>
  );
}
