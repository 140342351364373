// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* On screens with size less than 640px and screens between 750px and 850px */
@media screen and (max-width: 640px), (min-width: 768px) and (max-width: 850px) {
  .pagination_container__il0Tz {
    grid-template-columns: 1fr;
  }

  .pagination_container__il0Tz > :first-child {
    grid-column-start: 2;
    order: 2;
  }

  .pagination_container__il0Tz > :last-child {
    grid-column-start: 3;
    order: 1;
    margin-bottom: 10px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Pagination/pagination.module.css"],"names":[],"mappings":"AAAA,6EAA6E;AAC7E;EACE;IACE,0BAA0B;EAC5B;;EAEA;IACE,oBAAoB;IACpB,QAAQ;EACV;;EAEA;IACE,oBAAoB;IACpB,QAAQ;IACR,mBAAmB;EACrB;AACF","sourcesContent":["/* On screens with size less than 640px and screens between 750px and 850px */\n@media screen and (max-width: 640px), (min-width: 768px) and (max-width: 850px) {\n  .container {\n    grid-template-columns: 1fr;\n  }\n\n  .container > :first-child {\n    grid-column-start: 2;\n    order: 2;\n  }\n\n  .container > :last-child {\n    grid-column-start: 3;\n    order: 1;\n    margin-bottom: 10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `pagination_container__il0Tz`
};
export default ___CSS_LOADER_EXPORT___;
