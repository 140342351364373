import { Dayjs } from 'dayjs';

import { apiSlice } from './apiSlice';
import { AiProspectingBuilderForm } from '../../components/AiProspecting/AiProspectingBuilder';

export interface IntentLeadDataResult {
  id: number;
  name: string;
  website: string;
  industry: string;
  location: string;
  title: string;
  description: string;
  created: string;
  vacancyJobTitle: string;
  companyName: string;
  companySize: string;
  companySummary: string;
  companyNews: string;
  companyTechnologies: string;
  vacancies: string[];
  fundingLastRoundType: string;
  fundingLastRoundDate: Dayjs | null;
  monthlyPercentageEmployeeGrowth?: number | null;
  categoriesAndKeywords: string[];
}

interface CreateIntentSearchRecordRequest {
  intentPreferences: AiProspectingBuilderForm;
  filterUrl: string;
  intentAutoUpdateEnabled: boolean;
}

export const intentApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createIntentSearchRecord: builder.mutation<{ intentSearchRecordId: number }, CreateIntentSearchRecordRequest>({
      query: (body) => ({
        url: 'intent-search',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        credentials: 'include'
      })
    }),
    getTechnologySuggestions: builder.query<string[], { input: string }>({
      query: ({ input }) => ({
        url: `technology-intent/suggestions?input=${input}`,
        method: 'GET',
        credentials: 'include'
      })
    })
  })
});

export const { useCreateIntentSearchRecordMutation, useGetTechnologySuggestionsQuery } = intentApi;
