import { Feedback } from '../../types/feedback';
import { apiSlice } from './apiSlice';
import { ProviderTags } from '../constants';

interface SendFeedbackRequest {
  data: Partial<Feedback>;
  // Params for update of campaign events, campaign lead events
  campaignId: number;
  campaignLeadId: number;
}

export const feedbackApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendFeedback: builder.mutation<void, SendFeedbackRequest>({
      query: ({ data }) => ({
        url: 'feedback/email',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }),
      invalidatesTags: (result, error, { campaignId, campaignLeadId }) => [
        { type: ProviderTags.CampaignEvents, id: campaignId },
        { type: ProviderTags.CampaignLeads, id: campaignLeadId }
      ]
    })
  })
});

export const { useSendFeedbackMutation } = feedbackApi;
