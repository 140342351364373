import type { UserInterface } from '../../types';
import { apiSlice } from './apiSlice';

export interface LoginResponseData {
  status: string;
  proceedToOnboarding: boolean;
}

export const authsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseData, Pick<UserInterface, 'email' | 'password'>>({
      query: (body) => ({
        url: `users/login`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
    }),
    logout: builder.mutation<void, void>({
      query: () => ({
        url: `users/logout`,
        method: 'DELETE'
      })
    })
  })
});

export const { useLoginMutation, useLogoutMutation } = authsApi;
