import { User } from '../../services/user';
import { StripeCustomer, StripeSubscription } from '../../types/stripe';
import { apiSlice } from './apiSlice';

interface CustomerPortalData {
  data: {
    redirectUrl?: string;
    errorMessage?: string | null;
  };
  error: boolean;
}

interface SubscriptionsResponse {
  data: StripeSubscription[];
  has_more: boolean;
  object: string;
  url: string;
}

export const stripeActions = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendTemporaryPortalCreationRequest: builder.mutation<CustomerPortalData, User | undefined>({
      query: () => ({
        url: '/stripe/manage-customer-subscriptions',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })
    }),
    getTrialSubscription: builder.query<StripeSubscription | null, null>({
      transformResponse: (res: SubscriptionsResponse) => res?.data?.find((sub) => sub.status === 'trialing') || null,
      query: () => ({
        url: '/stripe/subscription',
        method: 'GET'
      })
    }),
    getCustomer: builder.query<StripeCustomer | null, null>({
      query: () => ({
        url: '/stripe/customer',
        method: 'GET'
      })
    })
  })
});

export const { useSendTemporaryPortalCreationRequestMutation, useGetTrialSubscriptionQuery, useGetCustomerQuery } =
  stripeActions;
