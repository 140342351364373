export enum SwarmManagementLevels {
  CXO = 'cxo',
  Director = 'director',
  Entry = 'entry',
  Manager = 'manager',
  Owner = 'owner',
  Partner = 'partner',
  Senior = 'senior',
  Training = 'training',
  Unpaid = 'unpaid',
  VP = 'vp'
}
