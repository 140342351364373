// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intentLeadsLoader_loaderWrapper__6CNOF {
  display: flex;
  flex-direction: column;
}

/* On screens with size more than 768px */
@media (min-width: 768px) {
  .intentLeadsLoader_loaderWrapper__6CNOF {
    flex-direction: row;
    align-items: center;
    height: auto;
  }
}

/* On screens with size between 768px and 825px */
@media screen and (min-width: 768px) and (max-width: 825px) {
  .intentLeadsLoader_loaderWrapper__6CNOF {
    flex-direction: column;
    align-items: center;
    height: auto;
  }

  .intentLeadsLoader_imageWrapper__V1JDE {
    flex-shrink: 0;
  }

  .intentLeadsLoader_textWrapper__hPk8H {
    margin-left: 1.25rem;
    text-align: left;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/AiProspecting/components/intentLeadsLoader.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA,yCAAyC;AACzC;EACE;IACE,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;EACd;AACF;;AAEA,iDAAiD;AACjD;EACE;IACE,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;EACd;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,oBAAoB;IACpB,gBAAgB;EAClB;AACF","sourcesContent":[".loaderWrapper {\n  display: flex;\n  flex-direction: column;\n}\n\n/* On screens with size more than 768px */\n@media (min-width: 768px) {\n  .loaderWrapper {\n    flex-direction: row;\n    align-items: center;\n    height: auto;\n  }\n}\n\n/* On screens with size between 768px and 825px */\n@media screen and (min-width: 768px) and (max-width: 825px) {\n  .loaderWrapper {\n    flex-direction: column;\n    align-items: center;\n    height: auto;\n  }\n\n  .imageWrapper {\n    flex-shrink: 0;\n  }\n\n  .textWrapper {\n    margin-left: 1.25rem;\n    text-align: left;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderWrapper": `intentLeadsLoader_loaderWrapper__6CNOF`,
	"imageWrapper": `intentLeadsLoader_imageWrapper__V1JDE`,
	"textWrapper": `intentLeadsLoader_textWrapper__hPk8H`
};
export default ___CSS_LOADER_EXPORT___;
