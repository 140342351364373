import classNames from 'classnames';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import MultiSelect from '../components/MultiSelect';
import { SectionsProps } from './ProspectsWrapper';
import { useGetTechnologySuggestionsQuery } from '../../../redux-store';
import { transformSuggestionsToOptions } from '../../../services/transformOptionHelpers';

import { ReactComponent as TechnologySearchSectionIcon } from '../images/technologySearchIcon.svg';

export default function TechnologySearchSection(props: SectionsProps) {
  const { handleChange, isSelected, disabled, search, searchInputHandle } = props;
  const { t } = useTranslation('translation', { keyPrefix: 'prospecting' });
  const { currentData: suggestedTechnologies, isFetching: isSuggestedTechnologiesLoading } =
    useGetTechnologySuggestionsQuery({ input: search?.technologyQuery }, { skip: !search?.technologyQuery?.trim() });

  return (
    <div className="h-full flex flex-col" data-testid="technology-search-section">
      <div className="flex flex-row items-center text-xl font-medium mb-4 space-x-2 ">
        <TechnologySearchSectionIcon />
        <span>{t('technologySearch.blockName')}</span>
      </div>

      <div
        className={classNames('h-full bg-white rounded-[5px] p-[20px]   border border-solid flex flex-col gap-5', {
          'border-[1.5px] border-[var(--button-primary)]': isSelected,
          'hover:border-[var(--tertiary)] hover:shadow-secondary': !isSelected && !disabled
        })}
      >
        <Form.Item
          rules={[{ required: isSelected, message: t('technologySearch.technologiesRequired') }]}
          name="technologyQuery"
          label={<span className="font-bold">{t('technologySearch.technology')}</span>}
          className="!mb-0"
        >
          <MultiSelect
            showSearch
            id="technologyQuery"
            disabled={disabled}
            isLoading={isSuggestedTechnologiesLoading}
            placeholder={t('technologySearch.placeholders.technology')}
            mode="multiple"
            options={transformSuggestionsToOptions(suggestedTechnologies)}
            setSearch={(value) => searchInputHandle(value, 'technologyQuery')}
            setSelected={(value) => handleChange('technologyQuery', value)}
          />
        </Form.Item>
      </div>
    </div>
  );
}
