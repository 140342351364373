export enum SwarmDepartments {
  'Customer Service' = 'customer_service',
  'Design' = 'design',
  'Education' = 'education',
  'Engineering' = 'engineering',
  'Finance' = 'finance',
  'Health' = 'health',
  'HumanResources' = 'human_resources',
  'Legal' = 'legal',
  'Marketing' = 'marketing',
  'Media' = 'media',
  'Operations' = 'operations',
  'Public Relations' = 'public_relations',
  'Real Estate' = 'real_estate',
  'Sales' = 'sales',
  'Trades' = 'trades'
}
