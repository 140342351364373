import { ProviderTags } from '../constants';
import { apiSlice } from './apiSlice';

export enum WarmUpMailboxProvider {
  Gmail = 'gmail',
  Outlook = 'oauth_outlook'
}

interface EmailWarmUpRequest {
  integrationTokenId: number;
  provider: WarmUpMailboxProvider;
  userId: number;
  email: string;
  password: string;
}

interface WarmUpProviderDeliverabilityInfo {
  [key: string]: { inbox: number; spam: number; waiting: number };
}

export interface MailboxStatistics {
  a_record: { status: null; title: null };
  created_at: string;
  deliverability: number;
  dkim: { status: number | null; title: string | null };
  dmarc: { status: number | null; title: string | null };
  dns_score: number;
  isp_score: number;
  latest_deliverability_test: { date: string; result: WarmUpProviderDeliverabilityInfo | null };
  mx_record: { status: number | null; title: string | null };
  r_dns: { status: number | null; title: string | null };
  received_today: number;
  sent_today: number;
  spf: { status: number | null; title: null };
  status: 'paused' | 'active';
  temperature: number;
  warmup_active: boolean;
  warmup_language: { name: string; code: string };
  warmup_topic: { title: string; id: number };
}

export const emailWarmUpApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getMailboxStatistics: builder.query<MailboxStatistics, { id: number }>({
      query: ({ id }) => ({
        url: `warm-up/mailboxes/${id}`
      }),
      providesTags: (result, error, { id }) => [{ type: ProviderTags.WarmUpStatistic, id }]
    }),
    connectMailboxToWarmUp: builder.mutation<void, EmailWarmUpRequest>({
      query: (data) => ({
        url: `warm-up/connect-mailbox/${data?.userId}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }),
      invalidatesTags: [{ type: ProviderTags.Me }]
    }),
    disconnectMailboxFromWarmUp: builder.mutation<void, Pick<EmailWarmUpRequest, 'integrationTokenId' | 'userId'>>({
      query: ({ integrationTokenId, userId }) => ({
        url: `warm-up/disconnect-mailbox/${integrationTokenId}/${userId}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: [{ type: ProviderTags.Me }]
    }),
    runDeliverabilityTest: builder.mutation<{ status: string; message?: string }, { warmupMailboxId: number }>({
      query: ({ warmupMailboxId }) => ({
        url: `warm-up/deliverability-test/${warmupMailboxId}`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      }),
      invalidatesTags: (result, error, { warmupMailboxId }) => [
        { type: ProviderTags.WarmUpStatistic, id: warmupMailboxId }
      ]
    })
  })
});

export const {
  useGetMailboxStatisticsQuery,
  useConnectMailboxToWarmUpMutation,
  useDisconnectMailboxFromWarmUpMutation,
  useRunDeliverabilityTestMutation
} = emailWarmUpApi;
