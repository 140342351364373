export enum ApolloDepartments {
  'C-Suite' = 'C-Suite',
  'Product' = 'Product',
  'Engineering & Technical' = 'Engineering & Technical',
  'Design' = 'Design',
  'Education' = 'Education',
  'Finance' = 'Finance',
  'Human Resources' = 'Human Resources',
  'Information Technology' = 'Information Technology',
  'Legal' = 'Legal',
  'Marketing' = 'Marketing',
  'Medical & Health' = 'Medical & Health',
  'Operations' = 'Operations',
  'Sales' = 'Sales'
}
