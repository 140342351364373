import { apiSlice } from './apiSlice';

export const autocompleteApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchStaticFile: builder.query<string[], { file: string }>({
      query: ({ file }) => ({
        url: file.startsWith('/') ? `${window.location.origin}${file}` : file,
        responseHandler: (response) => response.text()
      }),
      transformResponse: (responseData: string) => responseData.split('\n')
    })
  })
});

export const { useFetchStaticFileQuery } = autocompleteApi;
